<template>
    <div>

      <div class="modal" id="registerCompleteModal" data-bs-backdrop="static" >
        <div class="modal-dialog modal-dialog-centered  modal-xl modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">{{$t('card.title.completeRegistration')}}</h5>
            </div>
            <div class="modal-body">

              <div class="row">

                <div class="col-md-6">
                  <div class="form-floating mb-3">
                    <input type="text" :class="{'form-control': true, 'is-invalid': errors.company}"  id="companyField" v-model="userDetails.company" placeholder="Firma">
                    <label for="companyField" class="form-label" >{{$t('form.label.company')}}</label>
                    <div class="invalid-feedback" v-if="errors.company">
                      <p v-for="(item, x) in errors.company" :key='x' >{{ errors.company[x] }}</p>
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-floating mb-3">
                    <input type="text" :class="{'form-control': true, 'is-invalid': errors.url}"  id="urlField" v-model="userDetails.url" placeholder="Website">
                    <label for="urlField" class="form-label" >{{$t('form.label.website')}}</label>
                    <div class="invalid-feedback" v-if="errors.url">
                      <p v-for="(item, x) in errors.url" :key='x' >{{ errors.url[x] }}</p>
                    </div>
                  </div>
                </div>
                
                <div class="col-md-6">
                  <div class="form-floating mb-3">
                    <input type="text" :class="{'form-control': true, 'is-invalid': errors.firstName}"  id="firstnameField" v-model="userDetails.firstName" placeholder="Vorname">
                    <label for="firstnameField" class="form-label" >{{$t('form.label.firstname')}}</label>
                    <div class="invalid-feedback" v-if="errors.firstName">
                      <p v-for="(item, x) in errors.firstName" :key='x' >{{ errors.firstName[x] }}</p>
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-floating mb-3">
                    <input type="text" :class="{'form-control': true, 'is-invalid': errors.lastName}"  id="lastnameField" v-model="userDetails.lastName" placeholder="Nachname">
                    <label for="lastnameField" class="form-label" >{{$t('form.label.lastname')}}</label>
                    <div class="invalid-feedback" v-if="errors.lastName">
                      <p v-for="(item, x) in errors.lastName" :key='x' >{{ errors.lastName[x] }}</p>
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-floating mb-3">
                    <input type="text" :class="{'form-control': true, 'is-invalid': errors.street}"  id="streetField" v-model="userDetails.street" placeholder="Strasse">
                    <label for="streetField" class="form-label" >{{$t('form.label.streetNr')}}</label>
                    <div class="invalid-feedback" v-if="errors.street">
                      <p v-for="(item, x) in errors.street" :key='x' >{{ errors.street[x] }}</p>
                    </div>
                  </div>
                </div>
                
                <div class="col-md-2">
                  <div class="form-floating mb-3">
                    <input type="text" :class="{'form-control': true, 'is-invalid': errors.zip}"  id="zipField" v-model="userDetails.zip" placeholder="PLZ">
                    <label for="zipField" class="form-label" >{{$t('form.label.zip')}}</label>
                    <div class="invalid-feedback" v-if="errors.zip">
                      <p v-for="(item, x) in errors.zip" :key='x' >{{ errors.zip[x] }}</p>
                    </div>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-floating mb-3">
                    <input type="text" :class="{'form-control': true, 'is-invalid': errors.city}"  id="cityField" v-model="userDetails.city" placeholder="Ort">
                    <label for="cityField" class="form-label" >{{$t('form.label.city')}}</label>
                    <div class="invalid-feedback" v-if="errors.city">
                      <p v-for="(item, x) in errors.city" :key='x' >{{ errors.city[x] }}</p>
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-floating mb-3">
                    <select class="form-select" aria-label="Default select example" id="countryField" v-model="userDetails.country">
                      <option selectedvalue="Schweiz">Schweiz</option>
                      <option value="Deutschland" disabled>Deutschland</option>
                      <option value="Österreich" disabled>Österreich</option>
                    </select>
                    <label for="countryField" class="form-label" >{{$t('form.label.country')}}</label>
                    <div class="invalid-feedback" v-if="errors.country">
                      <p v-for="(item, x) in errors.country" :key='x' >{{ errors.country[x] }}</p>
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-floating mb-3">
                    <input type="text" :class="{'form-control': true, 'is-invalid': errors.phone}"  id="phoneField" v-model="userDetails.phone" placeholder="Telefon">
                    <label for="phoneField" class="form-label" >{{$t('form.label.phone')}}</label>
                    <div class="invalid-feedback" v-if="errors.phone">
                      <p v-for="(item, x) in errors.phone" :key='x' >{{ errors.phone[x] }}</p>
                    </div>
                  </div>
                </div>
              
              </div>

            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-primary"  @click="updateUserDetails()" :disabled="!userDetails.firstName || !userDetails.lastName || !userDetails.company || !userDetails.street || !userDetails.zip || !userDetails.city || !userDetails.country || !userDetails.phone">{{$t('button.save')}}</button>
            </div>
          </div>
        </div>
      </div>

    </div>
</template>
<script>
  import { Modal } from "bootstrap";
  import axios from 'axios';
  export default {
    name: '${COMPONENT_NAME}',
    components: {
    },
    //---------------------------------------------------
    //
    //  Properties
    //
    //---------------------------------------------------
    props: {
      userData: {required: true},
      authFunction: {type: Function} ,
      apiData: {type: Function} 
    },
    //---------------------------------------------------
    //
    //  Data model
    //
    //---------------------------------------------------
    data() {
      return {
        locationResponseTmp:null,
        errors:{},
        userDetails:{
          id:this.userData.user_details.id,
          company:'',
          url:'',
          firstName:'',
          lastName:'',
          street:'',
          zip:'',
          city:'',
          country:'Schweiz',
          phone:''
        }
      };
    },
    //---------------------------------------------------
    //
    //  Computed Properties
    //
    //---------------------------------------------------
    computed: {},
    //---------------------------------------------------
    //
    //  Watch Properties
    //
    //---------------------------------------------------
    watch: {},
    //---------------------------------------------------
    //
    //  Filter Properties
    //
    //---------------------------------------------------
    // filters: {},
    //---------------------------------------------------
    //
    //  Validation Properties
    //
    //---------------------------------------------------
    // validations: {},
    //---------------------------------------------------
    //
    //  Vue Lifecycle
    //
    //---------------------------------------------------
    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // render(h) { return h(); },
    mounted() {
      this.uniqueModal = new Modal(document.getElementById("registerCompleteModal"),{ keyboard: false });
      this.uniqueModal.show();
    },
    // beforeUpdate() {},
    // updated() {},
    // beforeDestroy() {},
    // destroyed() {},
    //---------------------------------------------------
    //
    //  Methods
    //
    //---------------------------------------------------
    methods: {
      async updateUserDetails(){
        let response;
        response = await this.apiData('CompleteUserDetails updateUserDetails()','post','update-user-details', this.userDetails);
        console.log('UPDATE USER DETAILS');
        console.log(response.status);
        //this.authFunction();
        if (response.status === 'failure'){
          this.errors = response.errors;
          console.log(this.errors);
          this.$toast.open({
            message: this.$t('toast.error'),
            type: 'error',
            duration:3000,
          });
        }else{
          if(this.userData.role === 'location'){
            this.addLocation();
          }
          this.uniqueModal.hide();
          this.authFunction();
          this.errors = {};
          this.$toast.open({
            message: this.$t('toast.registrationComplete'),
            type: 'success',
            duration:3000,
          });
          
        }  
        
      },
      /* ##################### ADD LOCATION ##################### */
      async addLocation(){

        const request = {
          street: this.userDetails.street,
          zip: this.userDetails.zip,
          city: this.userDetails.city,
          country: 'ch',
        };
        const address = `${request.street}, ${request.zip} ${request.city}, ${request.country}`;
        const apiUrl = `https://api.geocode.earth/v1/search?text=${address}&api_key=ge-ef6d511751d04497`;

        
        axios
          .get(apiUrl)
          .then((response) => {
            if (response.data.features && response.data.features.length > 0) {
              const coordinates = response.data.features[0].geometry.coordinates;
              console.log('Koordinaten:', response.data);
              let location = {
                name:this.userDetails.company,
                street:this.userDetails.street,
                zip:this.userDetails.zip,
                city:this.userDetails.city,
                lat:coordinates[1],
                long:coordinates[0],
                region:response.data.features[0].properties.region_a,
                images:[],
                defaultImg:'',
                description:'',
                type:'315',
                electricSmall:false,
                electricLarge:false,
                owner:this.userData.uuid,
              };

           

              ///////////////
              let locationResponse;
              locationResponse = this.apiData('CompleteUserDetails.vue addLocation()','post','add-location-auto', location);
              this.locationResponseTmp = locationResponse;
              if (locationResponse.status === 'failure'){
                this.errors = locationResponse.errors;
                this.$toast.open({
                  //message: this.$t('toast.autoLocationFail'),
                  message:'Es konnte leider kein Standort automatisch angelegt werden',
                  type: 'warning',
                  duration:3000,
                });
              }else{
                //this.uniqueModal.hide();
                this.errors = {};
                this.$toast.open({
                  //message: this.$t('toast.autoLocation'),
                  message:'Es wurde automatisch ein Standort erfasst',
                  type: 'success',
                  duration:3000,
                });
                
              }
              ///////////////


            } else {
              //console.log('Keine Koordinaten für diese Adresse gefunden.');
            }
          })
          .catch((error) => {
            console.error('Fehler bei der Geocoding-Anfrage:', error);
          });
      
       
      },
      /* ##################### ADD LOCATION END ##################### */


    },
  };
</script>
<style lang="scss">
</style>