<template>
    <div>
      <div class="input-group  mb-3">
        <span class="input-group-text" id="basic-addon1">
          <font-awesome-icon icon="fa-solid fa-location-dot" />
        </span>
          <div class="form-floating">
          
          
            <input type="text" @input="fetchAutocomplete" @click="search =''" :class="{'form-control': true}"  id="PlaceSearchField" v-model="search" placeholder="Ort Suchen">
            <label for="PlaceSearchField" class="form-label" >{{ $t('form.label.locationSearch') }}</label>
          </div>
        
      </div>

      <ul class="list-group" v-if="suggestions.length && startType">
        <li class="list-group-item" style="cursor: pointer;"  v-for="(item, index) in suggestions" :key="index" @click="selectAddress(item)">{{ item.properties.label }}</li>
      </ul>
     
      <div>
   
  </div>
    </div>
</template>
<script>
  import axios from 'axios';
  export default {
    name: '${COMPONENT_NAME}',
    components: {
    },
    //---------------------------------------------------
    //
    //  Properties
    //
    //---------------------------------------------------
    props: {
      modelValue: { type: Array, Object: () => {this.modelPrototype} },
      images:{required: true},
      defaultImg:{required: true},
      owner:{required: true},
      editDataLocation:{required: true}
    },
    //---------------------------------------------------
    //
    //  Data model
    //
    //---------------------------------------------------
    data() {
      return {
        modelPrototype:{
          name:'',
        street:'',
        zip:'',
        city:'',
        long:'',
        region:'',
        regionID:'',
        images:[],
        country:'',
        defaultImg:'',
        description:'',
        type:'',
        owner:''
        },
        search: '',
        suggestions: [],
        selectedLocation:{},
        startType:false,
        sharedModel:{...this.modelValue}
      };
    },
    //---------------------------------------------------
    //
    //  Computed Properties
    //
    //---------------------------------------------------
    computed: {},
    //---------------------------------------------------
    //
    //  Watch Properties
    //
    //---------------------------------------------------
    watch: {
       // Überwache interne Änderungen und emitte sie zurück an die Parent-Komponente
       sharedModel(newVal) {
      // Verhindere Rekursion: Nur wenn sich internalSelectedCategories vom modelValue unterscheidet
      if (JSON.stringify(newVal) !== JSON.stringify(this.modelValue)) {
        this.$emit('update:modelValue', newVal);
      }
    },
    // Falls die Parent-Komponente den Wert von modelValue ändert, synchronisiere die interne Kopie
    modelValue(newVal) {
      // Verhindere Rekursion: Nur wenn sich modelValue von internalSelectedCategories unterscheidet
      if (JSON.stringify(newVal) !== JSON.stringify(this.sharedModel)) {
        this.sharedModel = {...newVal};
      }
    }
    },
    //---------------------------------------------------
    //
    //  Filter Properties
    //
    //---------------------------------------------------
    // filters: {},
    //---------------------------------------------------
    //
    //  Validation Properties
    //
    //---------------------------------------------------
    // validations: {},
    //---------------------------------------------------
    //
    //  Vue Lifecycle
    //
    //---------------------------------------------------
    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // render(h) { return h(); },
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // beforeDestroy() {},
    // destroyed() {},
    //---------------------------------------------------
    //
    //  Methods
    //
    //---------------------------------------------------
    methods: {
      fetchAutocomplete() {
        if(this.startType === false){
          this.startType = true;
        }
        if (this.search.length < 3) return;

        const apiUrl = `https://api.geocode.earth/v1/autocomplete?text=${this.search}&boundary.country=CHE&api_key=ge-ef6d511751d04497`;

        axios
          .get(apiUrl)
          .then((response) => {
            this.suggestions = response.data.features;
          })
          .catch((error) => {
            console.error('Fehler bei der Autocomplete-Anfrage:', error);
          });
      },
      selectAddress(item) {
        //console.log(item);
        this.search = item.properties.label;
        this.startType = false;
        const coordinates = item.geometry.coordinates;
        console.log('Koordinaten:', coordinates);
        this.selectedLocation = item;
        
        this.sharedModel ={
          name:item.properties.label,
          
          zip:item.properties.postalcode,
          city:item.properties.locality,
          region:item.properties.region_a,
          images:this.images,
          defaultImg:this.defaultImg,
          owner:this.owner,
          regionID:'',
          country:item.properties.country_code,
          long:item.geometry.coordinates[0],
          lat:item.geometry.coordinates[1],
        }
        if(item.properties.street){
          this.sharedModel.street = item.properties.street+' '+item.properties.housenumber;
        }
        if(this.editDataLocation){
          //console.log('Geocode earth has edit data');
          this.sharedModel.regionID = this.editDataLocation.region; ////////////
          this.sharedModel.id = this.editDataLocation.id;
          this.sharedModel.type = this.editDataLocation.type;
          this.sharedModel.description = this.editDataLocation.description;
          this.sharedModel.name = this.editDataLocation.name;


          if(this.editDataLocation.electric_small === 0){
            this.sharedModel.electricSmall = false;
          }
          if(this.editDataLocation.electric_small === 1){
            this.sharedModel.electricSmall = true;
          }
          if(this.editDataLocation.electric_large === 0){
            this.sharedModel.electricLarge = false;
          }
          if(this.editDataLocation.electric_large === 1){
            this.sharedModel.electricLarge = true;
          }

        }
      },
    },
  };
</script>
<style lang="scss">
</style>