<template>
  <div class="row">
    <div class="col-md-12">
      <h1>Datenschutzerklärung</h1>
      <p><strong>Einleitung</strong></p>
      <p>Der Schutz Ihrer persönlichen Daten ist uns, bei <strong>Foodtruck Locator</strong>, einem Produkt von <strong>White Rabbit Studios</strong>, sehr wichtig. Wir halten uns an die datenschutzrechtlichen Bestimmungen der Schweiz (Bundesgesetz über den Datenschutz, DSG) sowie an die Vorgaben der Europäischen Datenschutz-Grundverordnung (DSGVO), soweit anwendbar. In dieser Erklärung informieren wir Sie über die Art, den Umfang und den Zweck der Erhebung und Verwendung personenbezogener Daten bei der Nutzung unserer Website.</p>

      <h2>1. Verantwortlicher</h2>
      <p>White Rabbit Studios - Sascha Bencze<br>Dorfstrasse 29, 8966 Oberwil-Lieli, Schweiz<br>Telefon: +41 79 127 09 19<br>E-Mail: <a href="mailto:mail@whiterabbitstudios.ch">mail@whiterabbitstudios.ch</a></p>

      <h2>2. Erhebung und Verarbeitung personenbezogener Daten</h2>
      <p>Wir erheben und verarbeiten personenbezogene Daten, die Sie uns freiwillig zur Verfügung stellen, z.B. bei der Registrierung oder der Nutzung unseres Kontaktformulars. Dazu gehören:</p>
      <ul>
        <li>Vorname und Nachname</li>
        <li>Kontaktdaten (E-Mail-Adresse, Telefonnummer)</li>
        <li>Standortinformationen (falls für die Buchung relevant)</li>
      </ul>

      <h2>3. Zweck der Datenverarbeitung</h2>
      <p>Die erhobenen Daten werden zu folgenden Zwecken verwendet:</p>
      <ul>
        <li>Erstellung und Verwaltung Ihres Benutzerkontos</li>
        <li>Buchung von Standorten über die Plattform</li>
        <li>Verbesserung unseres Angebots und der Nutzerfreundlichkeit unserer Website</li>
        <li>Marketing und Werbung, sofern Sie eingewilligt haben</li>
        <li>Analyse des Nutzerverhaltens mithilfe von Google Analytics</li>
      </ul>

      <h2>4. Verwendung von Cookies</h2>
      <p>Wir verwenden Cookies, um unsere Website benutzerfreundlicher zu gestalten. Cookies sind kleine Textdateien, die auf Ihrem Endgerät gespeichert werden und uns bestimmte Informationen über Ihre Nutzung der Website bereitstellen. Einige der von uns verwendeten Cookies sind notwendig, um die grundlegende Funktionalität unserer Website zu gewährleisten, während andere uns dabei helfen, das Nutzererlebnis zu verbessern.</p>
      <p>Sie können die Verwendung von Cookies über die Einstellungen Ihres Browsers steuern oder deaktivieren.</p>

      <h2>5. Verwendung von Google Analytics</h2>
      <p>Diese Website verwendet Google Analytics, einen Webanalysedienst der Google Inc. ("Google"). Google Analytics verwendet Cookies, die eine Analyse der Benutzung der Website durch Sie ermöglichen. Die durch den Cookie erzeugten Informationen über Ihre Benutzung dieser Website werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert. Wir haben Google Analytics um die IP-Anonymisierung erweitert, um sicherzustellen, dass Ihre IP-Adresse vor der Übertragung an Google gekürzt wird.</p>

      <h2>6. Nutzung von Mapbox und GeocodeEarth</h2>
      <p>Zur Darstellung von Karten auf unserer Website und zur Bereitstellung standortbasierter Funktionen verwenden wir den Kartendienst <strong>Mapbox</strong> und die Geocodierungslösung <strong>GeocodeEarth</strong>. Diese externen Dienstleister erhalten im Rahmen der Nutzung möglicherweise Zugriff auf Standortdaten der Nutzer. Die Weitergabe dieser Daten erfolgt jedoch nur, wenn dies für die Bereitstellung der Kartendienste erforderlich ist.</p>

      <h2>7. Rechte der betroffenen Personen</h2>
      <p>Sie haben das Recht, jederzeit Auskunft über Ihre von uns verarbeiteten personenbezogenen Daten zu verlangen. Darüber hinaus können Sie die Berichtigung, Löschung oder Einschränkung der Verarbeitung Ihrer Daten verlangen. Sie haben auch das Recht, der Verarbeitung Ihrer personenbezogenen Daten zu widersprechen.</p>
      <p>Zur Ausübung dieser Rechte oder bei Fragen zum Datenschutz können Sie sich an die oben genannten Kontaktdaten wenden.</p>

      <h2>8. Änderungen dieser Datenschutzerklärung</h2>
      <p>Wir behalten uns das Recht vor, diese Datenschutzerklärung bei Bedarf zu ändern, um sie an geänderte rechtliche Rahmenbedingungen oder neue Funktionen auf unserer Website anzupassen. Bitte informieren Sie sich regelmäßig über den aktuellen Stand der Datenschutzerklärung.</p>

    </div>
  </div>
</template>

<script>
  export default {
    name: '${COMPONENT_NAME}',
    components: {
    },
    //---------------------------------------------------
    //
    //  Properties
    //
    //---------------------------------------------------
    props: {},
    //---------------------------------------------------
    //
    //  Data model
    //
    //---------------------------------------------------
    data() {
      return {};
    },
    //---------------------------------------------------
    //
    //  Computed Properties
    //
    //---------------------------------------------------
    computed: {},
    //---------------------------------------------------
    //
    //  Watch Properties
    //
    //---------------------------------------------------
    watch: {},
    //---------------------------------------------------
    //
    //  Filter Properties
    //
    //---------------------------------------------------
    // filters: {},
    //---------------------------------------------------
    //
    //  Validation Properties
    //
    //---------------------------------------------------
    // validations: {},
    //---------------------------------------------------
    //
    //  Vue Lifecycle
    //
    //---------------------------------------------------
    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // render(h) { return h(); },
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // beforeDestroy() {},
    // destroyed() {},
    //---------------------------------------------------
    //
    //  Methods
    //
    //---------------------------------------------------
    methods: {},
  };
</script>
<style lang="scss">
</style>