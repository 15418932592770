<template>
  <div>
    
      <div class="dropdown" style="margin-top:3px">
        <button :class="['btn btn-custom-select','dropdown-toggle','d-flex','justify-content-between','align-items-center',customClass]" 
                style="width:100%; padding: 0.375rem 0.75rem 0.375rem 0.75rem; appearance: none; border-radius: 0.25rem;"
                type="button" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false" data-bs-display="static">
                {{$t('form.label.categorie')}}
               
          <font-awesome-icon icon="fa-solid fa-chevron-down"/>
        </button>
        <form class="dropdown-menu" style="min-width: 100%">
          <div class="dropdown-header" v-if="searchable === true">
            <div class="form-floating mb-3">
              <input type="text" class="form-control" id="searchField" placeholder="Search" v-model="searchString">
              <label for="searchField" class="form-label">Search</label>
            </div>
          </div>
          <hr class="dropdown-divider" v-if="searchable === true">
          <ul class="list-group list-group-flush">
            <li class="list-group-item" v-for="(item, x) in filteredCategories" :key="x">
              <input class="form-check-input me-1" type="radio" :value="item.id" :id="type + '_checkbox_' + x"
                     v-model="internalSelectedCategories">
              <label class="form-check-label" :for="type + '_checkbox_' + x" style="min-width: 85%">{{item.name}}</label>
            </li>
          </ul>
        </form>
      </div>
   
  </div>
</template>
<script>
  import 'bootstrap/dist/js/bootstrap';
  export default {
    name: '${COMPONENT_NAME}',
    components: {
    },
    //---------------------------------------------------
    //
    //  Properties
    //
    //---------------------------------------------------
    props: {
      apiData: {type: Function},
      type:{required:true},
      searchable:{required:true},
      class: {
        type: [String, Object], // Akzeptiert entweder einen String oder ein Object für dynamische Klassen
        default: ''
      },
      modelValue: { type: Number, default: () => 0 },  
    },
    //---------------------------------------------------
    //
    //  Data model
    //
    //---------------------------------------------------
    data() {
      return {
        internalSelectedCategories: this.modelValue, // Erstelle eine Kopie von modelValue
        searchString:'',
        categories:[],
      };
    },
    //---------------------------------------------------
    //
    //  Computed Properties
    //
    //---------------------------------------------------
    computed: {
      filteredCategories() {    
        const filteredCategories = this.searchString.toLowerCase() === ""
            ? this.categories
            : this.categories.filter( wo => Object.values(wo).join("").toLowerCase().indexOf(this.searchString.toLowerCase()) !== -1);    
        return filteredCategories;
      },
      customClass() {
      return this.class;
    }
    },
    //---------------------------------------------------
    //
    //  Watch Properties
    //
    //---------------------------------------------------
    watch: {
      // Überwache interne Änderungen und emitte sie zurück an die Parent-Komponente
    internalSelectedCategories(newVal) {
      // Verhindere Rekursion: Nur wenn sich internalSelectedCategories vom modelValue unterscheidet
      if (newVal !== this.modelValue) {
        this.$emit('update:modelValue', newVal);
      }
    },
    // Falls die Parent-Komponente den Wert von modelValue ändert, synchronisiere die interne Kopie
    modelValue(newVal) {
      // Verhindere Rekursion: Nur wenn sich modelValue von internalSelectedCategories unterscheidet
      if (newVal !== this.internalSelectedCategories) {
        this.internalSelectedCategories = newVal;
      }
    }
    },
    //---------------------------------------------------
    //
    //  Filter Properties
    //
    //---------------------------------------------------
    // filters: {},
    //---------------------------------------------------
    //
    //  Validation Properties
    //
    //---------------------------------------------------
    // validations: {},
    //---------------------------------------------------
    //
    //  Vue Lifecycle
    //
    //---------------------------------------------------
    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // render(h) { return h(); },
    async mounted() {
      await this.getCategories();

    },
    async beforeUpdate() {
      /*
      //this.selectedCategories = [];
      if(this.editData){
        //this.selectedCategories = [];
        if(this.selectedCategories.length === 0)
        {
          for(let i = 0; i < this.editData.categories.length; i++){
            this.selectedCategories.push(this.editData.categories[i].id)
          }
          this.getCategoriesSelected(this.selectedCategories);
        }
      }
      */
    },
    // updated() {},
    // beforeDestroy() {},
    // destroyed() {},
    //---------------------------------------------------
    //
    //  Methods
    //
    //---------------------------------------------------
    methods: {
      
      async getCategories(){
        let request = {type:this.type};
        let response;
        response = await this.apiData('SandboxView getCategories()','post','categories', request);
        this.categories = response;
      }
    },
  };
</script>
<style lang="scss">
.dropdown .list-group-flush {
  max-height: 200px;
  overflow-y: scroll;
  margin-top:0 !important;
  }
  .dropdown hr{
    margin-bottom:0 !important;
    margin-top:0 !important;
  }
  .dropdown .dropdown-header{
    padding-bottom:0  !important;
  }
</style>