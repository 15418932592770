<template>
  <div>
      <h1 class="text-center mb-5">Foodtruck Locator für Foodtruckbetreiber</h1>

      <div class="row">
          <div class="col-md-6">
              <h2 class="mb-4">Finde und buche neue Standorte für Deinen Foodtruck.</h2>
              <p>Als Foodtruckbetreiber hast Du die Möglichkeit, ganz einfach neue Standorte zu entdecken und diese unkompliziert zu buchen. <strong>Foodtruck Locator</strong> bietet Dir Zugang zu vielfältigen Locations, die Du flexibel zu einem Fixpreis oder auf Auktionsbasis buchen kannst.</p>
              <p>Erhalte auf Dich zugeschnittene Vorschläge aus Deiner bevorzugten Region und präsentiere Deinen Foodtruck sowie Deine Menükarte einer breiten Öffentlichkeit – sogar ohne Login für Besucher!</p>
          </div>
          <div class="col-md-6" :style="{ backgroundImage: `url(${require('../assets/teaser_location.jpg')})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center center', minHeight: '300px' }">
          </div>
      </div>

      <div class="row mt-5 d-flex">
          <div class="col-md-12">
              <h3 class="mb-4 text-center">Deine Vorteile auf einen Blick</h3>
          </div>
          <div class="col-md-4">
              <div class="card text-center h-100">
                  <div class="card-body">
                      <h5 class="card-title">Einfaches und Schnelles Buchen</h5>
                      <p class="card-text">Buche schnell und unkompliziert Standorte für Deinen Foodtruck – egal ob zu einem Fixpreis oder per Auktion, Du hast die volle Flexibilität bei der Auswahl Deiner Standorte.</p>                   
                    </div>
              </div>
          </div>
          <div class="col-md-4">
              <div class="card text-center h-100">
                  <div class="card-body">
                      <h5 class="card-title">Neue Standorte Entdecken</h5>
                      <p class="card-text">Erkunde neue Orte für Deinen Foodtruck und erweitere Deine Reichweite. Mit <strong>Foodtruck Locator</strong> kannst Du Standorte finden, die Du bisher noch nicht in Betracht gezogen hast.</p>
                  </div>
              </div>
          </div>
          <div class="col-md-4">
              <div class="card text-center h-100">
                  <div class="card-body">
                      <h5 class="card-title">Präsentiere Deinen Foodtruck</h5>
                      <p class="card-text">Stelle Deinen Standort, Deinen Foodtruck und Deine Menükarte einer breiten Öffentlichkeit vor. Besucher können Dein Angebot auch ohne Login sehen und Du erreichst so noch mehr potenzielle Kunden.</p>
                  </div>
              </div>
          </div>
      </div>

      <div class="row mt-5 d-flex">
          <div class="col-md-6">
              <h3>So funktioniert's</h3>
              <ol class="list-group list-group-flush list-group-numbered">
                  <li class="list-group-item d-flex">
                      <div style="margin-left:5px">Melde Dich kostenlos auf unserer Plattform an und erstelle Dein Profil.</div>
                  </li>
                  <li class="list-group-item d-flex">
                      <div style="margin-left:5px">Finde verfügbare Standorte und erhalte Vorschläge basierend auf Deiner bevorzugten Region.</div>
                  </li>
                  <li class="list-group-item d-flex">
                      <div style="margin-left:5px">Buche Standorte zu einem Fixpreis oder nimm an Auktionen teil, um den besten Standort zu sichern.</div>
                  </li>
                  <li class="list-group-item d-flex">
                      <div style="margin-left:5px">Präsentiere Deinen Foodtruck und Dein Menü online und erreiche mehr Kunden.</div>
                  </li>
              </ol>
          </div>
          <div class="col-md-6">
              <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/rtF_7XJqk8g?si=rvO2920kheISSIQE" allowfullscreen width="100%" height="100%"></iframe>
          </div>
      </div>

      <div class="row mt-5">
          <div class="col-md-12">
              <div class="alert alert-primary text-center" role="alert">
                  <h4 class="alert-heading">Wir helfen Dir persönlich.</h4>
                  <p>Du hast Fragen oder benötigst Unterstützung bei den ersten Schritten? Kontaktiere uns über das <a href="#" class="alert-link" @click="$router.push('/contact')">Kontaktformular</a>. Wir unterstützen Dich gerne bei der Einrichtung Deines Profils und den ersten Buchungen.</p>
              </div>
          </div>
      </div>

      <div class="row mt-5">
          <div class="col-md-12 text-center">
              <h3>Jetzt registrieren und durchstarten.</h3>
              <p class="lead">Nutze diese Chance und buche die besten Standorte für Deinen Foodtruck.</p>
              <a href="#" class="btn btn-primary btn-lg" @click="$router.push('/register')">Jetzt registrieren</a>
          </div>
      </div>
  </div>
</template>

<script>
  export default {
    name: '${COMPONENT_NAME}',
    components: {
    },
    //---------------------------------------------------
    //
    //  Properties
    //
    //---------------------------------------------------
    props: {},
    //---------------------------------------------------
    //
    //  Data model
    //
    //---------------------------------------------------
    data() {
      return {};
    },
    //---------------------------------------------------
    //
    //  Computed Properties
    //
    //---------------------------------------------------
    computed: {},
    //---------------------------------------------------
    //
    //  Watch Properties
    //
    //---------------------------------------------------
    watch: {},
    //---------------------------------------------------
    //
    //  Filter Properties
    //
    //---------------------------------------------------
    // filters: {},
    //---------------------------------------------------
    //
    //  Validation Properties
    //
    //---------------------------------------------------
    // validations: {},
    //---------------------------------------------------
    //
    //  Vue Lifecycle
    //
    //---------------------------------------------------
    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // render(h) { return h(); },
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // beforeDestroy() {},
    // destroyed() {},
    //---------------------------------------------------
    //
    //  Methods
    //
    //---------------------------------------------------
    methods: {},
  };
</script>
<style lang="scss">
</style>