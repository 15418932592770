<template>
    <div>

      <div class="form-floating mb-3">
        <select class="form-select" aria-label="Default select example" id="roleField" v-model="user.role">
          <option selected value="trucker">{{ $t('form.select.foodtruckProviders') }}</option>
          <option value="location">{{ $t('form.select.locationProviders') }}</option>
        </select>
        <label for="roleField" class="form-label" >{{ $t('form.label.iAm') }}</label>
      </div>

      <div class="form-floating mb-3">
        <input type="text" :class="{'form-control': true, 'is-invalid': errors.name}"  id="nameField" v-model="user.name" placeholder="Name">
        <label for="nameField" class="form-label" >{{ $t('form.label.name') }}</label>
        <div class="invalid-feedback" v-if="errors.name">
          <p v-for="(item, x) in errors.name" :key='x' >{{ errors.name[x] }}</p>
        </div>
      </div>

      <div class="form-floating mb-3">
        <input type="email" :class="{'form-control': true, 'is-invalid': errors.email}"  id="emailField" v-model="user.email"  placeholder="E-Mail">
        <label  for="emailField"  class="form-label">{{ $t('form.label.email') }}</label>
        <div class="invalid-feedback" v-if="errors.email">
          <p v-for="(item, x) in errors.email" :key='x' >{{ errors.email[x] }}</p>
        </div>
      </div>

      <div class="form-floating mb-3">
        <input type="password" :class="{'form-control': true, 'is-invalid': errors.password}"  id="passwordField" v-model="user.password" placeholder="Password">
        <label  for="passwordField" class="form-label">{{ $t('form.label.password') }}</label>
        <password-meter :password="user.password" />
        <div class="invalid-feedback" v-if="errors.password">
          <p v-for="(item, x) in errors.password" :key='x' >{{ errors.password[x] }}</p>
        </div>
      </div>
      
      <div class="form-floating mb-3">
        <input type="password" :class="{'form-control': true, 'is-invalid': errors.password_confirmation}"  id="confirmField" v-model="user.password_confirmation" placeholder="Password Confirmation">
        <label for="confirmField" class="form-label">{{ $t('form.label.passwordConfirmation') }}</label>
        
        <div class="invalid-feedback"  v-if="errors.password_confirmation">
          <p v-for="(item, x) in errors.password_confirmation" :key='x' >{{ errors.password_confirmation[x] }}</p>
        </div>
      </div>
      
      <div class="mb-3 d-flex">
        <input type="checkbox" class="form-check-input" id="agbCheckbox" v-model="agb">
        <label class="form-check-label ms-2" for="agbCheckbox">Ich akzeptiere die <a href="#" @click="$router.push('/dsv')">Datenschutzverordnung</a> und die <a href="#" @click="$router.push('/agb')">AGB's</a>.</label>
      </div>

      
      <div class="mb-3">
        <button v-on:click.prevent="signUp" :disabled="!user.name || !user.email || !user.password || !user.password_confirmation || agb === false" class="form-control btn btn-primary">{{ $t('button.register') }}</button>
      </div>
        
    </div>
</template>
<script>
import PasswordMeter from 'vue-simple-password-meter';

  export default {
    
    name: 'RegisterComponent',
    components: {
      PasswordMeter
    },
    //---------------------------------------------------
    //
    //  Properties
    //
    //---------------------------------------------------
    props: {
      apiData: { type: Function },
      agbShowFunction: { type: Function },
      setLoginNav:{ type: Function },
      colorMode:{required: true}
    },
    //---------------------------------------------------
    //
    //  Data model
    //
    //---------------------------------------------------
    data() {
      return {
  
        errors:{},
        success:false,
        agb:false,
        user:{
                    role:'trucker',
                    name:'',
                    email:'',
                    password:'',
                    password_confirmation:'',
                    language:'',
                    theme:this.colorMode
                    //theme:'auto'
                   
                },
      };
    },
    //---------------------------------------------------
    //
    //  Computed Properties
    //
    //---------------------------------------------------
    computed: {},
    //---------------------------------------------------
    //
    //  Watch Properties
    //
    //---------------------------------------------------
    watch: {},
    //---------------------------------------------------
    //
    //  Filter Properties
    //
    //---------------------------------------------------
    // filters: {},
    //---------------------------------------------------
    //
    //  Validation Properties
    //
    //---------------------------------------------------
    // validations: {},
    //---------------------------------------------------
    //
    //  Vue Lifecycle
    //
    //---------------------------------------------------
    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // render(h) { return h(); },
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // beforeDestroy() {},
    // destroyed() {},
    //---------------------------------------------------
    //
    //  Methods
    //
    //---------------------------------------------------
    methods: {
       generateToken(){
        const randomNum = Math.random() * 9000
        return Math.floor(1000 + randomNum)
      },
      async signUp(){
        this.user.language = navigator.language || navigator.userLanguage;      
        this.user.theme = 'auto';      
        let response;
        response = await this.apiData('Register.vue signUp()','post','auth/signup', this.user);
        //console.log(await response);
        if (response.status === 'failure'){
          this.errors = response.errors;
          //console.log(this.errors);
          this.$toast.open({
            message: this.$t('toast.registrationFail'),
            type: 'error',
            duration:3000,
          });
        }else{
          this.errors = {};
          this.success = true;
          this.$toast.open({
            message: this.$t('toast.registrationSuccess'),
            type: 'success',
            duration:3000,
          });
          //this.$router.push('/login');
          this.user={};
          if(this.$route.path === '/')
          {
            this.setLoginNav('login');
          }else{
            this.$router.push('/login');
          }
        }       
      }
    },
  };
</script>
<style lang="scss">
</style>