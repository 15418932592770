<template>
   <div class="home">
    <LocationMetricsWidget
      :locations="locations"
      :ownedLocations="ownedLocations"
      :offerts="offerts"
      :ownedOfferts="ownedOfferts"
      :bookings="bookings"
      :ownedBookingsLocation="ownedBookingsLocation"
      :auctions="auctions"
      :ownedAuctionsLocation="ownedAuctionsLocation"
      v-if="userData.role === 'location'  || userData.role === 'admin' "
    />

    <TruckMetricsWidget
      :trucks="trucks"
      :ownedTrucks="ownedTrucks"
      :offerts="offerts"
      :bookings="bookings"
      :ownedBookingsTruck="ownedBookingsTruck"
      :auctions="auctions"
      :ownedAuctionsTruck="ownedAuctionsTruck"
      v-if="userData.role === 'trucker'  || userData.role === 'admin' "
    />
    <div class="row d-flex">
      <div class="col-md-6" v-if="userData.role === 'trucker'  || userData.role === 'admin' ">
        <TruckBookingWidget
          :ownedBookingsTruck="ownedBookingsTruck"
          
        />
      </div>
      <div class="col-md-6" v-if="userData.role === 'location'  || userData.role === 'admin' ">
        <LocationBookingWidget
          :ownedBookingsLocation="ownedBookingsLocation"
          
        />
      </div>
      
      <div class="col-md-6">
        <NotificationsWidget
          :notifications="notifications"
          :apiData ="apiData"
        />
      </div>
      <div class="col-md-6"  v-if="userData.role === 'location'  || userData.role === 'admin' ">
        <InvoicesWidget
          :invoices="invoices"
          :config="config"
          :userData="userData"
        />
      </div>
      
    </div>
    
 
  
  </div>
</template>
<script>
import LocationMetricsWidget from "@/components/DashboardWidgets/LocationMetricsWidget.vue"
import TruckMetricsWidget from "@/components/DashboardWidgets/TruckMetricsWidget.vue"
import TruckBookingWidget from "@/components/DashboardWidgets/TruckBookingWidget.vue"
import LocationBookingWidget from "@/components/DashboardWidgets/LocationBookingWidget.vue"
import NotificationsWidget from "@/components/DashboardWidgets/NotificationsWidget.vue"
import InvoicesWidget from "@/components/DashboardWidgets/InvoicesWidget.vue"

export default {
  
  name: 'HomePrivateView',
  components: {
    LocationMetricsWidget,
    TruckMetricsWidget,
    TruckBookingWidget,
    LocationBookingWidget,
    NotificationsWidget,
    InvoicesWidget
  
  },
  //---------------------------------------------------
  //
  //  Properties
  //
  //---------------------------------------------------
  props: {
    apiData: { type: Function },
    setLoginStatus: { type: Function },
    setUserData: { type: Function },
    authFunction: { type: Function },
    userData: { required: true },
    isAuthenticated: { required: true },
    isVerified: { required: true },
    config: { config: true }
  },
  //---------------------------------------------------
  //
  //  Data model
  //
  //---------------------------------------------------
  data() {
    return {
      locations:[],
      ownedLocations:[],
      trucks:[],
      ownedTrucks:[],
      offerts:[],
      ownedOfferts:[],
      bookings:[],
      ownedBookingsLocation:[],
      ownedBookingsTruck:[],
      auctions:[],
      ownedAuctionsLocation:[],
      ownedAuctionsTruck:[],
      notifications:[],
      invoices:[],

      verifyError:false,
      userDataLocal: this.userData,
      loginNav:'login',
      bindValue:"",
    };
  },
  //---------------------------------------------------
  //
  //  Computed Properties
  //
  //---------------------------------------------------
  computed: {},
  //---------------------------------------------------
  //
  //  Watch Properties
  //
  //---------------------------------------------------
  watch: {},
  //---------------------------------------------------
  //
  //  Filter Properties
  //
  //---------------------------------------------------
  // filters: {},
  //---------------------------------------------------
  //
  //  Validation Properties
  //
  //---------------------------------------------------
  // validations: {},
  //---------------------------------------------------
  //
  //  Vue Lifecycle
  //
  //---------------------------------------------------
  // beforeCreate() {},
  // created() {},
  // beforeMount() {},
  // render(h) { return h(); },
  mounted() {
    this.getLocations();
    this.getTrucks();
    this.getOfferts();
    this.getNotifications();
    this.getInvoices();
  },
  // beforeUpdate() {},
  // updated() {},
  // beforeDestroy() {},
  // destroyed() {},
  //---------------------------------------------------
  //
  //  Methods
  //
  //---------------------------------------------------
  methods: {
    /* GET LOCATIONS */
    async getLocations(){
      let request = {};
      let response;
      response = await this.apiData('HomePrivateView.vue getLocations()','post','get-locations', request);
      this.locations = response;
      for(let i = 0; i < this.locations.length; i++){
        if(this.locations[i].owner === this.userData.uuid){
          this.ownedLocations.push(this.locations[i]);  
        }
      }
    },
    /* GET LOCATIONS END */
    /* GET TRUCKS */
    async getTrucks(){
      let request = {};
      let response;
      response = await this.apiData('HomePrivateView.vue getTrucks()','post','get-trucks', request);
      this.trucks = response;
      for(let i = 0; i < this.trucks.length; i++){
        if(this.trucks[i].owner === this.userData.uuid){
          this.ownedTrucks.push(this.trucks[i]);  
        }
      }      
    },
    /* GET TRUCKS END */
    /* GET OFFERTS */
    async getOfferts(){
    let request = {};
    let response;
    response = await this.apiData('HomePrivateView.vue getTrucks()','post','get-offerts', request);
    this.offerts = response;
    for(let i = 0; i < this.offerts.length; i++){
      if(this.offerts[i].seller === this.userData.uuid){
        this.ownedOfferts.push(this.offerts[i]);  
      }
      if(this.offerts[i].buyer != null){
        this.bookings.push(this.offerts[i]);  
      }
      if(this.offerts[i].buyer === this.userData.uuid){
        this.ownedBookingsTruck.push(this.offerts[i]);  
      }
      if(this.offerts[i].seller === this.userData.uuid && this.offerts[i].buyer != null){
        this.ownedBookingsLocation.push(this.offerts[i]);  
      }
      if(this.offerts[i].price_model === 'auction'){
        this.auctions.push(this.offerts[i]);  
      }
      if(this.offerts[i].price_model === 'auction' && this.offerts[i].seller === this.userData.uuid){
        this.ownedAuctionsLocation.push(this.offerts[i]);  
      }
      if(this.offerts[i].price_model === 'auction' && this.offerts[i].buyer === this.userData.uuid){
        this.ownedAuctionsTruck.push(this.offerts[i]);  
      }
      
    }      
  },
  /* GET OFFERTS END */
  /* GET TRUCKS */
  async getNotifications(){
      let request = {
        selectorTable:'owner',
        selectorValue: this.userData.uuid
      };
      let response;
      response = await this.apiData('HomePrivateView.vue getNotifications()','post','get-notifications', request);
      this.notifications = response;   
    },
    /* GET TRUCKS END */
    /* GET INVOICES */
    async getInvoices(){
      let request = {
        selectorTable:'owner',
        selectorValue: this.userData.uuid
      };
      let response;
      response = await this.apiData('InvoicesView.vue getInvoices()','post','get-invoices', request);
      
      this.invoices = response;
      
      
    },
    /* GET INVOICES END */
      
   
  },
};
</script>
<style lang="scss">
</style>

