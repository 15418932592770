<template>
  <div>
    <form @submit.prevent="signIn"  style="margin-bottom:0 !important">
      <div class="mb-3 form-floating">
        <input type="email" :class="{'form-control': true, 'is-invalid': errors.email}"  id="emailField" v-model="user.email" placeholder="E-Mail">
        <label  for="emailField"  class="form-label">{{ $t('form.label.email') }}</label>
        <div class="invalid-feedback" v-if="errors.email">
          <p v-for="(item, x) in errors.email" :key='x' >{{ errors.email[x] }}</p>
        </div>
      </div>      
      
      <div class="mb-3 form-floating">
        <input type="password" :class="{'form-control': true, 'is-invalid': errors.password}"  id="passwordField" v-model="user.password" placeholder="Password">
        <label  for="passwordField" class="form-label">{{ $t('form.label.password') }}</label>
        <div class="invalid-feedback" v-if="errors.password">
          <p v-for="(item, x) in errors.password" :key='x' >{{ errors.password[x] }}</p>
        </div>
      </div>
      <div class="mb-3">
        <!--<button v-on:click.prevent="signIn" class="form-control btn btn-primary">Einlogen</button> -->
        <input type="submit" :value="$t('button.login')" class="form-control btn btn-primary" >
      </div>
      <a href="#" @click.prevent="$router.push('/reset-password')">{{ $t('button.forgotPassword') }}</a>
    </form>
  </div>    
</template>
<script>
  export default {
    name: 'LoginComponent',
    components: {
    },
    //---------------------------------------------------
    //
    //  Properties
    //
    //---------------------------------------------------
    props: {
      apiData: {type: Function},
      setLoginStatus: {type:Function},
      setUserData: {type:Function},
      authFunction: {type:Function},
    },
    //---------------------------------------------------
    //
    //  Data model
    //
    //---------------------------------------------------
    data() {
      return {
        errors:{},
        user:{
          email:'',
          password:'',
          token:''
        }
      };
    },
    //---------------------------------------------------
    //
    //  Computed Properties
    //
    //---------------------------------------------------
    computed: {},
    //---------------------------------------------------
    //
    //  Watch Properties
    //
    //---------------------------------------------------
    watch: {},
    //---------------------------------------------------
    //
    //  Filter Properties
    //
    //---------------------------------------------------
    // filters: {},
    //---------------------------------------------------
    //
    //  Validation Properties
    //
    //---------------------------------------------------
    // validations: {},
    //---------------------------------------------------
    //
    //  Vue Lifecycle
    //
    //---------------------------------------------------
    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // render(h) { return h(); },
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // beforeDestroy() {},
    // destroyed() {},
    //---------------------------------------------------
    //
    //  Methods
    //
    //---------------------------------------------------
    methods: {
      async signIn(){
        //console.log(this.$t('toast.loginSuccess'));
        //console.log('Einlogen');
        let response;
        response = await this.apiData('Login.vue login()','post','auth/login', this.user);

        if(typeof response === 'object'){
          if(response.token){
           
            this.setItem('access_token', response.token).then(data => {
              
            this.setLoginStatus(true);
            //console.log(response);
            
            this.setUserData();
            
            if(this.$route.name === 'login'){
              this.$router.push('/dashboard');
            }else{
              this.$router.push(this.$route.fullPath);
            }
            
            return data;
            });
            this.$toast.open({
              message: this.$t('toast.loginSuccess'),
              type: 'success',
              duration:3000,
            });

          }else{
            this.$toast.open({
              message:  this.$t('toast.loginFail'),
              type: 'error',
              duration:3000,
            });
          }
        }else{
            this.$toast.open({
              message:  this.$t('toast.loginFail'),
              type: 'error',
              duration:3000,
            });
          }
          this.authFunction();
      },
      setItem: async function (key, value) {
        let tina = await localStorage.setItem(key, value);
        return tina;
      },

    },
  };
</script>
<style lang="scss">
</style>