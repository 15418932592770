<template>
    <div>
      
      


      

      <div class="card">
        <div class="card-header">
          <div class="row">
            <div class="col-md-12 d-flex justify-content-between" style="margin-bottom:0 !important; margin-top:10px">
              <div class="form-floating mb-3 flex-grow-1">
                <input v-model="searchString" placeholder="suchen" class="form-control" id="tableSearch">
                <label for="tableSearch" class="form-label" >{{ $t('form.label.search') }}</label>
              </div>
              <div class="dropdown col-auto" >
                <div  class="dropDownBtnTable text-secondary" data-bs-toggle="dropdown" aria-expanded="false" style="padding: 0 0 0 10px;cursor: pointer;">
                  <font-awesome-icon icon="fa-solid fa-table-columns" /> 
                </div>

                <ul class="dropdown-menu">
                  <li  v-for="(item, x) in colums" :key='x'  class="dropdown-item">
                    <input type="checkbox" class="form-check-input" :id="'cb'+x" v-model="item.visible"> &nbsp;
                    <label class="form-check-label" :for="'cb'+x">{{item.name}}</label>
                  </li>
                </ul>
              </div>

            </div>
            
          </div>
          
          
        </div>
        <div class="card-body">
          <div class="table-responsive">
            <table class="table table-striped">
              <thead>
                <tr >
                  <template v-for="(item, x) in colums" :key='x'>
                  <th scope="col" v-if="item.visible">
                    <p  @click.prevent="orderBy(item.value)">{{ item.name }} 
                      <font-awesome-icon icon="fa-solid fa-circle-down" v-if="sortColumn === item.value && sortOrder === 'asc'" />
                      <font-awesome-icon icon="fa-solid fa-circle-up" v-if="sortColumn === item.value && sortOrder === 'desc'" />
                    </p>
                  </th>
                  </template>
                  <th scope="col" >
                   
                  </th>
                </tr>
              </thead>
              <tbody class="table-group-divider">
                <template v-for="(rowItem, x) in orderedRows.slice(tableSlice.start, tableSlice.end) " :key='x'>
                <tr>
                  <template  v-for="(colItem, y) in colums" :key='y'>
                  <td scope="row" v-if="colItem.visible">
                    <p v-if="colItem.type === 'text'">{{rowItem[colItem.value]}}</p>
                    <h5 v-if="colItem.type === 'status'"> <span :class="{'badge':true, 'text-bg-secondary': rowItem[colItem.value] === 'offen', 'text-bg-warning': rowItem[colItem.value] === 'fällig', 'text-bg-danger': rowItem[colItem.value] === 'überfällig', 'text-bg-success': rowItem[colItem.value] === 'bezahlt'}">{{rowItem[colItem.value]}}</span></h5>
                    <p v-if="colItem.type === 'date'" class="hiddenDate">{{rowItem[colItem.value]}}</p>
                    <p v-if="colItem.type === 'date'">{{rowItem[colItem.valueReadable]}}</p>
                    <!-- <img  v-if="colItem.type === 'image'" :src="rowItem[colItem.value]" class="tableLargeImage" /> -->
                    <div  v-if="colItem.type === 'image'" class="tableImage" :style="{ backgroundImage:'url('+rowItem[colItem.value]+')' }"></div>
                    <div  v-if="colItem.type === 'avatar'" class="tableAvatar" :style="{ backgroundImage:'url('+rowItem[colItem.value]+')' }"></div>
                  </td>
                  </template>
                  <td >
                    <div class="tableButton" @click="downloadFunction(rowItem)"><font-awesome-icon icon="fa-solid fa-circle-down" /></div>
                    <!--<div class="tableButton" @click="openFunction(rowItem)"><font-awesome-icon icon="fa-solid fa-eye" /></div>  -->
                  </td>
                </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
        <div class="card-footer">
          <nav aria-label="Page navigation example">
            <ul class="pagination">
              <li class="page-item"><a class="page-link" href="#" @click.prevent="paginationScroll('previous')">{{ $t('button.previous') }}</a></li>
              <li class="page-item" v-for="(item, x) in pageArray" :key='x'><span class="page-link active" v-if="tableSlice.active === item">{{ item }}</span><a class="page-link" href="#" @click.prevent="paginationSkip(item - 1)" v-else>{{ item }}</a></li>
              <li class="page-item"><a class="page-link" href="#" @click.prevent="paginationScroll('next')">{{ $t('button.next') }}</a></li>
            </ul>
          </nav>
        </div>
      </div>
      
      
      
    </div>
</template>
<script>

  export default {
    name: 'SortableTable',
    components: {
    },
    //---------------------------------------------------
    //
    //  Properties
    //
    //---------------------------------------------------
    props: {
      
      colums:{ required: true },
      rows:{ required: true },
      itemsPerPage:{ required: true },
      initialSortColumn: { required: true },
      sortDir: { required: true },
      userData: { required: true },
      downloadFunction:{type: Function},
      openFunction:{type: Function}
      
    },
    //---------------------------------------------------
    //
    //  Data model
    //
    //---------------------------------------------------
    data() {
      return {
        sortColumn:'',
        sortOrder: this.sortDir,
        searchString:'',
        pageArray:[],
        tableSlice:{
          start:0,
          end: 0,
          active:1
        },
      };
    },
    //---------------------------------------------------
    //
    //  Computed Properties
    //
    //---------------------------------------------------
    computed: {
      filteredRows() {
        
        const filteredRows = this.searchString.toLowerCase() === ""
            ? this.rows
            : this.rows.filter( wo => Object.values(wo).join("").toLowerCase().indexOf(this.searchString.toLowerCase()) !== -1);
            
        return filteredRows;
      },
      orderedRows: function () {
        if(this.sortOrder === 'asc'){
          return this.filteredRows.concat().sort((a,b) => a[this.sortColumn].localeCompare(b[this.sortColumn]))
        }else if(this.sortOrder === 'desc'){
          return this.filteredRows.concat().sort((a,b) => b[this.sortColumn].localeCompare(a[this.sortColumn]))
        }else{
          return 0;
        }
      }
      
     
    },
    //---------------------------------------------------
    //
    //  Watch Properties
    //
    //---------------------------------------------------
    watch: {
      filteredRows() {      
        this.pagination();
      },
      colums(){
        this.pagination();
      }
    },
    //---------------------------------------------------
    //
    //  Filter Properties
    //
    //---------------------------------------------------
    // filters: {},
    //---------------------------------------------------
    //
    //  Validation Properties
    //
    //---------------------------------------------------
    // validations: {},
    //---------------------------------------------------
    //
    //  Vue Lifecycle
    //
    //---------------------------------------------------
    // beforeCreate() {},
    // created() {},
     beforeMount() {
      this.sortColumn = this.initialSortColumn;
     },
    // render(h) { return h(); },
    mounted() {
      this.pagination();
      this.tableSlice.end = this.itemsPerPage;
     
    },
    // beforeUpdate() {},
    // updated() {},
    // beforeDestroy() {},
    // destroyed() {},
    //---------------------------------------------------
    //
    //  Methods
    //
    //---------------------------------------------------
    methods: {
      pagination(){
        this.pageArray = [];
        let pageCount = Math.ceil(this.orderedRows.length / this.itemsPerPage);
        for(let i = 0 ; i < pageCount; i++) {
            this.pageArray.push(i+1);
        }
      },
      paginationScroll(direction){
        if(direction === 'next' && this.tableSlice.end < this.orderedRows.length){
            this.tableSlice.start = this.tableSlice.start + this.itemsPerPage;
            this.tableSlice.end = this.tableSlice.end + this.itemsPerPage;
            this.tableSlice.active ++;
        }
        if(direction === 'previous' && this.tableSlice.start > 0){
            this.tableSlice.start = this.tableSlice.start - this.itemsPerPage;
            this.tableSlice.end = this.tableSlice.end - this.itemsPerPage;
            this.tableSlice.active --;
        }
      },
      paginationSkip(value){
        this.tableSlice.start = value * this.itemsPerPage;
        this.tableSlice.end = this.tableSlice.start + this.itemsPerPage;
        this.tableSlice.active = value+1;
      },
      orderBy(key){
          
          if(this.sortColumn === key && this.sortOrder === 'asc'){
            this.sortOrder = 'desc';
          }
          else if(this.sortColumn === key && this.sortOrder === 'desc'){
            this.sortOrder = 'asc';
          }
          this.sortColumn = key;
         
      }
    },
    
  };
</script>
<style lang="scss">
.tableAvatar{
  width:60px;
  height:60px;
  border-radius: 30px;
  border:2px solid #000;
  background-position: center center;
  background-size: cover;
}
.tableImage{
  width:60px;
  height:60px;
  border-radius: 4px;
  border:2px solid #000;
  background-position: center center;
  background-size: cover;
}
.tableButton{
  float: right;
  width:40px;
  height:40px;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ccc;
  margin-right:8px;
  cursor: pointer;
}
.hiddenDate{
  visibility: hidden;
  display: none;
}
.dropDownBtnTable{
  font-size: 40px;
}
</style>