<template>
    <div>
      <nav class="navbar navbar-expand-lg bg-body-tertiary">
        <div class="container-fluid">
          <a class="navbar-brand" href="#" @click.prevent="$router.push('/')"> 
            <img src="../assets/ftl_logo_neg.png" v-if="colorTheme === 'dark'">
            <img src="../assets/ftl_logo_pos.png" v-if="colorTheme === 'light'">
          </a>
          <div class="d-lg-none d-flex align-items-center ms-auto">
          <font-awesome-icon class="navbarIcon me-2" icon="fa-solid fa-circle-half-stroke" v-if="colorMode === 'auto'" @click="displayColorNavigation = !displayColorNavigation" />
          <font-awesome-icon class="navbarIcon me-2" icon="fa-solid fa-sun" v-if="colorMode === 'light'" @click="displayColorNavigation = !displayColorNavigation" />
          <font-awesome-icon class="navbarIcon me-2" icon="fa-solid fa-moon" v-if="colorMode === 'dark'" @click="displayColorNavigation = !displayColorNavigation" />
          
          <button class="navbar-toggler" type="button"  data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"  @click="toggleNavbar">
            <span class="navbar-toggler-icon"></span>
          </button>
          </div>
          <div class="collapse navbar-collapse " id="navbarSupportedContent">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
              
              <template  v-for="(item, x) in mainNavigation" :key='x'>
                <li class="nav-item"  v-if="item.display">
                  <a href="#" @click="mainNav(item, x, 'mainNavigation')" :class="{'nav-link':true, 'active': item.active}">{{item.name}}</a>
                </li>
              </template>
              <template  v-for="(item, x) in loginNavigation" :key='x'>
                    <li class="nav-item redundantLoginNavigation"  v-if="item.display">
                      <a href="#" @click.prevent="mainNav(item, x, 'loginNavigation')" :class="{'nav-link':true, 'active': item.active}">{{item.name}}</a>
                    </li>                 
              </template>
            </ul>
            <div class="d-none d-lg-flex ms-auto align-items-center">
              <div class="loginNavigation">
                <ul class="navbar-nav">
                  <template  v-for="(item, x) in loginNavigation" :key='x'>
                    <li class="nav-item" v v-if="item.display">
                      <a href="#" @click.prevent="mainNav(item, x, 'loginNavigation')" :class="{'nav-link':true, 'active': item.active}">{{item.name}}</a>
                    </li>                 
                  </template>
                </ul> 
              </div> 
            
           
              <div class="d-none d-lg-flex ms-auto align-items-center">
                <font-awesome-icon class="navbarIcon me-2" icon="fa-solid fa-circle-half-stroke" v-if="colorMode === 'auto'" @click="displayColorNavigation = !displayColorNavigation" />
                <font-awesome-icon class="navbarIcon me-2" icon="fa-solid fa-sun" v-if="colorMode === 'light'" @click="displayColorNavigation = !displayColorNavigation" />
                <font-awesome-icon class="navbarIcon me-2" icon="fa-solid fa-moon" v-if="colorMode === 'dark'" @click="displayColorNavigation = !displayColorNavigation" />
              </div>
             </div>
          </div>
        </div>
      </nav>
      <div class="card userNavigation" v-if="displayColorNavigation" v-click-outside="closeColorNavigation">
        <div class="list-group list-group-flush">
            <a :class="{'list-group-item': true, 'list-group-item-action': true, 'active': storageMode === 'light'}" href="#" @click="setColorModeInternal('light')">
              <font-awesome-icon icon="fa-solid fa-sun" />
              {{ $t('nav.color.light') }}
            </a>
            <a :class="{'list-group-item': true, 'list-group-item-action': true, 'active': storageMode === 'dark'}" href="#"  @click.passive="setColorModeInternal('dark')" >
              <font-awesome-icon icon="fa-solid fa-moon"  />
              {{ $t('nav.color.dark') }}
            </a>
            <a :class="{'list-group-item': true, 'list-group-item-action': true, 'active': storageMode === 'auto'}" href="#" @click.passive="setColorModeInternal('auto')" >
              <font-awesome-icon icon="fa-solid fa-circle-half-stroke"  />
              {{ $t('nav.color.auto') }}
            </a>
        </div>
      </div>
    </div>
</template>
<script>
 import { Collapse } from 'bootstrap';
  export default {
   
    name: 'PublicHeader',
    components: {
    },
    //---------------------------------------------------
    //
    //  Properties
    //
    //---------------------------------------------------
    props: {
      setColorMode:{type: Function},
      colorMode:{required: true} ,
      colorTheme:{required:true}
    },
    //---------------------------------------------------
    //
    //  Data model
    //
    //---------------------------------------------------
    data() {
      return {
        storageMode: localStorage.getItem('colorMode'),
        displayColorNavigation:false,
        mainNavigation:[
          {
            name:this.$t('nav.public.home'),
            active:true,
            url:'/',
            display:true
          },
          {
            name:this.$t('nav.public.foodtrucks'),
            active:false,
            url:'/trucks',
            display:true
          },
          {
            name:this.$t('nav.public.locations'),
            active:false,
            url:'/locations',
            display:true
          },
          {
            name:this.$t('nav.public.locationProviders'),
            active:false,
            url:'/ftl-for-locations',
            display:true
          },       
          {
            name:this.$t('nav.public.foodtruckProviders'),
            active:false,
            url:'/ftl-for-trucks',
            display:true
          }             
        ],
        loginNavigation:[
          {
            name:this.$t('nav.login.login'),
            active:false,
            url:'/login',
            display:true
          },
          {
            name:this.$t('nav.login.register'),
            active:false,
            url:'/register',
            display:true
          }   
        ],
      };
    },
    //---------------------------------------------------
    //
    //  Computed Properties
    //
    //---------------------------------------------------
    computed: {
     
    },
    //---------------------------------------------------
    //
    //  Watch Properties
    //
    //---------------------------------------------------
    watch: {
      $route(){
        this.setActive();
      }
    },
    //---------------------------------------------------
    //
    //  Filter Properties
    //
    //---------------------------------------------------
    // filters: {},
    //---------------------------------------------------
    //
    //  Validation Properties
    //
    //---------------------------------------------------
    // validations: {},
    //---------------------------------------------------
    //
    //  Vue Lifecycle
    //
    //---------------------------------------------------
    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // render(h) { return h(); },
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // beforeDestroy() {},
    // destroyed() {},
    //---------------------------------------------------
    //
    //  Methods
    //
    //---------------------------------------------------
    methods: {
      closeColorNavigation(){      
        if(this.displayColorNavigation === true){
          this.displayColorNavigation =false;
        }
      },
      setColorModeInternal(value){
        localStorage.setItem('colorMode', value);
        window.dispatchEvent(new Event('storage'));
        this.setColorMode(localStorage.getItem('colorMode'));
        this.storageMode = localStorage.getItem('colorMode');
        this.closeColorNavigation();

      },
      mainNav(item){
        // Collapse the navbar after clicking on a link
      const navbarToggler = document.querySelector('.navbar-collapse');
      if (navbarToggler.classList.contains('show')) {
        const bsCollapse = new Collapse(navbarToggler, {
          toggle: true
        });
        bsCollapse.hide(); // Manually hide the collapsed menu
      }
      
        this.$router.push(item.url);
      },
      
      setActive() {
        const path = this.$route.path;

        // Hauptnavigation aktiv setzen
        for (let i = 0; i < this.mainNavigation.length; i++) {
          const navUrl = this.mainNavigation[i].url;

          // Spezielle Prüfung für Home "/"
          if (navUrl === '/' && path === '/') {
            this.mainNavigation[i].active = true;
          } 
          // Behandlung für "/trucks" und "/truck/123"
          else if (navUrl === '/trucks' && (path.startsWith('/trucks') || path.startsWith('/truck'))) {
            this.mainNavigation[i].active = true;
          }
          // Behandlung für "/locations" und "/location/333"
          else if (navUrl === '/locations' && (path.startsWith('/locations') || path.startsWith('/location'))) {
            this.mainNavigation[i].active = true;
          } 
          // Standardprüfung für andere URLs
          else if (navUrl !== '/' && path.startsWith(navUrl)) {
            this.mainNavigation[i].active = true;
          } 
          else {
            this.mainNavigation[i].active = false;
          }
        }

        // Login-Navigation aktiv setzen
        for (let i = 0; i < this.loginNavigation.length; i++) {
          if (path.startsWith(this.loginNavigation[i].url)) {
            this.loginNavigation[i].active = true;
          } else {
            this.loginNavigation[i].active = false;
          }
        }
      },



            
    

      toggleNavbar() {
      const navbarToggler = document.querySelector("#navbarSupportedContent");
      const bsCollapse = new Collapse(navbarToggler, {
        toggle: false,
      });

      // Use the classList API to check and manually toggle the navbar
      if (navbarToggler.classList.contains("show")) {
        bsCollapse.hide(); // Close the navbar
      } else {
        bsCollapse.show(); // Open the navbar
      }
    },
    },
  };
</script>
<style lang="scss">
</style>