<template>
  <div>
    <div class="modal" id="addLocationModal" data-bs-backdrop="static" >
      <div class="modal-dialog modal-dialog-centered  modal-xl modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" v-if="editData">{{ $t('modal.title.editLocation') }}</h5>
            <h5 class="modal-title" v-else>{{ $t('modal.title.addLocation') }}</h5>
          </div>
          <div class="modal-body">
            <div class="row">
              <!-- LOCATION SEARCH -->
              <div class="col-md-12">
                <GeocodeEarth
                  v-model="location"
                  :images="location.images"
                  :defaultImg="defaultImg"
                  :owner="userData.uuid"
                  :editDataLocation ="editDataLocation"
                  
                />
              </div>
              <!-- LOCATION SEARCH END -->
              <!-- LOCATION NAME -->
              <div class="col-md-6">
                <div class="form-floating mb-3">
                  <input type="text" :class="{'form-control': true, 'is-invalid': errors.name}"  id="locationNameField" v-model="location.name" placeholder="Name">
                  <label for="truckNameField" class="form-label" >{{ $t('form.label.locationName') }}</label>
                  <div class="invalid-feedback" v-if="errors.name">
                    <p v-for="(item, x) in errors.name" :key='x' >{{ errors.name[x] }}</p>
                  </div>
                </div>
              </div>
              <!-- LOCATION NAME END -->
              <!-- LOCATION TYPE -->
              <div class="col-md-3">
                <div class="form-floating mb-3">
                  <CategoriesDropdown 
                    :class="{'is-invalid': errors.type }"
                    :apiData="apiData"
                    type="location"
                    v-model="location.type"
                    :searchable="false"
                    />
                    <div class="invalid-feedback d-block" v-if="errors.type">
                     <p v-for="(item, x) in errors.type" :key='x' >{{ errors.type[x] }}</p>
                  </div> 
                </div>
              </div>
              <!-- LOCATION TYPE END -->
              <!-- LOCATION REGION -->
              <div class="col-md-3">
                <div class="form-floating mb-3">
                  <RegionDropdown 
                    :apiData="apiData"
                    v-model="location.regionID"
                    :class="{'is-invalid': errors.region }"
                    :requestRegion="requestRegion"
                    :searchable="true"
                    />
                  <div class="invalid-feedback  d-block" v-if="errors.region">
                     <p v-for="(item, x) in errors.region" :key='x' >{{ errors.region[x] }}</p>
                  </div>     
                </div>
              </div>
              <!-- LOCATION REGION END -->
              <!-- LOCATION STREET -->
              <div class="col-md-6">
                <div class="form-floating mb-3">
                  <input type="text" :class="{'form-control': true, 'is-invalid': errors.street}"  id="locationStreetField" v-model="location.street" placeholder="Strasse">
                  <label for="truckNameField" class="form-label" >{{ $t('form.label.street') }}</label>
                  <div class="invalid-feedback" v-if="errors.street">
                    <p v-for="(item, x) in errors.street" :key='x' >{{ errors.street[x] }}</p>
                  </div>
                </div>
              </div>
              <!-- LOCATION STREET END -->
              <!-- LOCATION ZIP -->
              <div class="col-md-2">
                <div class="form-floating mb-3">
                  <input type="text" :class="{'form-control': true, 'is-invalid': errors.zip}"  id="locationStreetField" v-model="location.zip" placeholder="PLZ">
                  <label for="truckStreetField" class="form-label" >{{ $t('form.label.zip') }}</label>
                  <div class="invalid-feedback" v-if="errors.zip">
                    <p v-for="(item, x) in errors.zip" :key='x' >{{ errors.zip[x] }}</p>
                  </div>
                </div>
              </div>
              <!-- LOCATION ZIP END -->
              <!-- LOCATION CITY -->
              <div class="col-md-4">
                <div class="form-floating mb-3">
                  <input type="text" :class="{'form-control': true, 'is-invalid': errors.city}"  id="locationCityField" v-model="location.city" placeholder="Ort">
                  <label for="truckCityField" class="form-label" >{{ $t('form.label.city') }}</label>
                  <div class="invalid-feedback" v-if="errors.city">
                    <p v-for="(item, x) in errors.city" :key='x' >{{ errors.city[x] }}</p>
                  </div>
                </div>
              </div>
              <!-- LOCATION CITY END -->
              <!-- LOCATION LAT -->
              <div class="col-md-3">
                <div class="form-floating mb-3">
                  <input type="text" :class="{'form-control': true, 'is-invalid': errors.lat}"  id="locationLatField" v-model="location.lat" placeholder="Breitengrad">
                  <label for="truckLatField" class="form-label" >{{ $t('form.label.lat') }}</label>
                  <div class="invalid-feedback" v-if="errors.lat">
                    <p v-for="(item, x) in errors.lat" :key='x' >{{ errors.lat[x] }}</p>
                  </div>
                </div>
              </div>
              <!-- LOCATION LAT END -->
              <!-- LOCATION LONG -->
              <div class="col-md-3">
                <div class="form-floating mb-3">
                  <input type="text" :class="{'form-control': true, 'is-invalid': errors.long}"  id="locationLongField" v-model="location.long" placeholder="Längengrad">
                  <label for="truckLongField" class="form-label" >{{ $t('form.label.long') }}</label>
                  <div class="invalid-feedback" v-if="errors.long">
                    <p v-for="(item, x) in errors.long" :key='x' >{{ errors.long[x] }}</p>
                  </div>
                </div>
              </div>
              <!-- LOCATION LONG END -->
              <!-- LOCATION 220V -->
              <div class="col-md-3 d-flex align-items-center">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" id="220V" v-model="location.electricSmall" >
                  <label class="form-check-label" for="220V">
                    {{ $t('form.label.electricSmall') }}
                  </label>
                </div>
              </div>
              <!-- LOCATION 220V END -->  
              <!-- LOCATION 400V -->
              <div class="col-md-3  d-flex align-items-center">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" id="400V" v-model="location.electricLarge" >
                  <label class="form-check-label" for="400V">
                    {{ $t('form.label.electricLarge') }}
                  </label>
                </div>
              </div>
              <!-- LOCATION 400V END -->  
              <!-- DESCRIPTION --> 
              <div class="col-md-12">
                <div class="form-floating mb-3">
                  <textarea :class="{'form-control': true, 'is-invalid': errors.description}" id="locationDescriptionField" v-model="location.description" placeholder="Beschreibung" style="height: 150px"></textarea>
                  <label for="locationDescriptionField" class="form-label" >{{ $t('form.label.description') }}</label>
                  <div class="invalid-feedback" v-if="errors.description">
                    <p v-for="(item, x) in errors.name" :key='x' >{{ errors.description[x] }}</p>
                  </div>
                </div>
              </div>
              <!-- DESCRIPTION END -->    
            </div>
            <!-- LOCATION DROPZONE -->
            <label for="dropzone" class="form-label" >{{ $t('form.label.addImage') }}</label>
            <vue-dropzone
              ref="myVueDropzone" 
              id="dropzone" 
              :options="dropzoneOptions"
              @vdropzone-success="successAction"
              @vdropzone-error="verror"
            />
            <!-- LOCATION DROPZONE END -->
            <!-- LOCATION PREVIEWS -->
            <div class="card" style="margin-top:20px; min-height:230px">
              <div class="card-body d-flex flex-row flex-wrap">
                <div v-for="(item, x) in previews" :key='x' class="truckTmbContainer">
                  <div class="truckTmb"  :style="{ backgroundImage:'url('+config.storageURL+userData.uuid+'/img/'+item.name+')' }">           
                  </div>
                  <div class="truckTmbBody">
                    <input class="form-check-input" type="radio" name="defaultImg" v-model="location.defaultImg" :value="item.id" :id="'defaultImg_'+ x">
                    <label class="form-check-label" :for="'defaultImg_'+ x">Hauptbild</label>
                    <button class="btn btn-danger" @click="deleteFileTmp(item)" v-if="this.editDataLocation" >{{$t('button.delete')}}</button>
                    <button class="btn btn-danger" @click="deleteFile(item)" v-else>{{$t('button.delete')}}</button>
                  </div>
                </div>
              </div>
            </div>
            <!-- LOCATION PREVIEWS END -->
          </div>
          <div class="modal-footer">   
            <button type="button" class="btn btn-secondary" @click="modalHandling('addLocationModal')">{{$t('button.cancel')}}</button>
            <button type="button" class="btn btn-primary" @click="updateLocation()" v-if="editDataLocation">{{$t('button.update')}}</button>
            <button type="button" class="btn btn-primary" @click="addLocation()" v-else>{{$t('button.save')}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import GeocodeEarth from "@/components/GeocodeEarth.vue";
import CategoriesDropdown from '@/components/CategoriesDropdownSingle.vue';
import RegionDropdown from '@/components/RegionDropdownSingle.vue';
import { Modal } from "bootstrap";
import vueDropzone from 'vue2-dropzone-vue3'
export default {
  name: '${COMPONENT_NAME}',
  components: {
    vueDropzone,
    CategoriesDropdown,
    RegionDropdown,
    GeocodeEarth
  },
  //---------------------------------------------------
  //
  //  Properties
  //
  //---------------------------------------------------
  props: {
    userData: {required: true},
    editDataLocation: {required: true},
    addLocationModal: {required: true},
    authFunction: {type: Function},
    apiData: {type: Function},
    modalHandling: {type: Function},
    getLocations: {type: Function},
    config:{required: true}, 
  },
  //---------------------------------------------------
  //
  //  Data model
  //
  //---------------------------------------------------
  data() {
    return {
      previews:[],
      request:{
        type:'foodtruck'
      },
      requestRegion:{
       // country:'ch',
        selectorTable:'country',
        selectorValue:'ch',

      },
      errors:{},
      location:{
        name:'',
        street:'',
        zip:'',
        city:'',
        long:'',
        region:'',
        regionID:'',
        images:[],
        defaultImg:'',
        description:'',
        type:'',
        electricSmall:false,
        electricLarge:false,
        owner:this.userData.uuid,
      },
      
      dropzoneOptions: {
        url: this.config.apiURL+'api/'+this.config.apiVersion+'/dropzone',
        headers: {
            owner: localStorage.getItem('userUUID'),
            'Cache-Control': null,
            'X-Requested-With': null,
            'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
            'enctype':'multipart/form-data',       
        },  
        maxFilesize: 1, // MB
        maxFiles: 40,
        thumbnailWidth: 150, // px
        thumbnailHeight: 150,
        addRemoveLinks: true,
        acceptedFiles:'image/*',
      },
    
    };
  },
  //---------------------------------------------------
  //
  //  Computed Properties
  //
  //---------------------------------------------------
  computed: {},
  //---------------------------------------------------
  //
  //  Watch Properties
  //
  //---------------------------------------------------
  watch: {
    location(){
      if(this.location.region){
       // console.log('REGION IST JETZT');
       // console.log(this.location.region);
        this.getRegionSelected();
        
      }
    },
    addLocationModal(){
      //console.log('editDataLocation child');
      //console.log(this.editDataLocation);
      if(this.addLocationModal === true)
      {
      if(this.editDataLocation){
       //console.log('mi händ edit date!');
        this.errors = {};
      this.previews = [];
      this.location =  this.editDataLocation;
      this.location.type = this.editDataLocation.typeID;
      this.location.region = this.editDataLocation.regionID;
      this.location.regionID = this.editDataLocation.regionID;
      
      if(this.location.electric_small === 0){
        this.location.electricSmall = false;
      }
      if(this.location.electric_small === 1){
        this.location.electricSmall = true;
      }
      if(this.location.electric_large === 0){
        this.location.electricLarge = false;
      }
      if(this.location.electric_large === 1){
        this.location.electricLarge = true;
      }
      
      this.loadPreviews();
      
    }else{
      this.errors = {};
      this.location={
        name:'',
        street:'',
        zip:'',
        city:'',
        long:'',
        region:'',
        images:[],
        defaultImg:'',
        description:'',
        type:'',
      }
      this.location.owner=this.userData.uuid;
      
    }
        //console.log('CHANGE LOCATION MODAL TRUE');
        this.uniqueModal.show();
      }
      if(this.addLocationModal === false)
      {
        this.uniqueModal.hide();
      }
     // console.log('CHANGE LOCATION MODAL' + this.addLocationModal);
    }
  },
  //---------------------------------------------------
  //
  //  Filter Properties
  //
  //---------------------------------------------------
  // filters: {},
  //---------------------------------------------------
  //
  //  Validation Properties
  //
  //---------------------------------------------------
  // validations: {},
  //---------------------------------------------------
  //
  //  Vue Lifecycle
  //
  //---------------------------------------------------
  // beforeCreate() {},
  // created() {},
  // beforeMount() {},
  // render(h) { return h(); },
  async mounted() {
    
      
    this.uniqueModal = new Modal(document.getElementById("addLocationModal"),{ keyboard: false });
  },
  async beforeUpdate() {
   
  },
  // async updated() {},
  // beforeDestroy() {},
  // destroyed() {},
  //---------------------------------------------------
  //
  //  Methods
  //
  //---------------------------------------------------
  methods: {
    async getRegionSelected(){
      let response;
      let request;


      if(typeof this.location.region === 'string'){
        request ={
        selectorTable:'short',
        selectorValue:this.location.region
        }
        //console.log('IST EIN STRING');
        //console.log(this.location.region);

      }else{
        request ={
        selectorTable:'id',
        selectorValue:this.location.regionID
        
        }
       // console.log('IST EIN INTEGER');
        //console.log(this.location.region);
      }
     
      
      response = await this.apiData('addLocation.vue getRegionSelected()','post','region', request);
     // console.log('RESPONSE FROM GET REGION SELECTED');
    
      this.location.regionID = response[0].id;
     // console.log(request);
      //console.log(response);

    },
    /* ##################### ADD LOCATION ##################### */
    async addLocation(){
      //console.log(this.location)
      
      let response;
      response = await this.apiData('AddLocation.vue addLocation()','post','add-location', this.location);
      if (response.status === 'failure'){
        this.errors = response.errors;
        this.$toast.open({
          message: this.$t('toast.error'),
          type: 'error',
          duration:3000,
        });
      }else{
        //this.uniqueModal.hide();
        this.modalHandling('addLocationModal');
        this.getLocations();
        this.errors = {};
        this.$toast.open({
          message: this.$t('toast.addLocation'),
          type: 'success',
          duration:3000,
        });
        
      }  
    },
    /* ##################### ADD LOCATION END ##################### */
    /* ##################### UPDATE LOCATION ##################### */
    async updateLocation(){
        //console.log('UPDATE DATA');
        //console.log(this.location);
      
      let response;
      response = await this.apiData('AddLocation.vue updateLocation()','post','update-location', this.location);
      if (response.status === 'failure'){
        this.errors = response.errors;
        this.$toast.open({
          message: this.$t('toast.error'),
          type: 'error',
          duration:3000,
        });
      }else{
        this.modalHandling('addLocationModal');
        this.getLocations();
        this.errors = {};
        this.$toast.open({
          message: this.$t('toast.editLocation'),
          type: 'success',
          duration:3000,
        });      
      }  
    },
    /* ##################### UPDATE LOCATION END ##################### */
    /* ##################### FILE HANDLING ##################### */
    async deleteFile(object){
      let response;
      response = await this.apiData('AddTruck.vue deleteFile()','post','delete-file', object);
      console.log(response);
      
      this.truck.images.splice(this.truck.images.indexOf(object.id), 1);
      
      if(object.id === this.truck.defaultImg){
        this.truck.defaultImg = null;
      }
      this.loadPreviews();
      //console.log('Objekt ID: '+ object.id);
      //console.log('Default IMG: '+ this.truck.defaultImg);
      //console.log(this.truck.images);
    },
    
    async deleteFileTmp(object) { 
        // Finde den Index des Objekts in location.images anhand der ID
        const imageIndex = this.location.images.indexOf(object.id);
        
        if (imageIndex !== -1) {
            // Entferne die ID aus location.images
            this.location.images.splice(imageIndex, 1);
        }

        // Finde den Index des Objekts in previews anhand des gesamten Objekts
        const previewIndex = this.previews.indexOf(object);

        if (previewIndex !== -1) {
            // Entferne das Objekt aus previews
            this.previews.splice(previewIndex, 1);
        }

        // Überprüfe, ob das gelöschte Objekt das Standardbild war
        if (object.id === this.location.defaultImg) {
            // Setze das erste Bild als Standardbild, wenn noch Bilder vorhanden sind
            if (this.location.images.length > 0) {
                this.location.defaultImg = this.location.images[0];
            } else {
                this.location.defaultImg = null;
            }
        }
    },

    async successAction(file, response){
        //await this.getFiles();
        this.$refs.myVueDropzone.removeFile(file);
        //console.log('############### UPLOAD ERFOLGREICH ###############')
        //console.log(response);
        this.location.images.push(response.id);
        this.loadPreviews();
      },
      verror(file, response){

        //console.log(file);
        //console.log("------------------------------------------------------------------------------------------");
        console.log(response);
        //console.log("------------------------------------------------------------------------------------------");
      },
      async loadPreviews(){
        
        let imgResponse;
        imgResponse = await this.apiData('AddLocation.vue loadPreviews()','post','get-img-by-id', this.location.images);
        if(!this.location.defaultImg){
          this.location.defaultImg = this.location.images[0];
        }
        this.previews = imgResponse;
      },
      /* ##################### FILE HANDLING END ##################### */
      
  },
};
</script>
<style lang="scss">
.truckTmb{

width:118px;
height:80px;
background-position: center center;
background-size: cover;
}
.truckTmbContainer{
margin:10px;
width:120px;
border:1px solid var(--bs-border-color);
}
.truckTmbBody{
margin:10px 10px 10px 10px;
}
.truckTmbBody button{
width:100%;
}
.truckTmbBody label{
margin-bottom:10px;
}
.truckTmbBody input{
margin-right:5px;
}
.truckTmbBody button{
margin-bottom:0 !important;
}
</style>