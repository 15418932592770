<template>
    <div class="row d-flex">
      <!-- LOCATIONS -->
      <div class="col-md-3 col-6">
        <div  class="card h-100">
          <div class="card-body">
            <h6>{{ $t('card.title.locations') }}</h6>
            <h2>{{ ownedLocations.length }}/{{ locations.length }}</h2>
          </div>
        </div>
      </div>
      <!-- LOCATIONS END -->
      <!-- OFFERTS -->
      <div class="col-md-3 col-6">
        <div  class="card h-100">
          <div class="card-body">
            <h6>{{ $t('card.title.offerts') }}</h6>
            <h2>{{ ownedOfferts.length }}/{{ offerts.length }}</h2>
          </div>
        </div>
      </div>
      <!-- OFFERTS END -->
      <!-- BOOKINGS -->
      <div class="col-md-3 col-6">
        <div  class="card h-100">
          <div class="card-body">
            <h6>{{ $t('card.title.bookings') }}</h6>
            <h2>{{ ownedBookingsLocation.length }}/{{ bookings.length }}</h2>
          </div>
        </div>
      </div>
      <!-- BOOKINGS END -->
      <!-- AUCTIONS -->
      <div class="col-md-3 col-6">
        <div  class="card h-100">
          <div class="card-body">
            <h6>{{ $t('card.title.auctions') }}</h6>
            <h2>{{ ownedAuctionsLocation.length }}/{{ auctions.length }}</h2>
          </div>
        </div>
      </div>
      <!-- AUCTIONS END -->
    </div>
</template>
<script>
  export default {
    name: 'LocationsMetricsWidget',
    components: {
    },
    //---------------------------------------------------
    //
    //  Properties
    //
    //---------------------------------------------------
    props: {
      locations: {required:true},
      ownedLocations: {required:true},
      offerts: {required:true},
      ownedOfferts: {required:true},
      bookings: {required:true},
      ownedBookingsLocation: {required:true},
      auctions: {required:true},
      ownedAuctionsLocation: {required:true},
    },
    //---------------------------------------------------
    //
    //  Data model
    //
    //---------------------------------------------------
    data() {
      return {};
    },
    //---------------------------------------------------
    //
    //  Computed Properties
    //
    //---------------------------------------------------
    computed: {},
    //---------------------------------------------------
    //
    //  Watch Properties
    //
    //---------------------------------------------------
    watch: {},
    //---------------------------------------------------
    //
    //  Filter Properties
    //
    //---------------------------------------------------
    // filters: {},
    //---------------------------------------------------
    //
    //  Validation Properties
    //
    //---------------------------------------------------
    // validations: {},
    //---------------------------------------------------
    //
    //  Vue Lifecycle
    //
    //---------------------------------------------------
    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // render(h) { return h(); },
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // beforeDestroy() {},
    // destroyed() {},
    //---------------------------------------------------
    //
    //  Methods
    //
    //---------------------------------------------------
    methods: {},
  };
</script>
<style lang="scss">
</style>