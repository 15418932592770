<template>
    <div>
      
      <div class="row d-flex">
        <!-- CARD COMPANY -->
        <div class="col-md-4">
          <div class="card h-100">
            <div class="card-body  text-center justify-content-center align-items-center d-flex flex-column">
              
              <div class="profileLogoContainer placeHolder" v-if="!userData.user_details.logo" @click="logoUploadModal = true">
                {{ $t('button.addLogo') }}
              </div>
              <div class="profileLogoContainer" v-else>
                <img alt="Logo" :src="config.storageURL+userData.uuid+'/img/'+userData.user_details.logo"  />
              </div>
              
              <h3>{{ userData.user_details.company }}</h3>
              <p>{{ userData.email }}</p>
            </div>
            <div class="card-footer justify-content-end d-flex">
              <button class="btn btn-secondary" @click="logoUploadModal = true">
                <font-awesome-icon icon="fa-solid fa-arrow-up-from-bracket" /> 
                {{ $t('button.addLogo') }}
              </button>
            </div>
          </div>
        </div>
        <!-- CARD COMPANY END -->
        <!-- CARD DETAILS -->
        <div class="col-md-8">
          <div class="card h-100">
            <div class="card-body">
              <div class="row">
                <div class="col-sm-3">
                  <p class="mb-0"> {{ $t('form.label.company') }}</p>
                </div>
                <div class="col-sm-9">
                  <p class="text-muted mb-0">{{ userData.user_details.company }}</p>
                </div>
              </div>
              <hr>
              <div class="row">
                <div class="col-sm-3">
                  <p class="mb-0">{{ $t('form.label.name') }}</p>
                </div>
                <div class="col-sm-9">
                  <p class="text-muted mb-0">{{ userData.user_details.first_name }} {{ userData.user_details.last_name }}</p>
                </div>
              </div>
              <hr>
              <div class="row">
                <div class="col-sm-3">
                  <p class="mb-0">{{ $t('form.label.street') }}</p>
                </div>
                <div class="col-sm-9">
                  <p class="text-muted mb-0">{{ userData.user_details.street }}</p>
                </div>
              </div>
              <hr>
              <div class="row">
                <div class="col-sm-3">
                  <p class="mb-0">{{ $t('form.label.address') }}</p>
                </div>
                <div class="col-sm-9">
                  <p class="text-muted mb-0">{{ userData.user_details.zip }} {{ userData.user_details.city }}</p>
                </div>
              </div>
              <hr>
              <div class="row">
                <div class="col-sm-3">
                  <p class="mb-0">{{ $t('form.label.country') }}</p>
                </div>
                <div class="col-sm-9">
                  <p class="text-muted mb-0">{{ userData.user_details.country }}</p>
                </div>
              </div>
              <hr>
              <div class="row">
                <div class="col-sm-3">
                  <p class="mb-0">{{ $t('form.label.phone') }}</p>
                </div>
                <div class="col-sm-9">
                  <p class="text-muted mb-0">{{ userData.user_details.phone }}</p>
                </div>
              </div>
              <hr v-if="userData.user_details.url">
              <div class="row" v-if="userData.user_details.url">
                <div class="col-sm-3">
                  <p class="mb-0">{{ $t('form.label.website') }}</p>
                </div>
                <div class="col-sm-9">
                  <p class="text-muted mb-0">{{ userData.user_details.url }}</p>
                </div>
              </div>
            </div>
            <div class="card-footer justify-content-end d-flex">
              <button class="btn btn-secondary" @click="editUserDetailsModal = true">
                <font-awesome-icon icon="fa-solid fa-pen" /> 
                {{ $t('button.edit') }}
              </button>
            </div>
          </div>
        </div>
        <!-- CARD DETAILS END -->
      </div> 
      <template v-if="userData.role === 'trucker' || userData.role === 'admin' ">
      <!-- TRUCKS HEADER -->
      <div class="row">
        <div class="col-md-12">
          <div class="card text-bg-card-header  ">
            <div class="card-body   d-flex justify-content-between">
              <h4>{{ $t('card.title.yourTrucks') }}</h4>
              <button class="btn btn-secondary"  @click="addTruck()">
                <font-awesome-icon icon="fa-solid fa-plus" /> 
                {{ $t('button.addTruck') }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- TRUCKS HEADER END -->
      <!-- TRUCKS TEASER -->
      <div class="row" v-if="trucks.length === 0">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body text-center">
              <h5>{{ $t('teaser.title.truck') }}</h5>
              <h6>{{ $t('teaser.content.truckBooking') }}</h6>
            </div>
          </div>
        </div>
      </div>
      <!-- TRUCKS TEASER END -->
      <!-- TRUCKS CARDS -->
      <div class="row" v-for="(item, x) in trucksGrouped" :key='x'>
        <div class="col-md-6 d-flex" v-for="(subitem, y) in item" :key='y' style="margin-bottom:0 !important">
          <!-- CARD -->
          <div class="card mb-3 truckCard d-flex" style="width:100%">
            <div class="row h-100 d-flex" style="margin-left: 0 !important; ">
              <div class="col-4 truckCardImg d-flex" v-if="subitem.default_img" :style="{ backgroundImage:'url('+config.storageURL+userData.uuid+'/img/'+subitem.default_img.name+')' }">
               
              </div>
              <div class="col-4 truckCardImg d-flex" v-else :style="{ backgroundImage: `url(${require('@/assets/default_truck.png')})` }" >

              </div>
              <div class="col-8 d-flex flex-column" style="margin-bottom: 0 !important; padding-left:0">
                <div class="card-body">
                  <h5 class="card-title">{{ subitem.name }}</h5>
                    <p class="card-text">
                      <span class="badge bg-secondary" v-for="(categorie, z) in subitem.categories" :key='z' style="margin-right:5px">{{categorie.name}}</span>&nbsp;
                    </p>
                    <p class="card-text"><small class="text-body-secondary">{{ $t('form.label.region') }}: <span  class="region" v-for="(region, i) in subitem.region" :key='i'>{{ region.short }}</span></small></p>
                  </div>
                <div class="card-footer align-self-end justify-content-end d-flex" style="width:100%">
                  <!-- <button class="btn btn-secondary"  @click="editTruck(subitem, x)">Bearbeiten</button> -->
                  <!-- FUNCTION DROPDOWN -->
                  <div class="btn-group dropup">
                    <div  class="" data-bs-toggle="dropdown" aria-expanded="false" style="padding: 0 10px 0 10px;cursor: pointer;">
                      <font-awesome-icon icon="fa-solid fa-ellipsis-vertical" /> 
                    </div>
                    <div class="dropdown-menu " style="padding:0"> 
                      <ul class="list-group list-group-flush" style="border-radius: 5px;">
                        <a :class="{'list-group-item': true, 'list-group-item-action': true,}" href="#"  @click.prevent="$router.push('truck/'+subitem.id)">
                          <font-awesome-icon icon="fa-solid fa-eye" />
                          {{ $t('button.view') }}
                          
                        </a>
                        <a :class="{'list-group-item': true, 'list-group-item-action': true,}" href="#"  @click.passive="editTruck(subitem, x)" >
                        <font-awesome-icon icon="fa-solid fa-pen"  />
                        {{ $t('button.edit') }}
                        </a>
                        <a :class="{'list-group-item': true, 'list-group-item-action': true,}" href="#" v-if="subitem.hasBookings === 0" @click.prevent="deleteTruckWarning(subitem)">
                          <font-awesome-icon icon="fa-solid fa-trash"  />
                          {{ $t('button.delete') }}
                        </a>
                      </ul>
                    </div>
                  </div>
                  <!-- FUNCTION DROPDOWN END -->
                </div>
              </div>
            </div>
          </div>
          <!-- END CARD -->
        </div>
      </div>
      <!-- TRUCKS CARDS END -->
      </template>
      <template v-if="userData.role === 'location'  || userData.role === 'admin' ">
      <!-- LOCATION HEADER -->
      <div class="row">
        <div class="col-md-12">
          <div class="card text-bg-card-header  ">
            <div class="card-body   d-flex justify-content-between">
              <h4>{{ $t('card.title.yourLocations') }}</h4>
              <button class="btn btn-secondary"  @click="addLocation()">
                <font-awesome-icon icon="fa-solid fa-plus" /> 
                {{ $t('button.addLocation') }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- LOCATION HEADER END -->
      <!-- LOCATIONS TEASER -->
      <div class="row" v-if="locations.length === 0">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body text-center">
              <h5>{{ $t('teaser.title.location') }}</h5>
              <h6>{{ $t('teaser.content.location') }}</h6>
            </div>
          </div>
        </div>
      </div>
      <!-- LOCATIONS TEASER END -->
      <!-- LOCATION CARDS -->
      <div class="row" v-for="(item, x) in locationsGrouped" :key='x'>
        <div class="col-md-6 d-flex" v-for="(subitem, y) in item" :key='y'>
          <div class="card mb-3 truckCard d-flex" style="width:100%">
            <div class="row h-100 d-flex" style="margin-left: 0 !important; ">
              <div class="col-4 truckCardImg d-flex" v-if="subitem.default_img" :style="{ backgroundImage:'url('+config.storageURL+userData.uuid+'/img/'+subitem.default_img.name+')' }">
                
              </div>
              <div class="col-4 truckCardImg d-flex" v-else :style="{ backgroundImage: `url(${require('@/assets/default_location.png')})` }" >

              </div>
              <div class="col-8 d-flex flex-column" style="margin-bottom: 0 !important; padding-left:0">
                <div class="card-body">
                  <h5 class="card-title">{{ subitem.name }}</h5>
                  
                  <ul class="list-group list-group-flush">
                    <li class="list-group-item"  style="padding-left:0" v-if="subitem.street">{{ $t('form.label.street') }}: {{ subitem.street }}</li>
                    <li class="list-group-item"  style="padding-left:0">{{ $t('form.label.city') }}: {{ subitem.city }}</li>
                    <li class="list-group-item"  style="padding-left:0">{{ $t('form.label.type') }}: {{ subitem.type.name }}</li>
                    <li class="list-group-item"  style="padding-left:0">{{ $t('form.label.region') }}: {{ subitem.region.name }}</li>
                  </ul>
                </div>
                <div class="card-footer align-self-end justify-content-end d-flex" style="width:100%">
                  <!-- FUNCTION DROPDOWN -->
                  <div class="btn-group dropup">
                    <div  class="" data-bs-toggle="dropdown" aria-expanded="false" style="padding: 0 10px 0 10px;cursor: pointer;">
                      <font-awesome-icon icon="fa-solid fa-ellipsis-vertical" /> 
                    </div>
                    <div class="dropdown-menu " style="padding:0"> 
                      <ul class="list-group list-group-flush" style="border-radius: 5px;">
                        <a :class="{'list-group-item': true, 'list-group-item-action': true,}" href="#"  @click.prevent="$router.push('location/'+subitem.id)">
                          <font-awesome-icon icon="fa-solid fa-eye" />
                          {{ $t('button.view') }}
                          
                        </a>
                        <a :class="{'list-group-item': true, 'list-group-item-action': true,}" href="#"  @click.passive="editLocation(subitem, x)" >
                        <font-awesome-icon icon="fa-solid fa-pen"  />
                        {{ $t('button.edit') }}
                        </a>
                        <a :class="{'list-group-item': true, 'list-group-item-action': true,}" href="#"  @click.prevent="deleteLocationWarning(subitem)" v-if="subitem.hasBookings === 0" >
                          <font-awesome-icon icon="fa-solid fa-trash"  />
                          {{ $t('button.delete') }}
                        </a>
                      </ul>
                    </div>
                  </div>
                  <!-- FUNCTION DROPDOWN END -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- LOCATION CARDS END -->
      </template>
      <template v-if="userData.role === 'trucker'  || userData.role === 'admin' ">
      <!-- MENUCARDS HEADER -->
      <div class="row">
        <div class="col-md-12">
          <div class="card text-bg-card-header  ">
            <div class="card-body   d-flex justify-content-between">
              <h4>{{ $t('card.title.yourCards') }}</h4>
              <button class="btn btn-secondary"  :disabled="trucks.length === 0" @click="addMenuCard()">
              <font-awesome-icon icon="fa-solid fa-plus" /> 
              {{ $t('button.addMenucard') }}
            </button>
            </div>
          </div>
        </div>
      </div>
      <!-- MENUCARDS HEADER END -->
      <!-- MENUCARDS TEASER -->
      <div class="row" v-if="menuCards.length === 0 &&  trucks.length > 0">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body text-center">
              <h5>{{ $t('teaser.title.menucard') }}</h5>
              <h6>{{ $t('teaser.content.menucard') }}</h6>
            </div>
          </div>
        </div>
      </div>
      <div class="row" v-if="menuCards.length === 0 && trucks.length === 0">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body text-center">
              <h5>{{ $t('teaser.title.menucard') }}</h5>
              <h6>{{ $t('teaser.content.menucardNoTruck') }}</h6>
            </div>
          </div>
        </div>
      </div>
      <!-- MENUCARDS TEASER END -->
      
      <!-- MENUCARDS -->
       <div class="row"  v-for="(item, x) in menuCards" :key='x'>
        <div class="col-md-12">
          <div class="card" >
            
            <template v-for="(categories, y) in item.categories" :key='y'>
              <div class="card-header d-flex w-100 align-items-center justify-content-between">
                <h5>{{ categories.name }}</h5>
                <button class="btn btn-secondary" @click="addMenuCardItem(item.id, categories.id)">
                  <font-awesome-icon icon="fa-solid fa-plus" /> 
                  {{ $t('button.addMenucardItem') }}
                </button>
              </div>

              <ul class="list-group list-group-flush">
                <li class="list-group-item"  v-for="(menucardItems, z) in categories.content" :key='z'  >
                  <div class="d-flex w-100 justify-content-between">
                    <h5 class="mb-1">{{menucardItems.title}}</h5>
                    <h5>CHF {{menucardItems.price}}</h5>
                  </div>
                  <div class="d-flex w-100 justify-content-between align-items-end">
                    <p class="mb-1">{{menucardItems.text}}</p>
                      <!-- FUNCTION DROPDOWN -->
                      <div class="btn-group dropup">
                        <div  class="" data-bs-toggle="dropdown" aria-expanded="false" style="padding: 0 10px 0 10px;cursor: pointer;">
                          <font-awesome-icon icon="fa-solid fa-ellipsis-vertical" /> 
                        </div>
                        <div class="dropdown-menu " style="padding:0"> 
                          <ul class="list-group list-group-flush" style="border-radius: 5px;">
                            <a :class="{'list-group-item': true, 'list-group-item-action': true,}" href="#"  @click.prevent="editMenucardItem(menucardItems)" >
                            <font-awesome-icon icon="fa-solid fa-pen"  />
                            {{ $t('button.edit') }}
                            </a>
                            <a :class="{'list-group-item': true, 'list-group-item-action': true,}" href="#"  @click.prevent="deleteMenucardItemWarning(menucardItems)">
                              <font-awesome-icon icon="fa-solid fa-trash"  />
                              {{ $t('button.delete') }}
                            </a>
                          </ul>
                        </div>
                      </div>
                      <!-- FUNCTION DROPDOWN END -->
                  </div>
                </li>
              </ul>
            </template>
            <div class="card-footer d-flex w-100 justify-content-between">
              <h6>{{ item.name }}</h6>
              <!-- FUNCTION DROPDOWN -->
              <div class="btn-group dropup">
                <div  class="" data-bs-toggle="dropdown" aria-expanded="false" style="padding: 0 10px 0 10px;cursor: pointer;">
                  <font-awesome-icon icon="fa-solid fa-ellipsis-vertical" /> 
                </div>
                <div class="dropdown-menu " style="padding:0"> 
                  <ul class="list-group list-group-flush" style="border-radius: 5px;">
                    <a :class="{'list-group-item': true, 'list-group-item-action': true,}" href="#"  @click.prevent="editMenucard(item)" >
                    <font-awesome-icon icon="fa-solid fa-pen"  />
                    {{ $t('button.edit') }}
                    </a>
                    <a :class="{'list-group-item': true, 'list-group-item-action': true,}" href="#"  @click.prevent="deleteMenucardWarning(item)">
                      <font-awesome-icon icon="fa-solid fa-trash"  />
                      {{ $t('button.delete') }}
                    </a>
                  </ul>
                </div>
              </div>
              <!-- FUNCTION DROPDOWN END -->
            </div>
          </div>
        </div>
       </div>
      <!-- MENUCARDS  END -->
      </template>

      
      <!-- Edit User Details  -->
      <EditUserDetails
      :userData="userData"
      :authFunction="authFunction"
      :apiData="apiData"
      :editUserDetailsModal="editUserDetailsModal" 
      :modalHandling="modalHandling"
      />
      <!-- Edit User Details END -->

       <!-- Upload Logo  -->
       <EditLogo
      :userData="userData"
      :authFunction="authFunction"
      :apiData="apiData"
      :logoUploadModal="logoUploadModal" 
      :modalHandling="modalHandling"
      :config="config" 
      />
      <!-- Upload Logo END -->
      <!-- Add Truck    --> 
      <AddTruck
      :userData="userData"
      :authFunction="authFunction"
      :apiData="apiData"
      :addTruckModal="addTruckModal" 
      :modalHandling="modalHandling"
      :config="config"
      :getTrucks="getTrucks"
      :editData="editData" 
      />
      <!--  Add Truck  END -->
        <!-- Add Location    --> 
      <AddLocation
      :userData="userData"
      :authFunction="authFunction"
      :apiData="apiData"
      :addLocationModal="addLocationModal" 
      :modalHandling="modalHandling"
      :config="config"
      :getLocations="getLocations"
      :editDataLocation="editDataLocation" 
      />
      <!--  Add Location  END -->
      <!-- Add Menu Card   --> 
      <MenuCard
      :userData="userData"
      :authFunction="authFunction"
      :apiData="apiData"
      :menuCardModal="menuCardModal" 
      :modalHandling="modalHandling"
      :config="config"
      :getMenucards="getMenucards"
      :editDataMenucard="editDataMenucard"
      :trucks="trucks" 
      />
      <!--  Add Menu Card END -->
      <!-- Add Menu Card Item -->
      <MenuCardItem
      :userData="userData"
      :apiData="apiData"
      :menuCardItemModal="menuCardItemModal" 
      :modalHandling="modalHandling"
      :config="config"
      :getMenucards="getMenucards"
      :editDataMenucardItem="editDataMenucardItem"
      :menucardItemPreset="menucardItemPreset"
      />
      <!-- Add Menu Card Item END -->
      <!-- Delete Warning -->
      <DeleteWarning
      :modalHandling="modalHandling"
      :DeleteWarningModal="DeleteWarningModal"
      :deleteItem = "deleteItem"
      :action = "deleteAction"
      :deleteWarningContent = "deleteWarningContent"
      />
       <!-- Delete Warning END -->

  </div>  



</template>
<script>
  
  import EditUserDetails from "@/components/EditUserDetails.vue"
  import EditLogo from "@/components/EditLogo.vue"
  import AddTruck from "@/components/AddTruck.vue"
  import AddLocation from "@/components/AddLocation.vue"
  import MenuCard from "@/components/MenucardModal.vue"
  import MenuCardItem from "@/components/MenucardItemModal.vue"
  import DeleteWarning from "@/components/DeleteWarningModal.vue"
  export default {
    name: '${COMPONENT_NAME}',
    components: {
      EditUserDetails,
      EditLogo,
      AddTruck,
      MenuCard,
      MenuCardItem,
      DeleteWarning,
      AddLocation
    },
    //---------------------------------------------------
    //
    //  Properties
    //
    //---------------------------------------------------
    props: {
      config:{required: true},
      userData:{required: true},
      apiData:{type: Function},
      authFunction:{type: Function},

    },
    //---------------------------------------------------
    //
    //  Data model
    //
    //---------------------------------------------------
    data() {
      return {
        deleteWarningContent:{},
        deleteItem:null,
        editData:null,
        editDataMenucardItem:null,
        editDataMenucard:null,
        editDataLocation:null,
        addTruckModal:false,
        addLocationModal:false,
        menuCardModal:false,
        menuCardItemModal:false,
        editUserDetailsModal:false,
        DeleteWarningModal:false,
        logoUploadModal:false,
        trucks:[],
        trucksGrouped:[],
        locations:[],
        locationsGrouped:[],
        menuCards:[],
        menucardItemPreset:{
          card:'',
          categorie:''
        },
      };
    },
    //---------------------------------------------------
    //
    //  Computed Properties
    //
    //---------------------------------------------------
    computed: {},
    //---------------------------------------------------
    //
    //  Watch Properties
    //
    //---------------------------------------------------
    watch: {
      addTruckModal(){
        if(this.addTruckModal === false){
          //this.getTrucks();

        }
      }
    },
    //---------------------------------------------------
    //
    //  Filter Properties
    //
    //---------------------------------------------------
    // filters: {},
    //---------------------------------------------------
    //
    //  Validation Properties
    //
    //---------------------------------------------------
    // validations: {},
    //---------------------------------------------------
    //
    //  Vue Lifecycle
    //
    //---------------------------------------------------
    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // render(h) { return h(); },
    async mounted() {
      this.getTrucks();
      this.getMenucards();
      this.getLocations();
      if(this.$route.params.function === 'add-location'){
        this.addLocationModal =true;
      }
      if(this.$route.params.function === 'add-truck'){
        this.addTruckModal =true;
      }
    },
    // beforeUpdate() {},
    // updated() {},
    // beforeDestroy() {},
    // destroyed() {},
    //---------------------------------------------------
    //
    //  Methods
    //
    //---------------------------------------------------
    methods: {
      /* ##################### TRUCKS ##################### */
       /* ******** GET TRUCKS ******** */
       async getTrucks(){
        let request = {
          selectorTable:'owner',
          selectorValue: this.userData.uuid
        };
        let response;
        response = await this.apiData('ProfileView.vue getTrucks()','post','get-trucks', request);
        this.trucks = response;
        this.trucksGrouped = [];
        this.groupArrays(response, this.trucksGrouped, 2);
      },
      /* ******** ADD TRUCK ******** */
      addTruck(){
        this.editData = null,
        this.addTruckModal = true;
      },
      /* ******** EDIT TRUCK ******** */
      async editTruck(item,x){
        console.log(x); //Unused Parameter
        let request = {
          selectorTable:'id',
          selectorValue: item.id
        };    
        let query =  await this.apiData('ProfileView.vue editTrucks()','post','get-trucks', request);
        this.editData = query[0];
        if(query[0].images.length > 0){
          let imageId = [];
            for(let i = 0; i < item.images.length; i++){
              imageId.push(this.editData.images[i].id)
            }
          this.editData.images = imageId;
          this.editData.defaultImg = this.editData.default_img.id;
        }
        this.addTruckModal = true;      
      },
      /* ******** DELETE TRUCK ******** */
      deleteTruckWarning(item){
        this.deleteWarningContent = {
          type:'truck',
          title:'Truck löschen',
          text:'Möchten Sie den Truck «'+item.name+'» wirklich löschen? Diese Aktion kann nicht wiederrufen werden.'
        };
        this.deleteItem = item;
        this.DeleteWarningModal = true;
      },
      async deleteTruck(item){
        this.DeleteWarningModal = false;
        let query =  await this.apiData('ProfileView.vue deleteTruck()','post','delete-truck', item);
        this.getTrucks();
        console.log(query); //Unused Variable
        this.$toast.open({
          message: this.$t('toast.deleteContent'),
          type: 'success',
          duration:3000,
        });
      },
      /* ##################### TRUCKS END ##################### */
      /* ##################### MENUCARD ##################### */
      /* ******** GET MENUCARDS ******** */
      async getMenucards(){
        let request = {
          selectorTable:'owner',
          selectorValue: this.userData.uuid
        };
        let response;
        response = await this.apiData('ProfileView.vue getMenucards()','post','get-menucards', request);
        this.menuCards = response;
        
      },
      /* ******** ADD MENUCARD ******** */
      addMenuCard(){
        this.editDataMenucard = null,
        this.menuCardModal = true;
      },
      /* ******** EDIT MENUCARD ******** */
      async editMenucard(item){
        let request = {
          selectorTable: 'id',
          selectorValue: item.id
          };
        let query = await this.apiData('ProfileView.vue editMenucard()','post','get-menucards', request);
        this.editDataMenucard = query[0];
        this.menuCardModal = true;    
      },
      /* ******** DELETE MENUCARD ******** */
      deleteMenucardWarning(item){
        this.deleteWarningContent = {
          type:'menucard',
          title:'Speisekarte löschen',
          text:'Möchten die Speisekarte «'+item.name+'» wirklich löschen? Diese Aktion kann nicht wiederrufen werden.'
        };
        this.deleteItem = item;
        this.DeleteWarningModal = true;
      },   
      async deleteMenucard(item){
        this.DeleteWarningModal = false;
        let request = {id: item.id};
        let query =  await this.apiData('ProfileView.vue deleteMenucard()','post','delete-menucard', request);
        console.log(query); //Unused Variable
        this.getMenucards();
        this.$toast.open({
          message: this.$t('toast.deleteContent'),
          type: 'success',
          duration:3000,
        });
      },
      /* ##################### MENUCARD END ##################### */
      /* ##################### MENUCARD ITEMS ##################### */
      /* ******** ADD MENUCARD ITEM ******** */
      addMenuCardItem(card, categorie){
        this.editDataMenucardItem = null,
        this.menuCardItemModal = true;
        this.menucardItemPreset.card = card;
        this.menucardItemPreset.categorie = categorie;
      },
      /* ******** EDIT MENUCARD ITEM ******** */
      async editMenucardItem(item){
        let request= {
          id:item.id
          };
        //this.editDataMenucardItem = item;
        let query =  await this.apiData('ProfileView.vue editMenucardItem()','post','get-menucard-item', request);
        this.editDataMenucardItem = query[0];
        this.menuCardItemModal = true;
      },
      /* ******** DELETE MENUCARD ITEM ******** */
      deleteMenucardItemWarning(item){
        this.deleteWarningContent = {
          type:'menucardItem',
          title:'Eintrag löschen',
          text:'Möchten Sie den Eintrag «'+item.title+'» wirklich löschen? Diese Aktion kann nicht wiederrufen werden.'
        };
        this.deleteItem = item;
        this.DeleteWarningModal = true;
      },
      async deleteMenucardItem(item){
        this.DeleteWarningModal = false;
        let request = {id: item.id};
        let query =  await this.apiData('ProfileView.vue deleteMenucardItem()','post','delete-menucard-item', request);
        console.log(query); //Unused Variable
        this.getMenucards();
        this.$toast.open({
          message: this.$t('toast.deleteContent'),
          type: 'success',
          duration:3000,
        });
      },  
      /* ##################### MENUCARD ITEMS END ##################### */
      /* ##################### LOCATIONS ##################### */
      /* ******** GET LOCATIONS ******** */
      async getLocations(){
        let request = {
          selectorTable:'owner',
          selectorValue: this.userData.uuid
        };
        let response;
        response = await this.apiData('ProfileView.vue getLocations()','post','get-locations', request);
        this.locations = response;
        this.locationsGrouped = [];
        this.groupArrays(response, this.locationsGrouped, 2);
      },
      /* ******** ADD LOCATION ******** */
      addLocation(){
        this.editDataLocation = null,
        this.addLocationModal = true;
      },
      /* ******** EDIT LOCATIONS ******** */
      async editLocation(item,x){
        console.log(x); //Unused Parameter
        let request = {
          selectorTable:'id',
          selectorValue: item.id
        };    
        let query =  await this.apiData('ProfileView.vue editLocations()','post','get-locations', request);
        this.editDataLocation = query[0];
          if(query[0].images.length > 0){
            let imageId = [];
            for(let i = 0; i < item.images.length; i++){
              imageId.push(this.editDataLocation.images[i].id)
            }
          this.editDataLocation.images = imageId;
          this.editDataLocation.defaultImg = this.editDataLocation.default_img.id;
        }
        

        this.addLocationModal = true; 
        console.log('editDataLocation');
        console.log(this.editDataLocation);     
      },
      /* ******** DELETE LOCATION ******** */
      deleteLocationWarning(item){
        this.deleteWarningContent = {
          type:'location',
          title:'Standort löschen',
          text:'Möchten Sie den Standort «'+item.name+'» wirklich löschen? Diese Aktion kann nicht wiederrufen werden.'
        };
        this.deleteItem = item;
        this.DeleteWarningModal = true;
      },
      async deleteLocation(item){
        this.DeleteWarningModal = false;
        let query =  await this.apiData('ProfileView.vue deleteLocation()','post','delete-location', item);
        this.getLocations();
        console.log(query); //Unused Variable
        this.$toast.open({
          message: 'Ihr Eintrag wurde gelöscht',
          type: 'success',
          duration:3000,
        });
      },
      
      /* ##################### LOCATIONS END ##################### */
      /* ##################### GENERAL FUNCTIONS ##################### */ 
      deleteAction(item, type){
        if(type === 'menucardItem'){
          this.deleteMenucardItem(item);
        }
        if(type === 'truck'){
          this.deleteTruck(item);
        }
        if(type === 'menucard'){
          this.deleteMenucard(item);
        }
        if(type === 'location'){
          this.deleteLocation(item);
        }
        this.deleteItem = null;
        this.deleteWarningContent = {};
      },
      groupArrays(originalArray, groupedArray, groupCount) {
        for (let i = 0; i < originalArray.length; i += groupCount) {
          groupedArray.push(originalArray.slice(i, i + groupCount));
        }
      },
      modalHandling(modal){
        if(this[modal] === false){
          this[modal] = true;
        }
        if(this[modal] === true){
          this[modal] = false;
        }
      }
      /* ##################### GENERAL FUNCTIONS END ##################### */ 
    },
  };
</script>
<style lang="scss">
  .truckCard{
    overflow: hidden;
  }
  .truckCardImg{
    
    margin-bottom:0 !important;
    background-position: center center;
    background-size: cover;
  }
  .region:after{
    content: ", ";
  }
  .region:last-child:after{
    content: "";
    
  }
</style>