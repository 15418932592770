
export default {
    card:{ 
        title:{
            filter:'Filter',
            locations:'Standorte',
            invoices:'Rechnungen',
            offerts:'Angebote',
            offertLocation:'Standort Anbieten',
            bookings:'Buchungen',
            auctions:'Auktionen',
            trucks:'Trucks',
            notifications:'Benachrichtigungen',
            completeRegistration:'Registrierung abschliessen',
            emailVerification:'Code eingeben:',
            provider:'Anbieter',
            locationDetails:'Standort Details',
            timeTable:'Zeitplan',
            login:'Anmelden',
            yourTrucks:'Deine Trucks',
            yourCards:'Deine Speisekarten',
            yourLocations:'Deine Standorte',
            register:'Registrieren',
            resetPassword:'Passwort zurücksetzen',
            newPassword:'Neues Passwort eingeben',
            emailSettings:'E-Mail Benachrichtigungen',
            accountSettings:'Konto Einstellungen',
            generalSettings:'Allgemeine Einstellungen'
        },
        body:{
            noLocations:'Aktuell keine gebuchte Standorte verfügbar.',
            noBookings:'Derzeit keine Buchungen.',
            noNotifications:'Derzeit keine Benachrichtigungen.',
            emailVerification:'Um Deine E-Mail-Adresse zu verifizieren, gib bitte den 4-stelligen Code ein, den wir Dir per E-Mail zugeschickt haben.'
        }
    },
    tab:{
        all:'Alle',
        open:'Offen',
        booked:'Gebucht',
        confirmed:'Bestätigt',
        bid:'Geboten',
        list:'Liste',
        map:'Karte'
    },
    error:{
        unexpected:'Ein unerwarteter Fehler ist aufgetreten',
        emailVerification:'Deine Eingabe ist falsch!'
    },
    warning:{
        blockedAccount:'Dein Account wurde gesperrt! Kontaktiere uns um das Problem zu lösen.'
    },
    toast:{
       addLocation:'Dein Standort wurde erfasst',
       editLocation:'Dein Standort wurde aktualisiert',
       addTruck:'Dein Truck wurde erfasst',
       editTruck:'Dein Truck wurde aktualisiert',
       error:'Es ist ein Fehler aufgetreten',
       registrationComplete:'Registrierung erfolgreich abgeschlossen',
       autoLocation:'Es wurde automatisch ein Standort erfasst',
       autoLocationFail:'Es konnte leider kein Standort automatisch angelegt werden',
       uploadLogo:'Dein Logo wurde erfolgreich Hochgeladen',
       editAddress: 'Deine Daten wurden erfolgreich Aktualisiert',
       loginSuccess:'Du hast dich erfolgreich angemeldet',
       loginFail:'Anmelden fehlgeschlagen',
       logout:'Erfolgreich abgemeldet',
       addItem:'Dein Eintrag wurde hinzugefügt',
       updateItem:'Dein Eintrag wurde aktualisiert',
       addMenucard:'Dein Speisekarte wurde hinzugefügt',
       editMenucard:'Dein Speisekarte wurde aktualisiert',
       registrationSuccess:'Registrierung erfolgreich',
       registrationFail:'Registrierung fehlerhaft',
       bookingSuccess:'Gratuliere: Du hast den Standort gebucht',
       bookingFail:'Hoppla: Da war leider jemand schneller als Du',
       bidSuccess:'Gratuliere: Du hast das aktuelle Höchstgebot auf den Standort',
       bidFail:'Hoppla: Da war leider jemand schneller als Du',
       auctionEnded:'Diese Auktion ist leider abgelaufen',
       bookingConfirm:'Die Buchung wurde bestätigt',
       bookingCancel:'Die Buchung wurde abgelehnt',
       addOffert:'Dein Angebot wurde erfasst',
       bookingDelete:'Dein Eintrag wurde gelöscht',
       bookingsDelete:'Deine Einträge wurden gelöscht',
       deleteContent:'Dein Eintrag wurde gelöscht',
       emailVerificationPasswordReset:'Du hast eine E-Mail mit einem Bestätigungscode erhalten',
       resetReady:'Du kannst jetzt Dein Passwort zurücksetzen',
       resetSuccess:'Du hast Dein Passwort erfolgreich zurückgesetzt',
       changePassword:'Dein Passwort wurde aktualisiert, du musst Dich erneut anmelden',
       changeName:'Deine Kontoname wurde geändert',
       emailSettings:'Deine E-Mail Einstellungen wurden aktualisiert'
    },
    button: {
       contact:'Kontakt',
       cancel:'Abbrechen',
       update:'Aktualisieren',
       save:'Speichern',
       delete:'Löschen',
       login:'Anmelden',
       register:'Registrieren',
       forgotPassword:'Passwort vergessen?',
       readAll:'Alle als gelesen markieren',
       profile:'Profil',
       settings:'Einstellungen',
       logout:'Abmelden',
       previous:'Zurück',
       next:'Vorwärts',
       bookNow:'Jetzt buchen',
       bid:'bieten',
       addTruck:'Truck erfassen',
       addLocation:'Standort erfassen',
       addMenucard:'Speisekarte erfassen',
       addMenucardItem:'Eintrag erfassen',
       sendMessage:'Nachricht senden',  
       close:'Schliessen',
       placeOffert:'Angebot aufgeben',
       placeAuction:'Auktion starten',
       bookingConfirm:'Buchung bestätigen',
       bookingCancel:'Buchung ablehnen',
       viewTruck:'Truck ansehen',
       viewLocation:'Standort ansehen',
       deleteSingle:'Einzeln löschen',
       deleteGroup:'Alle löschen',
       addLogo:'Logo hochladen',
       edit:'Bearbeiten',
       view:'Ansehen',
       resetPassword:'Passwort zurücksetzen',
      
       change:'Ändern'
       
        


    },
    teaser:{
      title:{
        truck:'Du hast noch keinen Truck erfasst',
        location:'Du hast noch keinen Standort erfasst',
        menucard:'Du hast noch keine Speisekarte erfasst'
      },
      content:{
        truckBooking:'Erfasse mindestens einen Truck um Standorte zu buchen.',
        location:'Erfasse mindestens einen Standort um Standorte anzubieten.',
        menucard:'Erfasse jetzt eine Speisekarte und weise sie einem Truck zu.',
        menucardNoTruck:'Du kannst erst Speisekarten erfassen wenn du mindestens einen Truck erfasst hast.'
      }
    },
    nav: {
      footer:{
        agb:'AGB',
        dsgvo:'DSGVO',
        inprint:'Impressum',
        contact: 'Kontakt'
      },
      private:{
        dashboard:'Dashboard',
        profile:'Profil',
        offert:'Standort anbieten',
        booking:'Standort buchen',
        invoice:'Rechnnungen',
        sandbox:'Sandbox'
      },
      public:{
        home:'Home',
        foodtrucks:'Foodtrucks',
        locations:'Standorte',
        locationProviders:'Standortanbieter',
        foodtruckProviders:'Foodtruckbetreiber'
      },
      login:{
        login:'Anmelden',
        register:'Registrieren'
      },
      color:{
        dark:'Dunkel',
        light:'hell',
        auto:'Auto'
      }
    },
    footer:{
        copyright:'© 2024 Foodtruck Locator'
    },
    form:{
       label:{
        from:'Von',
        to:'Bis',
        search:'Suchen',
        locationName:'Standort Name',
        truckName:'Truck Name',
        street:'Strasse',
        zip:'PLZ',
        city:'Ort',
        country:'Land',
        long:'Längengrad',
        lat:'Breitengrad',
        electricSmall:'220V Stromanschluss',
        electricLarge:'400V Stromanschluss',
        electricSmallShort:'220V Strom',
        electricLargeShort:'400V Strom',
        description:'Beschreibung',
        length:'Länge (cm)',
        height:'Höhe (cm)',
        width:'Breite (cm)',
        addImage:'Bilder hinzufügen',
        categorie:'Kategorie',
        categories:'Kategorien',
        type:'Typ',
        region:'Region',
        regions:'Regionen',
        company:'Firma',
        website:'Website',
        firstname:'Vorname',
        lastname:'Nachname',
        streetNr:'Strasse Nr.',
        phone:'Telefon',
        locationSearch:'Ort suchen',
        email:'E-Mail',
        password:'Passwort',
        passwordConfirmation:'Passwort wiederholen',
        title:'Titel',
        price:'Preis',
        text:'Text',
        menucardName:'Speisekarte Name',
        location:'Standort',
        locations:'Standorte',
        iAm:'Ich bin ein ...',
        name:'Name',
        trucks:'Trucks',
        truck:'Truck',
        priceModel:'Preismodell',
        maxPrice:'Max. Preis',
        chf:'CHF',
        hideExpired:'abgelaufene ausblenden',
        repead:'Wiederholen',
        repeads:'Wiederholungen',
        startprice:'Startpreis',
        bid:'Geboten',
        bids:'Gebote',
        auctionEnd:'Auktionsende',
        message:'Nachricht',
        address:'Adresse',
        start:'Start',
        end:'Ende',
        startPrice:'Startpreis',
        steps:'Schritte',
        reason:'Begründung',
        mailSettings1:'Neue Angebote in deiner Umgebung',
        mailSettings2:'Du wurdest bei einer Auktion überboten',
        mailSettings3:'Du hast eine Auktion gewonnen',
        mailSettings4:'Deine Auktion ist beendet',
        mailSettings5:'Deine Buchung wurde bestätigt',
        mailSettings6:'Deine Buchung wurde abgelehnt',
        mailSettings7:'Deine Angebot wurde gebucht',
        language:'Sprache',
        theme:'Thema',
        accountName:'Kontoname',
       },
       select:{
        locationProviders:'Standortanbieter',
        foodtruckProviders:'Foodtruckbetreiber',
        foody:'Foody'
       }
    },
    alert:{
      booked:'Du hast dieses Angebot gebucht, die Bestätigung des Anbieters ist noch ausstehend.',
      bookedExpired:'Dieses Angebot ist leider ohne Bestätigung abgelaufen.',
      leadBid:'Du bist aktuell der Höchstbietende.',
      overBid:'Du wurdest überboten.'
    },
   
    modal:{
      title:{
        addLocation:'Standort hinzufügen',
        editLocation:'Standort bearbeiten',
        addTruck:'Truck hinzufügen',
        editTruck:'Truck bearbeiten',
        blockedAccount:'Dein Account wurde gesperrt!',
        editAddress:'Adresse bearbeiten',
        emailVerification:'E-Mail Adresse bestätigen',
        menucardItemAdd:'Eintrag erfassen',
        menucardItemUpdate:'Eintrag aktualisieren',
        addMenucard:'Speisekarte hinzufügen',
        updateMenucard:'Speisekarte bearbeiten',
        uploadLogo:'Logo Hochladen',
        bookingCancel:'Buchung ablehnen',
        deleteOffert:'Angebot löschen',
        changeEmail:'E-Mail Adresse ändern',
        changeName:'Kontoname ändern',
        changePassword:'Passwort ändern'
      },
      text:{
        deleteBooking:'Möchtest Du dieses Angebot wirklich löschen? Diese Aktion kann nicht wiederrufen werden.',
        deleteGroup:'Dieses Angebot scheint teil einer Gruppe zu sein. Möchtest Du alle offenen Angebote dieser Gruppe löschen? Diese Aktion kann nicht wiederrufen werden.',
        changeEmail:'Nach dem Ändern deiner E-Mail Adresse musst du dich neu anmelden.',
        changePassword:'Nach dem Ändern de Passworts musst du dich neu anmelden.'
      }
    },
    tooltip:{
     auction:'Um eine Auktion zu starten, muss das Angebot mindestens 72 Stunden vor dem gewünschten Beginn veröffentlicht werden.',
    },
    settings:{
      
    },
    
}





