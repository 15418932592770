<template>
    <div v-if="loading === false">
      <!-- LOCATION HEADER -->
      <div class="row">
        <div class="col-md-1">
            <button class="btn btn-outline-secondary h-100 backBtn" style="width:100%" @click="$router.go(-1)"> <font-awesome-icon icon="fa-solid fa-chevron-left"  /></button>
          </div>
          <div class="col-md-11" >
            <div class="card text-bg-card-header">
              <div class="card-body">
                <div class="row d-flex align-items-center">
                  <div class="col-md-1 headerLogo"  :style="{ backgroundImage:'url('+config.storageURL+owner.owner+'/img/'+owner.logo+')', 'height':'40px'}">
                  </div>
                  <div class="col-md-11" style="margin-bottom:0 !important;">
                    <h4>{{owner.company}} | {{location.name}}</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- LOCATION HEADER END -->
        <div class="row  d-flex">
          <!-- MAP LOCATION -->
          <div class="col-md-12" >
              <div class="card h-100">
                <div class="card-body d-flex truckCardImg flex-column">
                <MapboxMap 
                  access-token="pk.eyJ1Ijoic2JlbmN6ZSIsImEiOiJjbTFiYXVrY3YwbWY0MmpzYm0wamc0bDhiIn0.R_6w0DsjbSGASOg2Snz8Ng"
                  style="min-height: 320px"
                  class="h-100"
                  :center="[location.long,location.lat]"
                  :map-style="mapboxTheme"
                  :zoom="16"
                  :scrollZoom="false"
                >
                <MapboxNavigationControl position="top-left" :showCompass="false" />
                <MapboxMarker :lng-lat="[location.long,location.lat]" color="#f9b233" />
                </MapboxMap>
                </div>
              </div>
              </div>
              <!-- MAP LOCATION END -->
          <!-- CARD DETAILS -->
          <div class="col-md-7">
              <div class="card h-100">
                <div class="card-header">
                  <h5>{{ $t('card.title.provider') }}</h5>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-sm-3">
                      <p class="mb-0">{{ $t('form.label.company') }}</p>
                    </div>
                    <div class="col-sm-9">
                      <p class="text-muted mb-0">{{ owner.company }}</p>
                    </div>
                  </div>
                  <hr>
                  <div class="row">
                    <div class="col-sm-3">
                      <p class="mb-0">{{ $t('form.label.street') }}</p>
                    </div>
                    <div class="col-sm-9">
                      <p class="text-muted mb-0">{{ owner.street }}</p>
                    </div>
                  </div>
                  <hr>
                  <div class="row">
                    <div class="col-sm-3">
                      <p class="mb-0">{{ $t('form.label.address') }}</p>
                    </div>
                    <div class="col-sm-9">
                      <p class="text-muted mb-0">{{ owner.zip }} {{ owner.city }}</p>
                    </div>
                  </div>
                  <hr>
                  <div class="row">
                    <div class="col-sm-3">
                      <p class="mb-0">{{ $t('form.label.country') }}</p>
                    </div>
                    <div class="col-sm-9">
                      <p class="text-muted mb-0">{{ owner.country }}</p>
                    </div>
                  </div>
                  <hr>
                  <div class="row">
                    <div class="col-sm-3">
                      <p class="mb-0">{{ $t('form.label.phone') }}</p>
                    </div>
                    <div class="col-sm-9">
                      <p class="text-muted mb-0">{{ owner.phone }}</p>
                    </div>
                  </div>
                  <hr v-if="owner.url">
                  <div class="row" v-if="owner.url">
                    <div class="col-sm-3">
                      <p class="mb-0">{{ $t('form.label.website') }}</p>
                    </div>
                    <div class="col-sm-9">
                      <p class="text-muted mb-0">{{ owner.url }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- CARD DETAILS END -->
             <!-- GALLERY -->
            <div class="col-md-5" v-if="location.images.length > 0">
              <div class="card h-100" style="min-height:400px">
                
                <div class="card-body d-flex flex-column">
                  <!-- BILD --> 
                  <div class="flex-grow-1 galleryImg" :style="{ backgroundImage:'url('+config.storageURL+owner.owner+'/img/'+gallery.img+')'}">
                   
                  </div>
                  <!-- BILD END --> 
                  <!-- TMB CONTAINER --> 
                  <div class="d-flex" >
                    <!-- SPLIDE BUTTON ZURÜCK --> 
                    <div  class="bg-secondary text-white d-flex justify-content-center align-items-center" style="width:30px; margin-right:1rem; border-radius: 5px 0 0 5px; cursor: pointer;" @click="splicerNav('<')">
                      <font-awesome-icon icon="fa-solid fa-chevron-left"/>
                    </div>
                    <!-- SPLIDE BUTTON ZURÜCK ENDE --> 
                    <!-- SPLIDE CONTAINER --> 
                    <div class="flex-grow-1 d-flex flex-column" style="min-width: 0;">          
                      <splide :options="splideOptions" ref="splicer" style="width: 100%; max-width: 100%;">
                        <splide-slide v-for="(item, x) in gallery.tmb" :key='x'>
                          <div :class="{'galleryTmbOuter':true, 'active': item.active}">
                            <div :class="{'galleryTmb':true, 'active': item.active}" :style="{ backgroundImage:'url('+config.storageURL+owner.owner+'/img/'+item.name+')'}" @click="tmbNav(item, x)"></div>
                          </div>
                        </splide-slide>
                      </splide>
                    </div> 
                    <!-- SPLIDE CONTAINER END --> 
                    <!-- SPLIDE BUTTON VOR -->  
                    <div class="bg-secondary text-white d-flex justify-content-center align-items-center" style="width:30px;margin-left:1rem; border-radius:0 5px 5px 0; cursor: pointer;" @click="splicerNav('>')">
                      <font-awesome-icon icon="fa-solid fa-chevron-right"/>
                    </div> 
                    <!-- SPLIDE BUTTON VOR END --> 
                  </div>
                  <!-- TMB CONTAINER END --> 
                  
                  
                </div>
              </div>
            </div>
            <!-- IMAGE PLACEHOLDER -->
            <div class="col-md-5" v-else>
              <div class="card h-100">
                <div class="card-body d-flex truckCardImg flex-column">
                  <div  class="d-flex truckCardImg flex-column h-100" :style="{ backgroundImage: `url(${require('@/assets/default_location.png')})` }">

                  </div>
                </div>
              </div>
            </div>
            <!-- IMAGE PLACEHOLDER END -->
            <!-- GALLERY END -->
            <!-- LOCATION DETAILS -->
            <div class="col-md-7" >
              <div class="card h-100">
                <div class="card-header">
                  <h5>{{ $t('card.title.locationDetails') }}</h5>
                </div>
                <div class="card-body d-flex truckCardImg flex-column">
                  <div class="row">
                    <div class="col-sm-3">
                      <p class="mb-0">{{ $t('form.label.name') }}</p>
                    </div>
                    <div class="col-sm-9">
                      <p class="text-muted mb-0">{{ location.name }}</p>
                    </div>
                  </div>
                  <hr>
                  <div class="row">
                    <div class="col-sm-3">
                      <p class="mb-0">{{ $t('form.label.street') }}</p>
                    </div>
                    <div class="col-sm-9">
                      <p class="text-muted mb-0">{{ location.street }}</p>
                    </div>
                  </div>
                  <hr>
                  <div class="row">
                    <div class="col-sm-3">
                      <p class="mb-0">{{ $t('form.label.address') }}</p>
                    </div>
                    <div class="col-sm-9">
                      <p class="text-muted mb-0">{{ location.zip }} {{ location.city }}</p>
                    </div>
                  </div>
                  <hr>
                  <div class="row">
                    <div class="col-sm-3">
                      <p class="mb-0">{{ $t('form.label.region') }}</p>
                    </div>
                    <div class="col-sm-9">
                      <p class="text-muted mb-0">{{ location.region.name }}</p>
                    </div>
                  </div>
                  <hr>
                  <div class="row">
                    <div class="col-sm-3">
                      <p class="mb-0">{{ $t('form.label.type') }}</p>
                    </div>
                    <div class="col-sm-9">
                      <p class="text-muted mb-0">{{ location.type.name }}</p>
                    </div>
                  </div>
                  <hr>
                  <div class="row">
                    <div class="col-sm-3">
                      <p class="mb-0">{{ $t('form.label.electricSmallShort') }}</p>
                    </div>
                    <div class="col-sm-9">
                      <p class="text-muted mb-0"><template v-if="location.electric_small === 0">Nein</template><template v-else>Ja</template></p>
                    </div>
                  </div>
                  <hr>
                  <div class="row">
                    <div class="col-sm-3">
                      <p class="mb-0">{{ $t('form.label.electricLargeShort') }}</p>
                    </div>
                    <div class="col-sm-9">
                      <p class="text-muted mb-0"><template v-if="location.electric_large === 0">Nein</template><template v-else>Ja</template></p>
                    </div>
                  </div>
                  <hr>
                  <div class="row">
                    <div class="col-sm-3">
                      <p class="mb-0">{{ $t('form.label.description') }}</p>
                    </div>
                    <div class="col-sm-9">
                      <p class="text-muted mb-0">{{ location.description }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- LOCATION DETAILS END -->
            <!-- TIMETABLE -->
            <div class="col-md-5">
              <div class="card h-100">
                <div class="card-header">
                  <h5>{{ $t('card.title.timeTable') }}</h5>
                </div>
                <div class="card-body d-flex truckCardImg flex-column">
                  <ol class="list-group list-group-flush listTimeTable" v-if="timeTable.length > 0">
                    <li class="list-group-item d-flex justify-content-between align-items-start" v-for="(item, x) in timeTable" :key="x" >
                      <div class="ms-2 me-auto" >
                        <div class="fw-bold" >{{formatDate(item.start)}} - {{formatDate(item.end)}}</div>
                        {{ item.truck.name }}
                      </div>
                      <button type="button" class="btn btn-primary " @click="$router.push('/truck/'+item.truck.id)"><font-awesome-icon icon="fa-solid fa-truck-front" style="margin-right:0" /></button>

                    </li>
                  </ol>
                  <p v-else>{{ $t('card.body.noBookings') }}</p>
                </div>
              </div>
              </div>
              <!-- TIMETABLE END -->
        </div>
    </div>
</template>
<script>
  import { Splide, SplideSlide } from '@splidejs/vue-splide';
  import '@splidejs/splide/dist/css/themes/splide-default.min.css';
  import { MapboxMap, MapboxMarker, MapboxNavigationControl } from '@studiometa/vue-mapbox-gl'
  import dayjs from 'dayjs';
  export default {
    name: '${COMPONENT_NAME}',
    components: {
      Splide,
      SplideSlide,
      MapboxMap,
      MapboxMarker,
      MapboxNavigationControl
    },
    //---------------------------------------------------
    //
    //  Properties
    //
    //---------------------------------------------------
    props: {
      apiData:{type:Function},
      config:{requireed: true}
    },
    //---------------------------------------------------
    //
    //  Data model
    //
    //---------------------------------------------------
    data() {
      return {
        timeTable:[],
        splideOptions:{
          
          gap: '1rem',
          perPage: 1,
          arrows:false,
          width:'100%',
          fixedWidth:'120px',
          fixedHeight:'80px',
          pagination:false,

        },
        loading:true,
        owner:{},
        location:[],
       
        gallery:{
          img:'',
          tmb:[]
        },
      };
    },
    //---------------------------------------------------
    //
    //  Computed Properties
    //
    //---------------------------------------------------
    computed: {
      mapboxTheme(){
        let value
        if(localStorage.getItem('theme') === 'light'){
          value ='mapbox://styles/mapbox/light-v11'
        }
        if(localStorage.getItem('theme') === 'dark'){
          value ='mapbox://styles/mapbox/dark-v11'
        }
        return value;
      },
    },
    //---------------------------------------------------
    //
    //  Watch Properties
    //
    //---------------------------------------------------
    watch: {},
    //---------------------------------------------------
    //
    //  Filter Properties
    //
    //---------------------------------------------------
    // filters: {},
    //---------------------------------------------------
    //
    //  Validation Properties
    //
    //---------------------------------------------------
    // validations: {},
    //---------------------------------------------------
    //
    //  Vue Lifecycle
    //
    //---------------------------------------------------
    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // render(h) { return h(); },
    async mounted() {
      this.getLocation();
      this.getTimeTable();
    },
    // beforeUpdate() {},
    // updated() {},
    // beforeDestroy() {},
    // destroyed() {},
    //---------------------------------------------------
    //
    //  Methods
    //
    //---------------------------------------------------
    methods: {
      formatDate(dateString) {
        return dayjs.utc(dateString).local().format('DD.MM.YY HH:mm');
      },
      async getLocation(){
        this.loading = true;
        let requestLocation = {
          selectorTable:'id',
          selectorValue: this.$route.params.id
        };
        let responseLocation;
        responseLocation = await this.apiData('LocationView.vue getLocation()','post','get-locations', requestLocation);
        this.location = responseLocation[0];

        let requestUser = {
          selectorTable:'owner',
          selectorValue: this.location.owner
        };
        let responseOwner;
        responseOwner = await this.apiData('LocationView.vue getLocation()','post','get-user-details', requestUser);
        this.owner = responseOwner[0];

         //BUILD GALLERY OBJECT
         if(responseLocation[0].images.length > 0){
          for(let i = 0; i < this.location.images.length; i++ ){
            this.gallery.tmb.push({name:this.location.images[i].name,active:false })
          }
          this.gallery.img = this.location.images[0].name;
          this.gallery.tmb[0].active = true;
        }
        this.loading = false;
      },
      async getTimeTable(){
         let requestTimetable = {
          selectorTable:'location',
          selectorValue: this.$route.params.id
        };
        let responseTimetable;
        responseTimetable = await this.apiData('LocationView.vue getTimetable()','post','get-confirmed-future-locations', requestTimetable);
        this.timeTable = responseTimetable;
        
      },
      splicerNav(direction){
          this.$refs.splicer.go( direction );  
          

      },
      tmbNav(item, x){
        this.gallery.img = item.name;
        for(let i = 0; i < this.gallery.tmb.length; i++ ){
            this.gallery.tmb[i].active=false;
           
          }
          this.gallery.tmb[x].active = true;
      },
    },
  };
</script>
<style lang="scss">
.galleryTmb{
  cursor: pointer;
  height:76px;
  background-position: center center;
  background-size:cover;
  background-repeat: no-repeat;
  border:2px solid #fff;
  box-sizing: border-box;
}

.galleryTmbOuter{
  border:2px solid #000;
  box-sizing: border-box;
}
.galleryTmbOuter.active{
  border:2px solid #f9b233;
  box-sizing: border-box;
}
.galleryImg{
  background-position: center center;
  background-size:cover;
  background-repeat: no-repeat;
  margin-bottom: 1rem;
}
.listTimeTable{
  border-bottom:1px solid var(--bs-border-color);
}
.listTimeTable li{
  padding-left:0; 
  padding-right:0;
 
}

</style>