<template>
  <div>

    <div class="modal" id="menucardItemModal" data-bs-backdrop="static" >
      <div class="modal-dialog modal-dialog-centered ">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title"  v-if="editDataMenucardItem">{{ $t('modal.title.menucardItemEdit') }}</h5>
            <h5 class="modal-title" v-else>{{ $t('modal.title.menucardItemAdd') }}</h5>
          </div>
          <div class="modal-body">
            <div class="row">
              
              <div class="col-md-10">
                <div class="form-floating mb-3">
                  <input type="text" :class="{'form-control': true, 'is-invalid': errors.title}"  id="menucardItemTitleField" v-model="menucardItem.title" placeholder="Name" >
                  <label for="menucardItemTitleField" class="form-label" >{{ $t('form.label.title') }}</label>
                  <div class="invalid-feedback" v-if="errors.title">
                    <p v-for="(item, x) in errors.title" :key='x' >{{ errors.title[x] }}</p>
                  </div>
                </div>
              </div>
              
              <div class="col-md-2">
                <div class="form-floating mb-3">
                  <input type="text" :class="{'form-control': true, 'is-invalid': errors.price}"  id="menucardItemPriceField" v-model="menucardItem.price" placeholder="Preis" >
                  <label for="menucardItemPriceField" class="form-label" >{{ $t('form.label.price') }}</label>
                  <div class="invalid-feedback" v-if="errors.price">
                    <p v-for="(item, x) in errors.price" :key='x' >{{ errors.price[x] }}</p>
                  </div>
                </div>
              </div>

              <div class="col-md-12">
                <div class="form-floating mb-3">
                  <textarea  :class="{'form-control': true, 'is-invalid': errors.text, 'w-100': true}"  id="menucardItemTextField" placeholder="Text" v-model="menucardItem.text"></textarea>
                  <label for="menucardItemTextField" class="form-label" >{{ $t('form.label.text') }}</label>
                  <div class="invalid-feedback" v-if="errors.text">
                    <p v-for="(item, x) in errors.text" :key='x' >{{ errors.text[x] }}</p>
                  </div>
                </div>
              </div>
            

            
            </div>
           
            

          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="modalHandling('menuCardItemModal')">{{ $t('button.cancel') }}</button>
            <button type="button" class="btn btn-primary" @click="updateMenucardItem()" v-if="editDataMenucardItem">{{ $t('button.update') }}</button>
            <button type="button" class="btn btn-primary" @click="addMenucardItem()" v-else>{{ $t('button.save') }}</button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>

import { Modal } from "bootstrap";
export default {
  name: '${COMPONENT_NAME}',
  components: {
    
  },
  //---------------------------------------------------
  //
  //  Properties
  //
  //---------------------------------------------------
  props: {
    config:{required: true},
    userData: {required: true},
    menuCardItemModal: {required: true},
    menucardItemPreset: {required: true},
    apiData: {type: Function},
    modalHandling: {type: Function},
    getMenucards: {type: Function},
    editDataMenucardItem: {required: true},
  },
  //---------------------------------------------------
  //
  //  Data model
  //
  //---------------------------------------------------
  data() {
    return {

      menucardItem:{
        title:'',
        price:'',
        text:'',
        categorie:null,
        parent:null,
        owner:this.userData.uuid,
        
      },
      errors:{},
    };
  },
  //---------------------------------------------------
  //
  //  Computed Properties
  //
  //---------------------------------------------------
  computed: {},
  //---------------------------------------------------
  //
  //  Watch Properties
  //
  //---------------------------------------------------
  watch: {
    
    menuCardItemModal(){

      if(this.menuCardItemModal === true)
      {

        if(this.editDataMenucardItem){
          this.errors = {};
      this.menucardItem =  this.editDataMenucardItem;
     

      
    }else{
      this.errors = {};
      this.menucardItem.parent = this.menucardItemPreset.card;
      this.menucardItem.categorie = this.menucardItemPreset.categorie;
      this.menucardItem.title='';
      this.menucardItem.price='';
      this.menucardItem.text='';
      
    }

        this.uniqueModal.show();
      }
      if(this.menuCardItemModal === false)
      {
        this.uniqueModal.hide();
      }
    }
  },
  //---------------------------------------------------
  //
  //  Filter Properties
  //
  //---------------------------------------------------
  // filters: {},
  //---------------------------------------------------
  //
  //  Validation Properties
  //
  //---------------------------------------------------
  // validations: {},
  //---------------------------------------------------
  //
  //  Vue Lifecycle
  //
  //---------------------------------------------------
  // beforeCreate() {},
  // created() {},
  // beforeMount() {},
  // render(h) { return h(); },
  mounted() {
    this.uniqueModal = new Modal(document.getElementById("menucardItemModal"),{ keyboard: false });
  },
  // beforeUpdate() {},
  // updated() {},
  // beforeDestroy() {},
  // destroyed() {},
  //---------------------------------------------------
  //
  //  Methods
  //
  //---------------------------------------------------
  methods: {
   
    async addMenucardItem(){
      //console.log('Karten ID: '+this.menucardItemPreset.card);
      //console.log('Kategorie ID: '+this.menucardItemPreset.categorie);
      //this.menucardItem.parent = this.menucardItemPreset.card;
      //this.menucardItem.categorie = this.menucardItemPreset.categorie;
      let response;
      response = await this.apiData('MenucardItemModal.vue addMenucardItem()','post','add-menucard-item', this.menucardItem);
      if (response.status === 'failure'){
        this.errors = response.errors;
        this.$toast.open({
          message: this.$t('toast.error'),
          type: 'error',
          duration:3000,
        });
      }else{
        this.modalHandling('menuCardItemModal');
        this.getMenucards();
        this.errors = {};
        this.$toast.open({
          message: this.$t('toast.addItem'),
          type: 'success',
          duration:3000,
        });
        
      }  
    },
    async updateMenucardItem(){
      let response;
      response = await this.apiData('MenucardItemModal.vue updateMenucardItem()','post','update-menucard-item', this.menucardItem);
      if (response.status === 'failure'){
        this.errors = response.errors;
        this.$toast.open({
          message: this.$t('toast.error'),
          type: 'error',
          duration:3000,
        });
      }else{
        this.modalHandling('menuCardItemModal');
        this.getMenucards();
        this.errors = {};
        this.$toast.open({
          message: this.$t('toast.updateItem'),
          type: 'success',
          duration:3000,
        });
        
      }  
    }
  },
};
</script>
<style lang="scss">
</style>