<template>
    <div>
      <div class="modal" id="deleteWarningModalID" data-bs-backdrop="static" >
      <div class="modal-dialog modal-dialog-centered ">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ deleteWarningContent.title }}</h5>
          </div>
          <div class="modal-body">
            
            {{ deleteWarningContent.text }}
            

          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="modalHandling('DeleteWarningModal')">{{$t('button.cancel')}}</button>
            <button type="button" class="btn btn-danger" @click="action(deleteItem, deleteWarningContent.type)">{{$t('button.delete')}}</button>
          </div>
        </div>
      </div>
    </div>
    </div>
</template>
<script>
  import { Modal } from "bootstrap";
  export default {
    name: '${COMPONENT_NAME}',
    components: {
    },
    //---------------------------------------------------
    //
    //  Properties
    //
    //---------------------------------------------------
    props: {
      DeleteWarningModal: {required: true},   
      deleteWarningContent: {required: true},
      deleteItem: {required: true},
      modalHandling: {type: Function},
      action: {type: Function}
    },
    //---------------------------------------------------
    //
    //  Data model
    //
    //---------------------------------------------------
    data() {
      return {};
    },
    //---------------------------------------------------
    //
    //  Computed Properties
    //
    //---------------------------------------------------
    computed: {},
    //---------------------------------------------------
    //
    //  Watch Properties
    //
    //---------------------------------------------------
    watch: {
      DeleteWarningModal(){
          if(this.DeleteWarningModal === true)
          {
            this.uniqueModal.show();
            //console.log('pip');
          }
          if(this.DeleteWarningModal === false)
          {
            this.uniqueModal.hide();
            //console.log('pop');
          }       
        }
    },
    //---------------------------------------------------
    //
    //  Filter Properties
    //
    //---------------------------------------------------
    // filters: {},
    //---------------------------------------------------
    //
    //  Validation Properties
    //
    //---------------------------------------------------
    // validations: {},
    //---------------------------------------------------
    //
    //  Vue Lifecycle
    //
    //---------------------------------------------------
    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // render(h) { return h(); },
    mounted() {
      this.uniqueModal = new Modal(document.getElementById("deleteWarningModalID"),{ keyboard: false });
    },
    // beforeUpdate() {},
    // updated() {},
    // beforeDestroy() {},
    // destroyed() {},
    //---------------------------------------------------
    //
    //  Methods
    //
    //---------------------------------------------------
    methods: {},
  };
</script>
<style lang="scss">
</style>