import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import toast from 'vue-toast-notification'
import vClickOutside from 'click-outside-vue3'
import { defineComponent } from 'vue';
import { createI18n } from 'vue-i18n'
import messages from './translations'


const i18n = new createI18n({
    locale: 'de',
    fallbackLocale: null,
    messages,
  });

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faUser, faAddressCard, faRightFromBracket, faGear, faCircleUp, faCircleDown, faPen, faTrash, faXmark, faCircleHalfStroke, faMoon, faSun, faEllipsisVertical, faEye, faPlus,  faArrowUpFromBracket, faChevronDown, faChevronLeft, faChevronRight, faLocationDot, faCircleCheck, faBan, faBell, faTableColumns, faTruckFront, faEnvelope} from '@fortawesome/free-solid-svg-icons'


/* add icons to the library */
library.add(faUser, faAddressCard, faRightFromBracket, faGear, faCircleUp, faCircleDown, faPen, faTrash, faXmark, faCircleHalfStroke, faMoon, faSun,  faEllipsisVertical, faEye,faPlus,  faArrowUpFromBracket, faChevronDown, faChevronLeft, faChevronRight,  faLocationDot, faCircleCheck, faBan, faBell, faTableColumns, faTruckFront,  faEnvelope  )

createApp(App)
.use(router)
.use(toast)
.use(i18n)
.use(vClickOutside)
.use(defineComponent)

.component('font-awesome-icon', FontAwesomeIcon)
.mount('#app')
