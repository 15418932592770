<template>
    <div>

      <div class="modal" id="menucardModal" data-bs-backdrop="static" >
        <div class="modal-dialog modal-dialog-centered ">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title"  v-if="editDataMenucard">{{ $t('modal.title.updateMenucard') }}</h5>
              <h5 class="modal-title" v-else>{{ $t('modal.title.addMenucard') }}</h5>
            </div>
            <div class="modal-body">
              <div class="row">
                
                <div class="col-md-12">
                  <div class="form-floating mb-3">
                    <input type="text" :class="{'form-control': true, 'is-invalid': errors.name}"  id="menucardNameField" v-model="menucard.name" placeholder="Name" >
                    <label for="menucardNameField" class="form-label" >{{ $t('form.label.menucardName') }}</label>
                    <div class="invalid-feedback" v-if="errors.name">
                      <p v-for="(item, x) in errors.name" :key='x' >{{ errors.name[x] }}</p>
                    </div>
                  </div>
                </div>
              
                <div class="col-md-6">
                  <div class="form-floating mb-3">
                    <CategoriesDropdown 
                      :apiData="apiData"
                      type="menucard"
                      v-model="menucard.categories"
                       :class="{'is-invalid': errors.categories }"
                       :searchable="false"
                    />
                    <div class="invalid-feedback d-block" v-if="errors.categories">
                       <p v-for="(item, x) in errors.categories" :key='x' >{{ errors.categories[x] }}</p>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-floating mb-3">
                    <TrucksDropdown 
                      :trucks="trucks"
                      v-model="menucard.trucks"
                       :class="{'is-invalid': errors.trucks }"
                       :searchable="false"
                      />
                      <div class="invalid-feedback d-block" v-if="errors.trucks">
                       <p v-for="(item, x) in errors.categories" :key='x' >{{ errors.trucks[x] }}</p>
                    </div>
                  </div>
                </div>

              
              </div>

              <p>Eine Speisekarte kann mehrere Kategorien haben (Menus, Speisen, Getränke ...), denen du nachträglich Inhalte hinzufügst!</p>

            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" @click="modalHandling('menuCardModal')">Abbrechen</button>
              <button type="button" class="btn btn-primary" @click="updateMenucard()" v-if="editDataMenucard">Aktualisieren</button>
              <button type="button" class="btn btn-primary" @click="addMenucard()" v-else>Speichern</button>
            </div>
          </div>
        </div>
      </div>

    </div>
</template>
<script>
  import CategoriesDropdown from '@/components/CategoriesDropdownV2.vue';
  import TrucksDropdown from '@/components/TrucksDropdownV2.vue';
  import { Modal } from "bootstrap";
  export default {
    name: '${COMPONENT_NAME}',
    components: {
      CategoriesDropdown,
      TrucksDropdown 
    },
    //---------------------------------------------------
    //
    //  Properties
    //
    //---------------------------------------------------
    props: {
      config:{required: true},
      userData: {required: true},
      menuCardModal: {required: true},
      trucks: {required: true},
      editDataMenucard: {required: true},
      authFunction: {type: Function},
      apiData: {type: Function},
      modalHandling: {type: Function},
      getMenucards: {type: Function},
    },
    //---------------------------------------------------
    //
    //  Data model
    //
    //---------------------------------------------------
    data() {
      return {

        menucard:{
          name:'',
          categories:[],
          trucks:[],
          owner:this.userData.uuid,
          
        },
        request:{
          type:'menucard'
        },
        errors:{},
      };
    },
    //---------------------------------------------------
    //
    //  Computed Properties
    //
    //---------------------------------------------------
    computed: {},
    //---------------------------------------------------
    //
    //  Watch Properties
    //
    //---------------------------------------------------
    watch: {
      menuCardModal(){

        if(this.menuCardModal === true)
        {

          if(this.editDataMenucard){
            this.errors = {};
        this.menucard =  this.editDataMenucard;
        this.menucard.categories = this.editDataMenucard.categoriesID;
        this.menucard.trucks = this.editDataMenucard.trucksID;
        
      }else{
        this.errors = {};
        this.menucard.name='';
        this.menucard.categories=[];
        this.menucard.trucks=[];
        //console.log('Karte zurückgesetzt');
        
      }

          this.uniqueModal.show();
        }
        if(this.menuCardModal === false)
        {
          
          this.uniqueModal.hide();
        }
        //console.log('CHANGE MENUCARD MODAL');
      }
    },
    //---------------------------------------------------
    //
    //  Filter Properties
    //
    //---------------------------------------------------
    // filters: {},
    //---------------------------------------------------
    //
    //  Validation Properties
    //
    //---------------------------------------------------
    // validations: {},
    //---------------------------------------------------
    //
    //  Vue Lifecycle
    //
    //---------------------------------------------------
    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // render(h) { return h(); },
    mounted() {
      this.uniqueModal = new Modal(document.getElementById("menucardModal"),{ keyboard: false });
    },
    // beforeUpdate() {},
    // updated() {},
    // beforeDestroy() {},
    // destroyed() {},
    //---------------------------------------------------
    //
    //  Methods
    //
    //---------------------------------------------------
    methods: {
      /*getCategoriesSelected(value){
        this.menucard.categories = value;
      },*/
      getTrucksSelected(value){
        this.menucard.trucks = value;
      },
      async addMenucard(){
        let response;
        response = await this.apiData('AddMenucard.vue addTruck()','post','add-menucard', this.menucard);
        if (response.status === 'failure'){
          this.errors = response.errors;
          this.$toast.open({
            message: this.$t('toast.error'),
            type: 'error',
            duration:3000,
          });
        }else{
          this.modalHandling('menuCardModal');
          this.getMenucards();
          this.errors = {};
          this.$toast.open({
            message: this.$t('toast.addMenucard'),
            type: 'success',
            duration:3000,
          });
          
        }  
      },
      async updateMenucard(){
      let response;
      response = await this.apiData('MenucardModal.vue updateMenucard()','post','update-menucard', this.menucard);
      if (response.status === 'failure'){
        this.errors = response.errors;
        this.$toast.open({
          message: this.$t('toast.error'),
          type: 'error',
          duration:3000,
        });
      }else{
        this.modalHandling('menuCardModal');
        this.getMenucards();
        this.errors = {};
        this.$toast.open({
          message: this.$t('toast.editMenucard'),
          type: 'success',
          duration:3000,
        });
        
      }  
    }
    },
  };
</script>
<style lang="scss">
</style>