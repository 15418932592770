<template>
    <div>
      <div class="dropdown" >
        <button :class="['btn btn-custom-select','dropdown-toggle','d-flex','justify-content-between','align-items-center',customClass]"   style="width:100%; padding: 0.375rem 0.75rem 0.375rem 0.75rem;  appearance: none; border-radius: 0.25rem;"    type="button" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false"  data-bs-display="static">

          {{ $t('form.label.regions') }}
          <font-awesome-icon icon="fa-solid fa-chevron-down" />
        </button>
        <form class="dropdown-menu" style="min-width: 100%">
          <div class="dropdown-header" v-if="searchable === true">
            <div class="form-floating mb-3">
              <input type="text" :class="{'form-control': true}"  id="searchField" placeholder="Search" v-model="searchString">
              <label for="searchField" class="form-label" >{{ $t('form.label.search') }}</label>
            </div>
          </div>
          <hr class="dropdown-divider" v-if="searchable === true">
          <ul class="list-group list-group-flush">
            <li class="list-group-item"   v-for="(item, x) in filteredRegion" :key='x'>
              <input class="form-check-input me-1" type="checkbox" :value="item" :id="rand+'checkboxR'+x" v-model="internalSelectedRegion">
              <label class="form-check-label" :for="rand+'checkboxR'+x" style="min-width: 85%">{{item.name}}</label>
            </li>
          </ul>
        </form>
      </div>

    </div>
</template>
<script>
  import 'bootstrap/dist/js/bootstrap';
  export default {
    name: '${COMPONENT_NAME}',
    components: {
    },
    //---------------------------------------------------
    //
    //  Properties
    //
    //---------------------------------------------------
    props: {
      class: {
        type: [String, Object], // Akzeptiert entweder einen String oder ein Object für dynamische Klassen
        default: ''
      },
      apiData: {type: Function},
      searchable:{required:true},
      type:{required:true},
      modelValue: { type: Array, default: () => [] },
      requestRegion:  {required:true},
    },
    //---------------------------------------------------
    //
    //  Data model
    //
    //---------------------------------------------------
    data() {
      return {
        rand:null,
        internalSelectedRegion: [...this.modelValue], // Erstelle eine Kopie von modelValue
        selectedRegion:[],
        searchString:'',
        region:[],
      };
    },
    //---------------------------------------------------
    //
    //  Computed Properties
    //
    //---------------------------------------------------
    computed: {
      filteredRegion() {    
        const filteredRegion = this.searchString.toLowerCase() === ""
            ? this.region
            : this.region.filter( wo => Object.values(wo).join("").toLowerCase().indexOf(this.searchString.toLowerCase()) !== -1);    
        return filteredRegion;
      },
      customClass() {
      return this.class;
    }
    },
    //---------------------------------------------------
    //
    //  Watch Properties
    //
    //---------------------------------------------------
    watch: {
      // Überwache interne Änderungen und emitte sie zurück an die Parent-Komponente
    internalSelectedRegion(newVal) {
      // Verhindere Rekursion: Nur wenn sich internalSelectedCategories vom modelValue unterscheidet
      if (JSON.stringify(newVal) !== JSON.stringify(this.modelValue)) {
        this.$emit('update:modelValue', newVal);
      }
    },
    // Falls die Parent-Komponente den Wert von modelValue ändert, synchronisiere die interne Kopie
    modelValue(newVal) {
      // Verhindere Rekursion: Nur wenn sich modelValue von internalSelectedCategories unterscheidet
      if (JSON.stringify(newVal) !== JSON.stringify(this.internalSelectedRegion)) {
        this.internalSelectedRegion = [...newVal];
      }
    }
    },
    //---------------------------------------------------
    //
    //  Filter Properties
    //
    //---------------------------------------------------
    // filters: {},
    //---------------------------------------------------
    //
    //  Validation Properties
    //
    //---------------------------------------------------
    // validations: {},
    //---------------------------------------------------
    //
    //  Vue Lifecycle
    //
    //---------------------------------------------------
    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // render(h) { return h(); },
    async mounted() {
      await this.getRegion();
      this.rand = Math.floor(100000 + Math.random() * 900000);
      
      
    },
    async beforeUpdate() {
      /*
      if(this.editData){
        if(this.selectedRegion.length === 0)
        {
          for(let i = 0; i < this.editData.region.length; i++){
            this.selectedRegion.push(this.editData.region[i].id)
          }
          this.getRegionSelected(this.selectedRegion);
        }
      }*/
    },
    async updated() {
     
    },
    // beforeDestroy() {},
    // destroyed() {},
    //---------------------------------------------------
    //
    //  Methods
    //
    //---------------------------------------------------
    methods: {
      
      async getRegion(){
       /* let request = {
          country:'ch'
        };*/
        let response;
        response = await this.apiData('RegionDropdown.vue getRegion()','post','region-small', this.requestRegion);
        this.region = response;
      }
    },
  };
</script>
<style lang="scss">
.dropdown .list-group-flush {
  max-height: 200px;
  overflow-y: scroll;
  margin-top:0 !important;
  }
  .dropdown hr{
    margin-bottom:0 !important;
    margin-top:0 !important;
  }
  .dropdown .dropdown-header{
    padding-bottom:0  !important;
  }
  .btn-custom-select.is-invalid{
    border:1px solid #ff0000 !important;
  }
  
</style>