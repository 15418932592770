<template>
    <div class="mainFooter">
      <div class="container d-flex flex-wrap justify-content-between align-items-center ">
       
        <p class="col-md-4 mb-0 text-muted" style="margin:0 !important">{{ $t('footer.copyright') }}</p>
          <ul class="nav col-md-4 justify-content-end">
            <li class="nav-item"><a href="#" class="nav-link px-2 text-muted" @click="$router.push('/agb')">{{ $t('nav.footer.agb') }}</a></li>
            <li class="nav-item"><a href="#" class="nav-link px-2 text-muted" @click="$router.push('/dsv')">{{ $t('nav.footer.dsgvo') }}</a></li>
            <li class="nav-item"><a href="#" class="nav-link px-2 text-muted" @click="$router.push('/impressum')">{{ $t('nav.footer.inprint') }}</a></li>
            <li class="nav-item"><a href="#" class="nav-link px-2 text-muted" @click="$router.push('/contact')">{{ $t('nav.footer.contact') }}</a></li>
          </ul>
      
      </div>
    </div>
</template>
<script>
  export default {
    name: '${COMPONENT_NAME}',
    components: {
    },
    //---------------------------------------------------
    //
    //  Properties
    //
    //---------------------------------------------------
    props: {},
    //---------------------------------------------------
    //
    //  Data model
    //
    //---------------------------------------------------
    data() {
      return {};
    },
    //---------------------------------------------------
    //
    //  Computed Properties
    //
    //---------------------------------------------------
    computed: {},
    //---------------------------------------------------
    //
    //  Watch Properties
    //
    //---------------------------------------------------
    watch: {},
    //---------------------------------------------------
    //
    //  Filter Properties
    //
    //---------------------------------------------------
    // filters: {},
    //---------------------------------------------------
    //
    //  Validation Properties
    //
    //---------------------------------------------------
    // validations: {},
    //---------------------------------------------------
    //
    //  Vue Lifecycle
    //
    //---------------------------------------------------
    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // render(h) { return h(); },
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // beforeDestroy() {},
    // destroyed() {},
    //---------------------------------------------------
    //
    //  Methods
    //
    //---------------------------------------------------
    methods: {},
  };
</script>
<style lang="scss">
</style>