<template>
    <div>

      <div class="modal" id="logoUploadModal" data-bs-backdrop="static" >
        <div class="modal-dialog modal-dialog-centered  modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">{{$t('modal.title.uploadLogo')}}</h5>
            </div>
            <div class="modal-body">

              <vue-dropzone
                ref="myVueDropzone" 
                id="dropzone" 
                :options="dropzoneOptions"
                @vdropzone-success="successAction"
                @vdropzone-error="verror"
              />

            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" @click="modalHandling('logoUploadModal')">{{$t('button.cancel')}}</button>
            </div>
          </div>
        </div>
      </div>

    </div>
</template>
<script>
  import vueDropzone from 'vue2-dropzone-vue3'
  import { Modal } from "bootstrap";
  export default {
   
    name: '${COMPONENT_NAME}',
    components: {
      vueDropzone
    },
    //---------------------------------------------------
    //
    //  Properties
    //
    //---------------------------------------------------
    props: {
      config:{required: true},
      userData: {required: true},
      logoUploadModal: {required: true},
      authFunction: {type: Function},
      apiData: {type: Function},
      modalHandling: {type: Function}  
    },
    //---------------------------------------------------
    //
    //  Data model
    //
    //---------------------------------------------------
    data() {
      return {
        errors:{},
        userDetails:{
          id:this.userData.user_details.id
        },
        dropzoneOptions: {
                    url: this.config.apiURL+'api/'+this.config.apiVersion+'/logoupload',
                    headers: {
                        owner: localStorage.getItem('userUUID'),
                        'Cache-Control': null,
                        'X-Requested-With': null,
                        'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
                        'enctype':'multipart/form-data',
                       
                    },
                    
                    maxFilesize: 1, // MB
                    maxFiles: 1,
                    thumbnailWidth: 150, // px
                    thumbnailHeight: 150,
                    addRemoveLinks: true,
                    acceptedFiles:'image/*, application/pdf',


                },
      };
    },
    //---------------------------------------------------
    //
    //  Computed Properties
    //
    //---------------------------------------------------
    computed: {},
    //---------------------------------------------------
    //
    //  Watch Properties
    //
    //---------------------------------------------------
    watch: {
      logoUploadModal(){
        if(this.logoUploadModal === true)
        {
          this.uniqueModal.show();
        }
        if(this.logoUploadModal === false)
        {
          this.uniqueModal.hide();
        }
       // console.log('CHANGE LOGO MODAL');
      }
    },
    //---------------------------------------------------
    //
    //  Filter Properties
    //
    //---------------------------------------------------
    // filters: {},
    //---------------------------------------------------
    //
    //  Validation Properties
    //
    //---------------------------------------------------
    // validations: {},
    //---------------------------------------------------
    //
    //  Vue Lifecycle
    //
    //---------------------------------------------------
    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // render(h) { return h(); },
    mounted() {
      this.uniqueModal = new Modal(document.getElementById("logoUploadModal"),{ keyboard: false });
      //this.uniqueModal.show();
    },
    // beforeUpdate() {},
    // updated() {},
    // beforeDestroy() {},
    // destroyed() {},
    //---------------------------------------------------
    //
    //  Methods
    //
    //---------------------------------------------------
    methods: {
      
      async successAction(file){
          
          await this.modalHandling('logoUploadModal');
          this.$refs.myVueDropzone.removeFile(file);
          await this.authFunction();
          
          this.$toast.open({
            //message: this.$t('toast.uploadLogo'),
            message: 'Logo erfolgreich hochgeladen',
            type: 'success',
            duration:3000,
          });
        },
        verror(){
          this.$toast.open({
            //message: this.$t('toast.error'),
            message: 'Es ist ein Fehler aufgetreten',
            type: 'error',
            duration:3000,
          });

          },
          
     
    },
  };
</script>
<style lang="scss">
</style>