<template>
    <div>

      <div class="modal" id="blockedModal" >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">{{$t('modal.title.blockedAccount')}}</h5>
            </div>
            <div class="modal-body">

              {{$t('warning.blockedAccount')}}

            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-primary"  @click="$router.push('/contact');this.uniqueModal.hide();">{{$t('button.contact')}}</button>
            </div>
          </div>
        </div>
      </div>

    </div>
</template>
<script>
  import { Modal } from "bootstrap";
  export default {
    name: '${COMPONENT_NAME}',
    components: {
    },
    //---------------------------------------------------
    //
    //  Properties
    //
    //---------------------------------------------------
    props: {
    
    },
    //---------------------------------------------------
    //
    //  Data model
    //
    //---------------------------------------------------
    data() {
      return {
       
      };
    },
    //---------------------------------------------------
    //
    //  Computed Properties
    //
    //---------------------------------------------------
    computed: {},
    //---------------------------------------------------
    //
    //  Watch Properties
    //
    //---------------------------------------------------
    watch: {},
    //---------------------------------------------------
    //
    //  Filter Properties
    //
    //---------------------------------------------------
    // filters: {},
    //---------------------------------------------------
    //
    //  Validation Properties
    //
    //---------------------------------------------------
    // validations: {},
    //---------------------------------------------------
    //
    //  Vue Lifecycle
    //
    //---------------------------------------------------
    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // render(h) { return h(); },
    mounted() {
      this.uniqueModal = new Modal(document.getElementById("blockedModal"),{ keyboard: false });
      this.uniqueModal.show();
    },
    // beforeUpdate() {},
    // updated() {},
    // beforeDestroy() {},
    // destroyed() {},
    //---------------------------------------------------
    //
    //  Methods
    //
    //---------------------------------------------------
    methods: {
    },
  };
</script>
<style lang="scss">
</style>