<template>
    <div>
      <div class="modal" id="addTruckModal" data-bs-backdrop="static" >
        <div class="modal-dialog modal-dialog-centered  modal-xl modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" v-if="editData">{{ $t('modal.title.editTruck') }}</h5>
              <h5 class="modal-title" v-else>{{ $t('modal.title.addTruck') }}</h5>
            </div>
            <div class="modal-body">

              <div class="row">

                <div class="col-md-6">
                  <div class="form-floating mb-3">
                    <input type="text" :class="{'form-control': true, 'is-invalid': errors.name}"  id="truckNameField" v-model="truck.name" placeholder="Telefon">
                    <label for="truckNameField" class="form-label" >{{ $t('form.label.truckName') }}</label>
                    <div class="invalid-feedback" v-if="errors.name">
                      <p v-for="(item, x) in errors.name" :key='x' >{{ errors.name[x] }}</p>
                    </div>
                  </div>
                </div>
              
                <div class="col-md-3">
                  <div class="form-floating mb-3">
                    <CategoriesDropdown 
                      :class="{'is-invalid': errors.categories }"
                      :apiData="apiData"
                      type="foodtruck"
                      v-model="truck.categories"
                      :searchable="true"
                      />
                      <div class="invalid-feedback d-block" v-if="errors.categories">
                       <p v-for="(item, x) in errors.categories" :key='x' >{{ errors.categories[x] }}</p>
                    </div> 
                  </div>
                </div>
                
                <div class="col-md-3">
                  <div class="form-floating mb-3">
                  
                    <RegionDropdown 
                      :apiData="apiData"
                      v-model="truck.region"
                      :class="{'is-invalid': errors.region }"
                      :requestRegion="requestRegion"
                      :searchable="true"
                      />
                    <div class="invalid-feedback  d-block" v-if="errors.region">
                       <p v-for="(item, x) in errors.region" :key='x' >{{ errors.region[x] }}</p>
                    </div> 
                      
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-floating mb-3">
                    <input type="text" :class="{'form-control': true, 'is-invalid': errors.length}"  id="lengthField" v-model="truck.length" placeholder="Länge">
                    <label for="lengthField" class="form-label" >{{ $t('form.label.length') }}</label>
                    <div class="invalid-feedback" v-if="errors.length">
                      <p v-for="(item, x) in errors.length" :key='x' >{{ errors.length[x] }}</p>
                    </div>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-floating mb-3">
                    <input type="text" :class="{'form-control': true, 'is-invalid': errors.width}"  id="widthField" v-model="truck.width" placeholder="Breite (cm)">
                    <label for="widthField" class="form-label" >{{ $t('form.label.width') }}</label>
                    <div class="invalid-feedback" v-if="errors.width">
                      <p v-for="(item, x) in errors.width" :key='x' >{{ errors.width[x] }}</p>
                    </div>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-floating mb-3">
                    <input type="text" :class="{'form-control': true, 'is-invalid': errors.height}"  id="heightField" v-model="truck.height" placeholder="Höhe (cm)">
                    <label for="heightField" class="form-label" >{{ $t('form.label.height') }}</label>
                    <div class="invalid-feedback" v-if="errors.height">
                      <p v-for="(item, x) in errors.height" :key='x' >{{ errors.height[x] }}</p>
                    </div>
                  </div>
                </div>

              
              </div>

              <label for="dropzone" class="form-label" >{{ $t('form.label.addImage') }}</label>
              <vue-dropzone
                ref="myVueDropzone" 
                id="dropzone" 
                :options="dropzoneOptions"
                @vdropzone-success="successAction"
                @vdropzone-error="verror"
              />
              
              
              <div class="card" style="margin-top:20px; min-height:230px">
                <div class="card-body d-flex flex-row flex-wrap">
                  <div v-for="(item, x) in previews" :key='x' class="truckTmbContainer">
                    <div class="truckTmb"  :style="{ backgroundImage:'url('+config.storageURL+userData.uuid+'/img/'+item.name+')' }">
                     
                    </div>

                    <div class="truckTmbBody">
                      <input class="form-check-input" type="radio" name="defaultImg" v-model="truck.defaultImg" :value="item.id" :id="'defaultImg_'+ x">
                      <label class="form-check-label" :for="'defaultImg_'+ x">Hauptbild</label>
                      <button class="btn btn-danger" @click="deleteFileTmp(item)" v-if="this.editData" >{{$t('button.delete')}}</button>
                      <button class="btn btn-danger" @click="deleteFile(item)" v-else>{{$t('button.delete')}}</button>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <div class="modal-footer">
              
              <button type="button" class="btn btn-secondary" @click="modalHandling('addTruckModal')">{{$t('button.cancel')}}</button>
              <button type="button" class="btn btn-primary" @click="updateTruck()" v-if="editData">{{$t('button.update')}}</button>
              <button type="button" class="btn btn-primary" @click="addTruck()" v-else>{{$t('button.save')}}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
  import CategoriesDropdown from '@/components/CategoriesDropdownV2.vue';
  import RegionDropdown from '@/components/RegionDropdownV2.vue';
  import { Modal } from "bootstrap";
  import vueDropzone from 'vue2-dropzone-vue3'
  export default {
    name: '${COMPONENT_NAME}',
    components: {
      vueDropzone,
      CategoriesDropdown,
      RegionDropdown
    },
    //---------------------------------------------------
    //
    //  Properties
    //
    //---------------------------------------------------
    props: {
      userData: {required: true},
      editData: {required: true},
      addTruckModal: {required: true},
      authFunction: {type: Function},
      apiData: {type: Function},
      modalHandling: {type: Function},
      getTrucks: {type: Function},
      config:{required: true}, 
    },
    //---------------------------------------------------
    //
    //  Data model
    //
    //---------------------------------------------------
    data() {
      return {
        previews:[],
        request:{
          type:'foodtruck'
        },
        regionRequest:{
          country:'ch'
        },
        requestRegion:{
       // country:'ch',
        selectorTable:'country',
        selectorValue:'ch',

      },
        errors:{},
        truck:{
          name:'',
          length:null,
          width:null,
          height:null,
          categories:[],
          region:[],
          images:[],
          defaultImg:'',
          owner:this.userData.uuid,
        },
        dropzoneOptions: {
                    //url: 'https://api.haselabs.ch/api/v1/dropzone',
                    url: this.config.apiURL+'api/'+this.config.apiVersion+'/dropzone',
                    headers: {
                        owner: localStorage.getItem('userUUID'),
                        'Cache-Control': null,
                        'X-Requested-With': null,
                        'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
                        'enctype':'multipart/form-data',
                       
                    },
                    
                    maxFilesize: 1, // MB
                    maxFiles: 40,
                    thumbnailWidth: 150, // px
                    thumbnailHeight: 150,
                    addRemoveLinks: true,
                    acceptedFiles:'image/*, application/pdf',


                },
      
      };
    },
    //---------------------------------------------------
    //
    //  Computed Properties
    //
    //---------------------------------------------------
    computed: {},
    //---------------------------------------------------
    //
    //  Watch Properties
    //
    //---------------------------------------------------
    watch: {
      addTruckModal(){
        if(this.addTruckModal === true)
        {
        if(this.editData){
          this.errors = {};
        this.previews = [];
        this.truck =  this.editData;
        this.truck.categories = this.editData.categoriesID;
        this.truck.region = this.editData.regionID;
        //console.log("EDIT DATA ADD TRUCK");
        //console.log(this.editData);

        this.loadPreviews();
        
      }else{
        this.errors = {};
        this.previews = [];
        this.truck.name='';
        this.truck.length=null;
        this.truck.width=null;
        this.truck.height=null;
        this.truck.categories=[];
        this.truck.region=[];
        this.truck.images=[];
        this.truck.defaultImg='';
        this.truck.owner=this.userData.uuid;
        
      }
          this.uniqueModal.show();
        }
        if(this.addTruckModal === false)
        {
          this.uniqueModal.hide();
        }
        
      }
    },
    //---------------------------------------------------
    //
    //  Filter Properties
    //
    //---------------------------------------------------
    // filters: {},
    //---------------------------------------------------
    //
    //  Validation Properties
    //
    //---------------------------------------------------
    // validations: {},
    //---------------------------------------------------
    //
    //  Vue Lifecycle
    //
    //---------------------------------------------------
    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // render(h) { return h(); },
    async mounted() {
      
        
      this.uniqueModal = new Modal(document.getElementById("addTruckModal"),{ keyboard: false });
    },
    async beforeUpdate() {
     
    },
    // async updated() {},
    // beforeDestroy() {},
    // destroyed() {},
    //---------------------------------------------------
    //
    //  Methods
    //
    //---------------------------------------------------
    methods: {
      async addTruck(){
        let response;
        response = await this.apiData('AddTruck.vue addTruck()','post','add-truck', this.truck);
        if (response.status === 'failure'){
          this.errors = response.errors;
          this.$toast.open({
            message: this.$t('toast.error'),
            type: 'error',
            duration:3000,
          });
        }else{
          //this.uniqueModal.hide();
          this.modalHandling('addTruckModal');
          this.getTrucks();
          this.errors = {};
          this.$toast.open({
            message: this.$t('toast.addTruck'),
            type: 'success',
            duration:3000,
          });
          
        }  
      },
      /*
      getCategoriesSelected(value){
        this.truck.categories = value;
      },
      
      getRegionSelected(value){
        this.truck.region = value;
      },
      */
      async deleteFile(object){
        let response;
        response = await this.apiData('AddTruck.vue deleteFile()','post','delete-file', object);
        console.log(response);
        
        this.truck.images.splice(this.truck.images.indexOf(object.id), 1);
        
        if(object.id === this.truck.defaultImg){
          this.truck.defaultImg = null;
        }
        this.loadPreviews();
        
      },
      
      async deleteFileTmp(object) { 
          // Finde den Index des Objekts in truck.images anhand der ID
          const imageIndex = this.truck.images.indexOf(object.id);
          
          if (imageIndex !== -1) {
              // Entferne die ID aus truck.images
              this.truck.images.splice(imageIndex, 1);
          }

          // Finde den Index des Objekts in previews anhand des gesamten Objekts
          const previewIndex = this.previews.indexOf(object);

          if (previewIndex !== -1) {
              // Entferne das Objekt aus previews
              this.previews.splice(previewIndex, 1);
          }

          // Überprüfe, ob das gelöschte Objekt das Standardbild war
          if (object.id === this.truck.defaultImg) {
              // Setze das erste Bild als Standardbild, wenn noch Bilder vorhanden sind
              if (this.truck.images.length > 0) {
                  this.truck.defaultImg = this.truck.images[0];
              } else {
                  this.truck.defaultImg = null;
              }
          }
      },

      async successAction(file, response){
          //await this.getFiles();
          this.$refs.myVueDropzone.removeFile(file);
          
          this.truck.images.push(response.id);
          this.loadPreviews();
        },
        verror(file, response){

          //console.log(file);
          //console.log("------------------------------------------------------------------------------------------");
          console.log(response);
          //console.log("------------------------------------------------------------------------------------------");
        },
        async loadPreviews(){
          
          let imgResponse
          imgResponse = await this.apiData('AddTruck.vue successAction()','post','get-img-by-id', this.truck.images);
          if(!this.truck.defaultImg){
            this.truck.defaultImg = this.truck.images[0];
          }
          this.previews = imgResponse;
        },
        async updateTruck(){
          //console.log('UPDATE DATA');
          //console.log(this.truck);

          let response;
        response = await this.apiData('AddTruck.vue updateTruck()','post','update-truck', this.truck);
        if (response.status === 'failure'){
          this.errors = response.errors;
          this.$toast.open({
            message: this.$t('toast.error'),
            type: 'error',
            duration:3000,
          });
        }else{
          this.modalHandling('addTruckModal');
          this.getTrucks();
          this.errors = {};
          this.$toast.open({
            message: this.$t('toast.editTruck'),
            type: 'success',
            duration:3000,
          });
          
        } 
        
        }
    },
  };
</script>
<style lang="scss">
.truckTmb{
  
  width:118px;
  height:80px;
  background-position: center center;
  background-size: cover;
}
.truckTmbContainer{
  margin:10px;
  width:120px;
  border:1px solid var(--bs-border-color);
}
.truckTmbBody{
  margin:10px 10px 10px 10px;
}
.truckTmbBody button{
  width:100%;
}
.truckTmbBody label{
  margin-bottom:10px;
}
.truckTmbBody input{
  margin-right:5px;
}
.truckTmbBody button{
  margin-bottom:0 !important;
}
</style>