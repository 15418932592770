<template>
  <div>
    <div  v-if="loading===true" class="pageLoader">
      <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <div v-if="loading===false">
      <!-- User Header -->
      <MainHeader
      v-if="isAuthenticated && isVerified && !isBlocked" 
      :isAuthenticated="isAuthenticated"
      :userData="userData"
      :apiData="apiData"
      :setLoginStatus="setLoginStatus" 
      :resetUserData="resetUserData"
      :config="config"
      :colorTheme="colorTheme"
      />
      <!-- End User Header -->
      <!-- Public Header -->
      <PublicHeader
      v-else 
      :setColorMode="setColorMode"
      :colorMode="colorMode"
      :colorTheme="colorTheme"
      />
      <!-- End Public Header -->

      <!-- Verification Panel  -->
      <EmailVerification 
      v-if="isAuthenticated ===true && isVerified === false"
      :userData="userData"
      :authFunction="authFunction"
      :isAuthenticated="isAuthenticated"
      :isVerified="isVerified" 
      :apiData="apiData" 
      />
      <!-- END Verification Panel -->

      <!-- Complete Registration Panel  -->
      <CompleteUserDetails
      v-if="isAuthenticated ===true && isVerified === true && userData.user_details.city === ''"
      :userData="userData"
      :authFunction="authFunction"
      :apiData="apiData" 
      />
      <!-- END complete Registration Panel -->
      <!-- Blocked Panel  -->
      <BlockedPanel
      v-if="isBlocked"
      />
      <!-- END Blocked Panel -->

      <div class="mainContent">
        <!-- Public Router -->
        <div class="container  py-4" v-if="!isAuthenticated && !isVerified || isBlocked" >
          <router-view 
          
          :setLoginStatus="setLoginStatus" 
          :config="config" 
          :setUserData="setUserData" 
          :userData="userData" 
          :authFunction="authFunction"
          :apiData="apiData"
          :colorMode="colorMode"
          
          name="publicRouter"
          />
        </div>
        <!-- End Public Router -->  
        <!-- Private Router -->
        <div class="container py-4" v-if="isAuthenticated && isVerified && !isBlocked" >
          <router-view 
          :setColorMode="setColorMode"
          :apiData="apiData" 
          :setLoginStatus="setLoginStatus" 
          :setUserData="setUserData" 
          :userData="userData" 
          :config="config" 
          :isAuthenticated="isAuthenticated" 
          :isVerified="isVerified" 
          :authFunction="authFunction"
          :colorMode="colorMode"
          :colorTheme="colorTheme"
          :resetUserData="resetUserData"
          
          name="privateRouter"
          />
        </div>
        <!-- End Private Router -->
      </div>
      <MainFooter />
    </div>
    <cookieconsent>
      <template
        v-slot:message
      >
        <div>
        Wir verwenden Cookies für die stetige Verbesserung unserer Dienstleistungen und Angebote.
        <a href="#" @click="$router.push('/dsv')">Datenschutzerklärung.</a>
        </div>
      </template>
      <template
        v-slot:button
      >
        <button class="btn btn-primary">Akzeptieren</button>
      </template>
    </cookieconsent>
  </div>
</template>
<script>
import EmailVerification from "@/components/EmailVerification.vue"
import CompleteUserDetails from "@/components/CompleteUserDetails.vue"
import BlockedPanel from "@/components/BlockedPanel.vue"
import config from './config.json'
import '../node_modules/bootstrap/dist/js/bootstrap.min.js'
import MainHeader from '@/components/MainHeader.vue'
import PublicHeader from '@/components/PublicHeader.vue'
import MainFooter from '@/components/MainFooter.vue'
import cookieconsent from 'vue-cookieconsent-component'
import axios from 'axios';


export default {
  name: 'MainApp',
  components: {
    PublicHeader,
    EmailVerification,
    CompleteUserDetails,
    MainHeader,
    MainFooter,
    cookieconsent,
    BlockedPanel
  },
  //---------------------------------------------------
  //
  //  Properties
  //
  //---------------------------------------------------
  props: {},
  //---------------------------------------------------
  //
  //  Data model
  //
  //---------------------------------------------------
  data() {
    return {
      userDataLoading:true,
      loading:false,
      colorMode:'light',
      colorTheme:null,
      verifyError:false,
      userDataLocal: this.userData,
      //loginNav:'login',
      bindValue:"",

      config: config,
      isAuthenticated:null,
      isVerified:null,
      isBlocked:null,
      userData:{},
      workingmodel:{},
    };
  },
  //---------------------------------------------------
  //
  //  Computed Properties
  //
  //---------------------------------------------------
  computed: {},
  //---------------------------------------------------
  //
  //  Watch Properties
  //
  //---------------------------------------------------
  watch: {},
  //---------------------------------------------------
  //
  //  Filter Properties
  //
  //---------------------------------------------------
  // filters: {},
  //---------------------------------------------------
  //
  //  Validation Properties
  //
  //---------------------------------------------------
  // validations: {},
  //---------------------------------------------------
  //
  //  Vue Lifecycle
  //
  //---------------------------------------------------
  // beforeCreate() {},
  // async created() {},
  async beforeMount() {  
    this.authFunction();
  },
   async mounted() {
    //console.log(this.userData)
    /*
    if(!this.userData.user_settings){
      this.setColorMode('auto');
    }
    */
    //console.log(localStorage.getItem('colorMode'));
    if(localStorage.getItem('colorMode') === null){
      //console.log('es ist kein color mode im speicher');
      this.setColorMode('auto');
      localStorage.setItem('colorMode', 'auto');
    }else{
      //console.log('es ist ein color mode im speicher');
      this.setColorMode(localStorage.getItem('colorMode'));
    }
    
   },
  // async beforeUpdate() {},
  // updated() {},
  // beforeDestroy() {},
  // destroyed() {},
  //---------------------------------------------------
  //
  //  Methods
  //
  //---------------------------------------------------
  methods: {
    /*setLoginNav(value){
      this.loginNav = value;
    },*/
    // Set User Data zum vererben
    async setUserData(){
        //this.loading = true;
        this.userData = await this.apiData('App.vue setUserData()','get','user');
        //this.loading = false;
    },
    async resetUserData(){
        this.userData = {};
    },
    // Set Login Status
    setLoginStatus(status){
      this.isAuthenticated = status;
      this.isVerified = status;
    },
    //API Data zum vererben
    async apiData(sender, action, endPoint, data){
      //console.log('Access API Data from: ' + sender);
      let apiReturn;
      var config = {
        method: action,
        url: this.config.apiURL+'api/'+this.config.apiVersion+'/'+endPoint,
        data: data,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('access_token')
        },
      };
      await axios(config).then((response) => {
        if(!response.data){
          return 'keine API daten';
        }else{
          apiReturn = response.data;
        }
        }).catch(function (error) {
          return error;
        });
      return apiReturn;
    },
    //Auth Function Zum vererben
    async authFunction(){
      this.loading = true;
      let checkForToken =  localStorage.getItem('access_token');
      if(checkForToken){
        this.userData = await this.apiData('App.vue beforeMount()', 'get','user');
        
        
        //this.setColorMode(this.userData.user_settings[0].theme);
        
        if(this.userData){
          this.isAuthenticated = true;
        }else{
          this.isAuthenticated = false;
        }
        localStorage.setItem('userUUID', this.userData.uuid);
      }else{
        this.isAuthenticated = false;
      }
      if(await this.userData.email_verified_at == null){
        this.isVerified = false;
      }else{
        this.isVerified = true;
      }
      if(await this.userData.blocked === 0){
        this.isBlocked = false;
      }else if(await this.userData.blocked === 1){
        this.isBlocked = true;
        localStorage.removeItem('userUUID');
        localStorage.removeItem('access_token');
      }
      this.loading = false;
    },
    //Veryfy E-Mail
    async handleOnComplete(){
      let response;
      this.userDataLocal = this.userData;
      this.userDataLocal.code = this.bindValue;
      response = await this.apiData('Login.vue login()','post','auth/veryficate', this.userDataLocal);
      if(response === 1){
        this.authFunction();
        //console.log('eingabe richtig');
        this.verifyError = false;
      }else{
        //console.log('eingabe falsch')
        this.verifyError = true; 
      }     
    },
    setColorMode(value){
      this.colorMode = value;
      if(this.colorMode === 'auto'){
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        document.body.setAttribute('data-bs-theme', 'dark');
        localStorage.setItem('theme', 'dark');
        this.loadFlatpickrTheme('dark');
        this.colorTheme = 'dark';
      }else{
        document.body.setAttribute('data-bs-theme', 'light');
        localStorage.setItem('theme', 'light');
        this.loadFlatpickrTheme('light');
        this.colorTheme = 'light';
      }
    }
    if(this.colorMode === 'dark'){
      document.body.setAttribute('data-bs-theme', 'dark');
      localStorage.setItem('theme', 'dark');
      this.loadFlatpickrTheme('dark');
      this.colorTheme = 'dark';
    }
    if(this.colorMode === 'light'){
      document.body.setAttribute('data-bs-theme', 'light');
      localStorage.setItem('theme', 'light');
      this.loadFlatpickrTheme('light');
      this.colorTheme = 'light';
    }

    },
    loadFlatpickrTheme(theme) {
    // Entferne vorhandenes Flatpickr-CSS, falls vorhanden
    const existingLink = document.getElementById('flatpickr-theme');
    if (existingLink) {
      existingLink.remove();
    }
    
    // Lade das entsprechende Theme-CSS basierend auf dem Farbschema
    const link = document.createElement('link');
    link.id = 'flatpickr-theme';
    link.rel = 'stylesheet';
    
    if (theme === 'dark') {
      link.href = 'https://cdn.jsdelivr.net/npm/flatpickr/dist/themes/dark.css';
    } else {
      link.href = 'https://cdn.jsdelivr.net/npm/flatpickr/dist/themes/material_blue.css';
    }
    
    // Füge das Theme-CSS zum <head> hinzu
    document.head.appendChild(link);
  }
  },
};
</script>
<style lang="scss">
  @import "@/scss/app";
</style>