<template>
    <div style="height:100%; flex-grow: 1;" class="d-flex h-100 flex-column">
      <!-- FILTER -->
      <div class="row">
        <div class="col-md-12">
          <div class="card">
          <div class="card-header"><h5>{{ $t('card.title.filter') }}</h5></div>
          <div class="card-body">
              
              <div class="row">
              
              <div class="col-md-4" style="margin-bottom:0 !important">
                <div class="form-floating mb-3">
                  <input v-model="mainFilter.text" placeholder="suchen" class="form-control" id="tableSearch">
                  <label for="tableSearch" class="form-label" >{{ $t('form.label.search') }}</label>
                </div>
              </div>
              <div class="col-md-4"  >
                <CategoriesDropdown 
                    :apiData="apiData"
                    v-model="mainFilter.categories"
                    :class="{'is-invalid': errors.categories }"
                    :searchable="false"
                    type="location"
                    />
              </div>
              <div class="col-md-4"  style="margin-bottom:0 !important">
                <RegionDropdown 
                    :apiData="apiData"
                    v-model="mainFilter.region"
                    :class="{'is-invalid': errors.region }"
                    :requestRegion="requestRegion"
                    :searchable="true"
                    />
              </div>
              <div class="col-md-12"  style="margin-bottom:0 !important">
                <template v-if="mainFilter.text">
                  <span class="badge bg-secondary" style="margin-right:5px">{{mainFilter.text}} <font-awesome-icon icon="fa-solid fa-xmark"  style="cursor: pointer;" @click="mainFilter.text = ''" /></span>&nbsp;
                </template>
                <template v-for="(item, x) in mainFilter.categories" :key='x' >
                  <span class="badge bg-secondary" style="margin-right:5px">{{item.name}} <font-awesome-icon icon="fa-solid fa-xmark"  style="cursor: pointer;" @click="removeFilterBadge('categories', x)" /></span>&nbsp;
                </template>
                <template v-for="(item, x) in mainFilter.region" :key='x' >
                  <span class="badge bg-secondary" style="margin-right:5px">{{item.name}} <font-awesome-icon icon="fa-solid fa-xmark"  style="cursor: pointer;" @click="removeFilterBadge('region', x)" /></span>&nbsp;
                </template>
                
              </div>
            </div>
            
          </div>
        </div>
        </div>
      </div>
      <!-- FILTER END -->
       
      <!-- TAB NAV -->
        <div class="col-md-12">
          <ul class="nav nav-tabs d-flex" >
            <li class="nav-item">
              <a :class="{'nav-link': true, 'active': view ==='list'}" aria-current="page" href="#"  @click.prevent="view ='list';currentPage=1">{{ $t('tab.list') }}</a>
            </li>
            <li class="nav-item">
              <a :class="{'nav-link': true, 'active': view ==='map'}" href="#" @click.prevent="view ='map'">{{ $t('tab.map') }}</a>
            </li>
          </ul>
          
        </div>
        <!-- TAB NAV END -->
         
      <template v-if="view === 'list'">
        <!-- PAGINATION NAV -->
        <nav aria-label="Page navigation" v-if="locationsGrouped.length > 0">
          <ul class="pagination">
            <li class="page-item" :class="{ disabled: currentPage === 1 }">
              <a class="page-link" href="#" @click.prevent="changePage(currentPage - 1)">{{ $t('button.previous') }}</a>
            </li>
            <li class="page-item" v-for="page in totalPages" :key="page" :class="{ active: currentPage === page }">
              <a class="page-link" href="#" @click.prevent="changePage(page)">{{ page }}</a>
            </li>
            <li class="page-item" :class="{ disabled: currentPage === totalPages }">
              <a class="page-link" href="#" @click.prevent="changePage(currentPage + 1)">{{ $t('button.next') }}</a>
            </li>
          </ul>
        </nav>
        <!-- PAGINATION NAV END -->
        
        <!-- LOCATION CARDS -->
        <div class="row" v-for="(item, x) in locationsGrouped" :key='x'>
          <div class="col-md-6 d-flex" v-for="(subitem, y) in item" :key='y' style="margin-bottom:0 !important">
            <!-- CARD -->
            <div class="card mb-3 truckCard d-flex" style="width:100%; cursor: pointer;" @click="$router.push('/location/'+subitem.id)">
              <div class="row h-100 d-flex" style="margin-left: 0 !important; ">
                <div class="col-4 truckCardImg d-flex" v-if="subitem.default_img" :style="{ backgroundImage:'url('+config.storageURL+subitem.owner+'/img/'+subitem.default_img.name+')' }">
                
                </div>
                <div class="col-4 truckCardImg d-flex" v-else :style="{ backgroundImage: `url(${require('@/assets/default_location.png')})` }" >

                </div>
                <div class="col-8 d-flex flex-column" style="margin-bottom: 0 !important; padding-left:0">
                  <div class="card-body">
                    <h5 class="card-title">{{ subitem.name }}</h5>
                    <ul class="list-group list-group-flush">
                      <li class="list-group-item"  style="padding-left:0" v-if="subitem.street">{{ $t('form.label.street') }}: {{ subitem.street }}</li>
                      <li class="list-group-item"  style="padding-left:0">{{ $t('form.label.city') }}: {{ subitem.city }}</li>
                      <li class="list-group-item"  style="padding-left:0">{{ $t('form.label.type') }}: {{ subitem.type.name }}</li>
                      <li class="list-group-item"  style="padding-left:0">{{ $t('form.label.region') }}: {{ subitem.region.name }}</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <!-- END CARD -->
          </div>
        </div>
        <!-- LOCATION CARDS END -->
        <!-- PAGINATION NAV -->
        <nav aria-label="Page navigation" v-if="locationsGrouped.length > 0">
          <ul class="pagination">
            <li class="page-item" :class="{ disabled: currentPage === 1 }">
              <a class="page-link" href="#" @click="changePage(currentPage - 1)">{{ $t('button.previous') }}</a>
            </li>
            <li class="page-item" v-for="page in totalPages" :key="page" :class="{ active: currentPage === page }">
              <a class="page-link" href="#" @click="changePage(page)">{{ page }}</a>
            </li>
            <li class="page-item" :class="{ disabled: currentPage === totalPages }">
              <a class="page-link" href="#" @click="changePage(currentPage + 1)">{{ $t('button.next') }}</a>
            </li>
          </ul>
        </nav>
        <!-- PAGINATION NAV END -->
      </template>
  <template  v-if="view === 'map' && loading === false">
    <div class="card h-100" style=" flex-grow: 1; height:100%">
      <div class="card-body  flex-grow-1 d-flex flex-column" >
    
        <MapboxMap 
          @mb-created="initializeMap"
          access-token="pk.eyJ1Ijoic2JlbmN6ZSIsImEiOiJjbTFiYXVrY3YwbWY0MmpzYm0wamc0bDhiIn0.R_6w0DsjbSGASOg2Snz8Ng"
          style="min-height: 600px; height: 100% !important; width: 100%"
          :center="mapCenterDefault"
          class="h-100 w-100 flex-grow-1"
          
          :map-style="mapboxTheme"
          :zoom="zoom"
          :scrollZoom="false"
        >
        <MapboxNavigationControl position="top-left" :showCompass="false" />
        <!-- LOCATIONS -->
          <template v-for="(item, x) in filteredLocations" :key="x">
            <!-- Marker mit Popup aktiviert -->
            <MapboxMarker :lng-lat="[item.long, item.lat]"  color="#f9b233" ref="mapMarkers"  > 
            <template v-slot:popup>
            <MapboxPopup :lng-lat="[item.long, item.lat]" v-if="activeMarker === x" @mb-close="onPopupClose(x)">
              <h5 @click="$router.push('/location/'+item.id)">{{ item.name }}</h5>
              <div @click="$router.push('/location/'+item.id)" style="cursor:pointer">
              <ul class="list-group list-group-flush">
                <li class="list-group-item"   v-if="item.street">Strasse: {{ item.street }}</li>
                <li class="list-group-item"  >Ort: {{ item.city }}</li>
                <li class="list-group-item"  >Typ: {{ item.type.name }}</li>
                <li class="list-group-item"  >Region: {{ item.region.name }}</li>
              </ul>
            </div>
            </MapboxPopup>
              </template>
              <template v-slot:default >
              <div  
                class="mapboxgl-marker mapboxgl-marker-anchor-center"
                style="position: absolute; transform: translate(-50%, -100%);"
                @click.stop="handleListClick(x, [item.long, item.lat])"
                >
                <svg display="block" height="41px" width="27px" viewBox="0 0 27 41"><defs><radialGradient id="shadowGradient"><stop offset="10%" stop-opacity="0.4"></stop><stop offset="100%" stop-opacity="0.05"></stop></radialGradient></defs><ellipse cx="13.5" cy="34.8" rx="10.5" ry="5.25" fill="url(#shadowGradient)"></ellipse><path fill="#f9b233" d="M27,13.5C27,19.07 20.25,27 14.75,34.5C14.02,35.5 12.98,35.5 12.25,34.5C6.75,27 0,19.22 0,13.5C0,6.04 6.04,0 13.5,0C20.96,0 27,6.04 27,13.5Z"></path><path opacity="0.25" d="M13.5,0C6.04,0 0,6.04 0,13.5C0,19.22 6.75,27 12.25,34.5C13,35.52 14.02,35.5 14.75,34.5C20.25,27 27,19.07 27,13.5C27,6.04 20.96,0 13.5,0ZM13.5,1C20.42,1 26,6.58 26,13.5C26,15.9 24.5,19.18 22.22,22.74C19.95,26.3 16.71,30.14 13.94,33.91C13.74,34.18 13.61,34.32 13.5,34.44C13.39,34.32 13.26,34.18 13.06,33.91C10.28,30.13 7.41,26.31 5.02,22.77C2.62,19.23 1,15.95 1,13.5C1,6.58 6.58,1 13.5,1Z"></path><circle fill="white" cx="13.5" cy="13.5" r="5.5"></circle></svg>
              </div>
            </template> 
            <!-- Popup-Inhalt END -->
            </MapboxMarker>
          </template>
          <!-- LOCATIONS END -->
        </MapboxMap>
        
      </div>
    </div>
    
  </template>
</div>

</template>

<script>
 import RegionDropdown from '@/components/RegionDropdownV2extended.vue';
 import CategoriesDropdown from '@/components/CategoriesDropdownV2extended.vue';
 import { MapboxMap,  MapboxMarker, MapboxPopup, MapboxNavigationControl } from '@studiometa/vue-mapbox-gl';
  export default {
    name: '${COMPONENT_NAME}',
    components: {
      MapboxMap,  MapboxMarker, MapboxPopup, //MapboxLayer,  MapboxSource,
      RegionDropdown,
      CategoriesDropdown,
      MapboxNavigationControl
    },
    //---------------------------------------------------
    //
    //  Properties
    //
    //---------------------------------------------------
    props: {
      apiData: {type: Function},
      config:{required:true}
    },
    //---------------------------------------------------
    //
    //  Data model
    //
    //---------------------------------------------------
    data() {
      return {
        filterActions:0,
        activeMarker:null,
        loading:true,
        markers:[],
        mapCenter:[8.2311, 46.8083],
        mapCenterDefault:[8.2311, 46.8083],        
        map: null,
        zoom:null,

        view:'list',
        itemsPerPage: 10,           // Anzahl der Items pro Seite
        currentPage: 1,             // Aktuelle Seite
        errors:{},
        mainFilter:{
          text:'',
          region:[],
          categories:[]
        },
        locations:[],
        //trucksGrouped:[]
      };
    },
    //---------------------------------------------------
    //
    //  Computed Properties
    //
    //---------------------------------------------------
    computed: {
      mapboxTheme(){
        let value
        if(localStorage.getItem('theme') === 'light'){
          value ='mapbox://styles/mapbox/light-v11';
        }
        if(localStorage.getItem('theme') === 'dark'){
          value ='mapbox://styles/mapbox/dark-v11';
        }
        return value;
      },
      preFiltered() {
        const searchText = this.mainFilter.text.toLowerCase();

        // Funktion, um alle Werte eines Objekts, auch verschachtelte, als String zusammenzuführen
        const flattenObjectValues = (obj) => {
          return Object.values(obj).map(value => {
            if (typeof value === 'object' && value !== null) {
              return flattenObjectValues(value);
            }
            return String(value);
          }).join(""); // Verknüpfe alle Werte zu einem String
        };

        // Filter anwenden, basierend auf 'searchText'
        const preFiltered = this.locations.filter(truck => {
          return searchText === ""
            ? true
            : flattenObjectValues(truck).toLowerCase().includes(searchText);
        });

        return preFiltered;
      },

      filteredLocations() {
        let filtered = this.preFiltered; // Keine Klammern hier!

        // Region-Filter
        if (this.mainFilter.region.length > 0) {
          const regionIds = this.mainFilter.region.map(region => region.id);
          filtered = filtered.filter(location => {
            // Da region jetzt ein Integer ist, überprüfen wir die region.id direkt
            return regionIds.includes(location.region.id);
          });
        }

        // Kategorien-Filter (Type ID)
        if (this.mainFilter.categories.length > 0) {
          const categoryIds = this.mainFilter.categories.map(category => category.id);
          filtered = filtered.filter(location => {
            // Da type.id jetzt ein Integer ist, überprüfen wir den Typ direkt
            return categoryIds.includes(location.type.id);
          });
        }

        return filtered;
      },


      paginatedLocations() {
        const start = (this.currentPage - 1) * this.itemsPerPage;
        const end = start + this.itemsPerPage;

        return this.filteredLocations.slice(start, end); // Noch nicht gruppieren, nur Paginieren
      },

      locationsGrouped() {
        // Gruppiere die paginierten Trucks
        let grouped = [];
        this.groupArrays(this.paginatedLocations, grouped, 2);
        return grouped;
      },

      totalPages() {
        return Math.ceil(this.filteredLocations.length / this.itemsPerPage);
      }
    },

    //---------------------------------------------------
    //
    //  Watch Properties
    //
    //---------------------------------------------------
    watch: {
      mainFilter: {
        handler(newValue) {
          //console.log(newValue);
         if(this.view ==='map'){
            setTimeout(() => {
              if(this.filteredLocations.length > 0){
                this.calculateMapCenterAndZoom(this.filteredLocations);
              }
              //console.log('Mapcenter und Zoom neu berechnen');
            }, 1000);
            
            this.activeMarker = null;
            
          }

          if (newValue.text || newValue.region || newValue.categories) {
            localStorage.setItem('publicLocationsFilter', JSON.stringify(this.mainFilter));
            if(this.filterActions > 0)
            {
             this.currentPage = 1;
            }
            this.filterActions ++;
          }
        },
        deep: true // Tiefes Watching aktivieren
      },
      view(){
        localStorage.setItem('publicLocationsView', JSON.stringify(this.view));
      },
      currentPage(){
        localStorage.setItem('publicLocationsPage', this.currentPage);
      }
    },
    //---------------------------------------------------
    //
    //  Filter Properties
    //
    //---------------------------------------------------
    // filters: {},
    //---------------------------------------------------
    //
    //  Validation Properties
    //
    //---------------------------------------------------
    // validations: {},
    //---------------------------------------------------
    //
    //  Vue Lifecycle
    //
    //---------------------------------------------------
    // beforeCreate() {},
    // created() {},
     beforeMount() {
      const publicLocationsFilter = localStorage.getItem('publicLocationsFilter');
        if (publicLocationsFilter) {
            const parsedFilter = JSON.parse(publicLocationsFilter);
            this.mainFilter = parsedFilter;
        }
      const publicLocationsView = localStorage.getItem('publicLocationsView');
        if (publicLocationsView) {
            const parsedView = JSON.parse(publicLocationsView);
            this.view = parsedView;
        }
      const publicLocationsPage = localStorage.getItem('publicLocationsPage');
        if (publicLocationsPage) {
           
            this.currentPage = parseInt(publicLocationsPage, 10);
        }
      window.removeEventListener('storage', this.checkThemeChange);
     },
    // render(h) { return h(); },
    mounted() {
      this.getLocations();
      window.addEventListener('storage', this.checkThemeChange);
    },
    // beforeUpdate() {},
    // updated() {},
    // beforeDestroy() {},
    // destroyed() {},
    //---------------------------------------------------
    //
    //  Methods
    //
    //---------------------------------------------------
    methods: {
        // Seitenwechsel
      changePage(page) {
        if (page > 0 && page <= this.totalPages) {
          this.currentPage = page;
        }
      },
       /* ******** GET LOCATIONS ******** */
       async getLocations(){
        this.loading = true;
        let request = {
          
        };
        let response;
        response = await this.apiData('PublicLocationsView.vue getTrucks()','post','get-locations', request);
        this.locations = response;
        this.locationsGrouped = [];
        //this.groupArrays(response, this.trucksGrouped, 2);
        this.loading = false;
      },
      groupArrays(originalArray, groupedArray, groupCount) {
        for (let i = 0; i < originalArray.length; i += groupCount) {
          groupedArray.push(originalArray.slice(i, i + groupCount));
        }
      },
      removeFilterBadge(filter, x){
        if(filter === 'region'){
          this.mainFilter.region.splice(x, 1);
        }
        if(filter === 'categories'){
          this.mainFilter.categories.splice(x, 1);
        }
        
      },
      ////////////////MAPBOX
      handleListClick(x, position){


      if (this.activeMarker === x) {
      this.activeMarker = null; // Schließe das Popup, wenn derselbe Marker erneut geklickt wird
      } else {
      this.activeMarker = x; // Öffne das Popup für den neuen Marker
      }

      this.map.flyTo({
        center: position, // LngLat-Koordinaten für das Zentrum
        essential: true, // Animation auf allen Geräten
        zoom: 10 // Optional: Zoom-Level festlegen
      });
      },
      checkThemeChange() {
        setTimeout(() => {
          const newTheme = localStorage.getItem('theme');
          let newMapboxTheme;
          console.log('Neues Thema: ' + newTheme);

          // Aktualisiere den Mapbox-Stil entsprechend dem neuen Theme
          if (newTheme === 'light') {
            newMapboxTheme = 'mapbox://styles/mapbox/light-v11';
          } else if (newTheme === 'dark') {
            newMapboxTheme = 'mapbox://styles/mapbox/dark-v11';
          }

          // Setze das neue Mapbox-Theme
          if (this.map && this.map._canvas) {
            this.map.setStyle(newMapboxTheme); // Kartenstil aktualisieren
            this.map.resize(); // Falls nötig, Karte neu rendern
            //console.log('New Mapbox Theme: ' + newMapboxTheme);
          } else {
            //console.warn('Map or canvas not initialized yet');
          }
        }, 100); // Timeout von 100ms
      },
      onPopupClose(index) {
        if (this.activeMarker === index) {
            this.activeMarker = null;
          // this.calculateMapCenterAndZoom(this.markers);
            this.map.flyTo({
              center: this.mapCenter, // LngLat-Koordinaten für das Zentrum
              essential: true, // Animation auf allen Geräten
              zoom: this.zoom // Optional: Zoom-Level festlegen
            });
          }
      },
      initializeMap(mapboxInstance) {
          // Überprüfe, ob die Mapbox-Instanz korrekt übergeben wird
        
          this.map = mapboxInstance; // Speichere die Mapbox-Instanz
          this.map.on('load', () => {
            //console.log('Map is fully loaded');
          });
          //console.log('map instantz');
          //console.log(this.map);
          this.calculateMapCenterAndZoom(this.locations);
      },
      /* ################################# CALCULATE MAP CENTER AND ZOOM FOR LOCATIONS ################################# */
     

calculateMapCenterAndZoom(locations) {
  let totalLat = 0;
  let totalLng = 0;
  let totalPoints = 0;

  let minLat = Infinity;
  let maxLat = -Infinity;
  let minLng = Infinity;
  let maxLng = -Infinity;

  // Durchlaufe alle Locations
  locations.forEach(location => {
    const lat = parseFloat(location.lat);
    const lng = parseFloat(location.long);

    if (!isNaN(lat) && !isNaN(lng)) {
      totalLat += lat;
      totalLng += lng;
      totalPoints++;

      // Berechne die Bounding Box
      if (lat < minLat) minLat = lat;
      if (lat > maxLat) maxLat = lat;
      if (lng < minLng) minLng = lng;
      if (lng > maxLng) maxLng = lng;
    }
  });

  // Berechne den Mittelpunkt, falls Punkte vorhanden sind
  if (totalPoints > 0) {
    const centerLat = totalLat / totalPoints;
    const centerLng = totalLng / totalPoints;
    this.mapCenter = [centerLng, centerLat]; // Setze das Kartenzentrum
    //const localCenter =  [centerLng, centerLat];
    //console.log([centerLng, centerLat]);
  } else {
   this.mapCenter = [null, null]; // Falls keine gültigen Koordinaten vorhanden sind
  }

  
  

  // Setze das Kartenzentrum und den Zoom
  if (this.map && totalPoints > 0) {
   
    
    const bounds = [[minLng, minLat], [maxLng, maxLat]];
    this.map.fitBounds(bounds, { padding: 80, maxZoom: 14 }); // Passt die Karte an, um alle Polygone sichtbar zu machen
    //this.zoom = this.map.getZoom(); 


    setTimeout(() => {
      //this.mapCenter = this.map.getCenter();
      //this.mapCenter = localCenter;
      this.zoom = this.map.getZoom(); // Den richtigen Zoomwert nach dem FitBounds-Aufruf holen
      //console.log(`Zoom: ${this.zoom}, Center: ${this.mapCenter}`);

      


    }, 3000);
   
    
    //console.log('minLat '+minLat);
    //console.log('maxLat '+maxLat);
    //console.log('minLng '+minLng);
    //console.log('maxLng '+maxLng);

  }
},



/* ################################# CALCULATE MAP CENTER AND ZOOM FOR LOCATIONS END ################################# */

    },
  };
</script>
<style lang="scss">

</style>