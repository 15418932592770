<template>
    <div>
      
      <!-- FILTER -->
      <div class="row">
        <div class="col-md-12">
          <div class="card">
          <div class="card-header"><h5>{{ $t('card.title.filter') }}</h5></div>
          <div class="card-body">
              
              <div class="row">
              
              <div class="col-md-4" style="margin-bottom:0 !important">
                <div class="form-floating mb-3">
                  <input v-model="mainFilter.text" placeholder="suchen" class="form-control" id="tableSearch">
                  <label for="tableSearch" class="form-label" >{{ $t('form.label.search') }}</label>
                </div>
              </div>
              <div class="col-md-4"  >
                <CategoriesDropdown 
                    :apiData="apiData"
                    v-model="mainFilter.categories"
                    :class="{'is-invalid': errors.categories }"
                    :searchable="true"
                    type="foodtruck"
                    />
              </div>
              <div class="col-md-4"  style="margin-bottom:0 !important">
                <RegionDropdown 
                    :apiData="apiData"
                    v-model="mainFilter.region"
                    :class="{'is-invalid': errors.region }"
                    :requestRegion="requestRegion"
                    :searchable="true"
                    />
              </div>
              <div class="col-md-12"  style="margin-bottom:0 !important">
                <template v-if="mainFilter.text">
                  <span class="badge bg-secondary" style="margin-right:5px">{{mainFilter.text}} <font-awesome-icon icon="fa-solid fa-xmark"  style="cursor: pointer;" @click="mainFilter.text = ''" /></span>&nbsp;
                </template>
                <template v-for="(item, x) in mainFilter.categories" :key='x' >
                  <span class="badge bg-secondary" style="margin-right:5px">{{item.name}} <font-awesome-icon icon="fa-solid fa-xmark"  style="cursor: pointer;" @click="removeFilterBadge('categories', x)" /></span>&nbsp;
                </template>
                <template v-for="(item, x) in mainFilter.region" :key='x' >
                  <span class="badge bg-secondary" style="margin-right:5px">{{item.name}} <font-awesome-icon icon="fa-solid fa-xmark"  style="cursor: pointer;" @click="removeFilterBadge('region', x)" /></span>&nbsp;
                </template>
                
              </div>
            </div>
            
          </div>
        </div>
        </div>
      </div>
      <!-- FILTER END -->
      <!-- PAGINATION NAV -->
      <nav aria-label="Page navigation" v-if="trucksGrouped.length > 0">
        <ul class="pagination">
          <li class="page-item" :class="{ disabled: currentPage === 1 }">
            <a class="page-link" href="#" @click.prevent="changePage(currentPage - 1)">{{ $t('button.previous') }}</a>
          </li>
          <li class="page-item" v-for="page in totalPages" :key="page" :class="{ active: currentPage === page }">
            <a class="page-link" href="#" @click.prevent="changePage(page)">{{ page }}</a>
          </li>
          <li class="page-item" :class="{ disabled: currentPage === totalPages }">
            <a class="page-link" href="#" @click.prevent="changePage(currentPage + 1)">{{ $t('button.next') }}</a>
          </li>
        </ul>
      </nav>
      <!-- PAGINATION NAV END -->
      
      <!-- TRUCKS CARDS -->
      <div class="row" v-for="(item, x) in trucksGrouped" :key='x'>
        <div class="col-md-6 d-flex" v-for="(subitem, y) in item" :key='y' style="margin-bottom:0 !important">
          <!-- CARD -->
          <div class="card mb-3 truckCard d-flex" style="width:100%; cursor: pointer;" @click="$router.push('/truck/'+subitem.id)">
            <div class="row h-100 d-flex" style="margin-left: 0 !important; ">
              <div class="col-4 truckCardImg d-flex" v-if="subitem.default_img" :style="{ backgroundImage:'url('+config.storageURL+subitem.owner+'/img/'+subitem.default_img.name+')' }">
               
              </div>
              <div class="col-4 truckCardImg d-flex" v-else :style="{ backgroundImage: `url(${require('@/assets/default_truck.png')})` }" >

              </div>
              <div class="col-8 d-flex flex-column" style="margin-bottom: 0 !important; padding-left:0">
                <div class="card-body">
                  <h5 class="card-title">{{ subitem.name }}</h5>
                    <p class="card-text">
                      <span class="badge bg-secondary" v-for="(categorie, z) in subitem.categories" :key='z' style="margin-right:5px">{{categorie.name}}</span>&nbsp;
                    </p>
                    <p class="card-text"><small class="text-body-secondary">{{ $t('form.label.region') }}: <span  class="region" v-for="(region, i) in subitem.region" :key='i'>{{ region.short }}</span></small></p>
                </div>
              </div>
            </div>
          </div>
          <!-- END CARD -->
        </div>
      </div>
      <!-- TRUCKS CARDS END -->
      <!-- PAGINATION NAV -->
      <nav aria-label="Page navigation" v-if="trucksGrouped.length > 0">
        <ul class="pagination">
          <li class="page-item" :class="{ disabled: currentPage === 1 }">
            <a class="page-link" href="#" @click="changePage(currentPage - 1)">{{ $t('button.previous') }}</a>
          </li>
          <li class="page-item" v-for="page in totalPages" :key="page" :class="{ active: currentPage === page }">
            <a class="page-link" href="#" @click="changePage(page)">{{ page }}</a>
          </li>
          <li class="page-item" :class="{ disabled: currentPage === totalPages }">
            <a class="page-link" href="#" @click="changePage(currentPage + 1)">{{ $t('button.next') }}</a>
          </li>
        </ul>
      </nav>
      <!-- PAGINATION NAV END -->
    </div>
</template>
<script>
 import RegionDropdown from '@/components/RegionDropdownV2extended.vue';
 import CategoriesDropdown from '@/components/CategoriesDropdownV2extended.vue';
 
  export default {
    name: '${COMPONENT_NAME}',
    components: {
      RegionDropdown,
      CategoriesDropdown
    },
    //---------------------------------------------------
    //
    //  Properties
    //
    //---------------------------------------------------
    props: {
      apiData: {type: Function},
      config:{required:true}
    },
    //---------------------------------------------------
    //
    //  Data model
    //
    //---------------------------------------------------
    data() {
      return {
        filterActions:0,
        itemsPerPage: 10,           // Anzahl der Items pro Seite
        currentPage: 1,             // Aktuelle Seite
        errors:{},
        mainFilter:{
          text:'',
          region:[],
          categories:[]
        },
        trucks:[],
        //trucksGrouped:[]
      };
    },
    //---------------------------------------------------
    //
    //  Computed Properties
    //
    //---------------------------------------------------
    computed: {
      preFiltered() {
        const searchText = this.mainFilter.text.toLowerCase();

        // Funktion, um alle Werte eines Objekts, auch verschachtelte, als String zusammenzuführen
        const flattenObjectValues = (obj) => {
          return Object.values(obj).map(value => {
            if (typeof value === 'object' && value !== null) {
              return flattenObjectValues(value);
            }
            return String(value);
          }).join(""); // Verknüpfe alle Werte zu einem String
        };

        // Filter anwenden, basierend auf 'searchText'
        const preFiltered = this.trucks.filter(truck => {
          return searchText === ""
            ? true
            : flattenObjectValues(truck).toLowerCase().includes(searchText);
        });

        return preFiltered;
      },

      filteredTrucks() {
        let filtered = this.preFiltered; // Keine Klammern!

        // Region-Filter
        if (this.mainFilter.region.length > 0) {
          const regionIds = this.mainFilter.region.map(region => region.id);
          filtered = filtered.filter(truck => {
            // Prüfen, ob eine Übereinstimmung in den regionID Arrays vorhanden ist
            return truck.regionID.some(regionId => regionIds.includes(regionId));
          });
        }

        // Kategorien-Filter
        if (this.mainFilter.categories.length > 0) {
          const categoryIds = this.mainFilter.categories.map(category => category.id);
          filtered = filtered.filter(truck => {
            // Prüfen, ob eine Übereinstimmung in den categoriesID Arrays vorhanden ist
            return truck.categoriesID.some(categoryId => categoryIds.includes(categoryId));
          });
        }

        return filtered;
      },

      paginatedTrucks() {
        const start = (this.currentPage - 1) * this.itemsPerPage;
        const end = start + this.itemsPerPage;

        return this.filteredTrucks.slice(start, end); // Noch nicht gruppieren, nur Paginieren
      },

      trucksGrouped() {
        // Gruppiere die paginierten Trucks
        let grouped = [];
        this.groupArrays(this.paginatedTrucks, grouped, 2);
        return grouped;
      },

      totalPages() {
        return Math.ceil(this.filteredTrucks.length / this.itemsPerPage);
      }
    },

    //---------------------------------------------------
    //
    //  Watch Properties
    //
    //---------------------------------------------------
    watch: {
      mainFilter: {
        handler(newValue) {  
        
          if (newValue.text || newValue.region || newValue.categories) {
            localStorage.setItem('publicTrucksFilter', JSON.stringify(this.mainFilter));
            if(this.filterActions > 0)
            {
             this.currentPage = 1;
            }
            this.filterActions ++;
          }
        },
        deep: true // Tiefes Watching aktivieren
      },
      currentPage(){
        localStorage.setItem('publicTruckPage', this.currentPage);
      }
    },
    //---------------------------------------------------
    //
    //  Filter Properties
    //
    //---------------------------------------------------
    // filters: {},
    //---------------------------------------------------
    //
    //  Validation Properties
    //
    //---------------------------------------------------
    // validations: {},
    //---------------------------------------------------
    //
    //  Vue Lifecycle
    //
    //---------------------------------------------------
    // beforeCreate() {},
    // created() {},
     beforeMount() {
      const publicTrucksFilter = localStorage.getItem('publicTrucksFilter');
        if (publicTrucksFilter) {
            const parsedFilter = JSON.parse(publicTrucksFilter);
            this.mainFilter = parsedFilter;
        }
      const publicTrucksPage = localStorage.getItem('publicTrucksPage');
        if (publicTrucksPage) {
           
            this.currentPage = parseInt(publicTrucksPage, 10);
        }
     },
    // render(h) { return h(); },
    mounted() {
      this.getTrucks();
    },
    // beforeUpdate() {},
    // updated() {},
    // beforeDestroy() {},
    // destroyed() {},
    //---------------------------------------------------
    //
    //  Methods
    //
    //---------------------------------------------------
    methods: {
        // Seitenwechsel
      changePage(page) {
        if (page > 0 && page <= this.totalPages) {
          this.currentPage = page;
        }
      },
       /* ******** GET TRUCKS ******** */
       async getTrucks(){
        let request = {
          
        };
        let response;
        response = await this.apiData('ProfileView.vue getTrucks()','post','get-trucks', request);
        this.trucks = response;
        this.trucksGrouped = [];
        //this.groupArrays(response, this.trucksGrouped, 2);
      },
      groupArrays(originalArray, groupedArray, groupCount) {
        for (let i = 0; i < originalArray.length; i += groupCount) {
          groupedArray.push(originalArray.slice(i, i + groupCount));
        }
      },
      removeFilterBadge(filter, x){
        if(filter === 'region'){
          this.mainFilter.region.splice(x, 1);
        }
        if(filter === 'categories'){
          this.mainFilter.categories.splice(x, 1);
        }
        
      },
    },
  };
</script>
<style lang="scss">
</style>