<template>
  <div v-if="userData">
  
    <nav class="navbar navbar-expand-lg bg-body-tertiary">
      <div class="container-fluid">
        <a class="navbar-brand" href="#" @click.prevent="$router.push('/')">
          <img src="../assets/ftl_logo_neg.png" v-if="colorTheme === 'dark'">
          <img src="../assets/ftl_logo_pos.png" v-if="colorTheme === 'light'">
        </a>
        <div class="d-lg-none d-flex align-items-center ms-auto">
          <!-- Notification Button for Mobile -->
          <div class="notificationBtn text-secondary me-2" :data-bs-toggle="newNotifications > 0 ? 'dropdown' : null" aria-expanded="false">
            <font-awesome-icon icon="fa-solid fa-bell" />
            <span class="badge rounded-pill bg-danger notificationBadge" v-if="newNotifications > 0">
              {{ newNotifications }}
            </span>
          </div>
          <!-- User Chip for Mobile -->
          <div :class="{'userChip': true, 'has-scrollbar': hasScrollbar }" v-if="isAuthenticated" @click="displayUserNavigation = !displayUserNavigation">
            <div class="userChipIcn">
              <font-awesome-icon icon="fa-solid fa-user" v-if="!userData.user_details.logo" />
              <img alt="Logo" :src="config.storageURL+userData.uuid+'/img/'+userData.user_details.logo" v-else />
            </div>
          </div>
          <!-- Navbar Toggler for Mobile -->
          <button class="navbar-toggler" type="button"  data-bs-target="#navbarSupportedContentX" aria-controls="navbarSupportedContentX" aria-expanded="false" aria-label="Toggle navigation"  @click="toggleNavbar">
            <span class="navbar-toggler-icon"></span>
          </button>
        </div>

        <div class="collapse navbar-collapse" id="navbarSupportedContentX">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            <template v-for="(item, x) in mainNavigation" :key="x">
              <li class="nav-item" v-if="item.display">
                <a href="#" @click.prevent="mainNav(item, x, 'mainNavigation')" :class="{'nav-link':true, 'active': item.active}">{{ item.name }}</a>
              </li>
            </template>
          </ul>
          <div class="btn-group dropstart">
            <div class="notificationBtn text-secondary" :data-bs-toggle="newNotifications > 0 ? 'dropdown' : null" aria-expanded="false" style="margin-right:10px">
              <font-awesome-icon icon="fa-solid fa-bell" />
              <span class="badge rounded-pill bg-danger notificationBadge" v-if="newNotifications > 0">
                {{ newNotifications }}
              </span>
            </div>
            
            <div class="dropdown-menu" style="padding: 0; min-width: 400px;">
              <div class="card">
                <div class="card-body" style="max-height: 400px; overflow-y: auto; padding: 0">
                  <div class="list-group list-group-flush">
                    <template v-for="(item, x) in notifications" :key="x">
                      <a href="#" class="list-group-item list-group-item-action" v-if="item.read === 0" @click="notificationAction(item)">
                        <div class="d-flex w-100 justify-content-between">
                          <h5 class="mb-1">{{ item.title }}</h5>
                        </div>
                        <p class="mb-1">{{ item.text }}</p>
                        <small class="text-body-secondary">{{ formatDate(item.created_at) }}</small>
                      </a>
                    </template>
                  </div>
                </div>
                <div class="card-footer">
                  <a href="#" @click="notificationReadAll()">{{ $t('button.readAll') }}</a>
                </div>
              </div>
            </div>
          </div>
          
          <!-- User Chip for Desktop -->
          <div :class="{'userChip':true, 'ms-3':true}" v-if="isAuthenticated" @click="displayUserNavigation = !displayUserNavigation">
            <div class="userChipIcn">
              <font-awesome-icon icon="fa-solid fa-user" v-if="!userData.user_details.logo" />
              <img alt="Logo" :src="config.storageURL + userData.uuid + '/img/' + userData.user_details.logo" v-else />
            </div>
            <div class="userChipName">{{ userData.name }}</div>
          </div>
        </div>
      </div>
    </nav>

    <!-- User Navigation Dropdown -->
    <div class="card userNavigation" v-if="displayUserNavigation" v-click-outside="closeUserNavigation">
      <ul class="list-group list-group-flush">
        <li class="list-group-item" @click.prevent="$router.push('/profil'); displayUserNavigation =false" style="cursor: pointer"><font-awesome-icon icon="fa-solid fa-address-card"  /> {{ $t('button.profile') }}</li>
        <li class="list-group-item" @click="$router.push('/settings');  displayUserNavigation =false" style="cursor: pointer"><font-awesome-icon icon="fa-solid fa-gear"  /> {{ $t('button.settings') }}</li>
        <li class="list-group-item" @click="logout" style="cursor: pointer"><font-awesome-icon icon="fa-solid fa-right-from-bracket"  /> {{ $t('button.logout') }}</li>
      </ul>
    </div>
  </div>
</template>

<script>
 import { Collapse } from 'bootstrap';
  import dayjs from 'dayjs';
  import { useIntervalFn } from '@vueuse/core';
  export default {
    name: 'MainHeader',
    components: {
    },
    //---------------------------------------------------
    //
    //  Properties
    //
    //---------------------------------------------------
    props: {
      isAuthenticated:{required: true},
      userData:{required: true},
      config:{required: true},
      setLoginStatus:{type: Function},
      resetUserData:{type: Function},
      apiData:{type: Function},
      colorTheme:{required:true}
    },
    //---------------------------------------------------
    //
    //  Data model
    //
    //---------------------------------------------------
    data() {
      return {
        hasScrollbar: false,
        newNotifications:0,
        notifications:[],
        displayUserNavigation:false,
        mainNavigation:[
          {
            name:this.$t('nav.public.home'),
            active:true,
            url:'/',
            display:true
          },
          {
            name:this.$t('nav.private.dashboard'),
            active:true,
            url:'/dashboard',
            display:true
          },
          {
            name:this.$t('nav.private.profile'),
            active:false,
            url:'/profil',
            display:true
          },
          {
            name:this.$t('nav.private.offert'),
            active:false,
            url:'/offerts',
            display:true
          },
          {
            name:this.$t('nav.private.booking'),
            active:false,
            url:'/booking',
            display:true
          },
          {
            name:this.$t('nav.private.invoice'),
            active:false,
            url:'/invoices',
            display:true
          },
          {
            name:this.$t('nav.public.foodtrucks'),
            active:false,
            url:'/trucks',
            display:true
          },
          {
            name:this.$t('nav.public.locations'),
            active:false,
            url:'/locations',
            display:true
          },
          {
            name:this.$t('nav.private.sandbox'),
            active:false,
            url:'/sandbox',
            display:true
          },
         
        ],
        loginNavigation:[
        {
            name:this.$t('nav.login.register'),
            active:false,
            url:'/register'
          },
          {
            name:this.$t('nav.login.login'),
            active:false,
            url:'/login'
          },
          
        ]
      };
    },
    //---------------------------------------------------
    //
    //  Computed Properties
    //
    //---------------------------------------------------
    computed: {
     
    },
    //---------------------------------------------------
    //
    //  Watch Properties
    //
    //---------------------------------------------------
    watch: {
      $route(){
        this.setActive();
      }
    },
    //---------------------------------------------------
    //
    //  Filter Properties
    //
    //---------------------------------------------------
    // filters: {},
    //---------------------------------------------------
    //
    //  Validation Properties
    //
    //---------------------------------------------------
    // validations: {},
    //---------------------------------------------------
    //
    //  Vue Lifecycle
    //
    //---------------------------------------------------
    // beforeCreate() {},
    // created() {},
    async beforeMount() {
     
      useIntervalFn(() => {
      this.getNotifications();
    }, 60000);
    },
    // render(h) { return h(); },
    async mounted() {
      for(let i = 0 ; i < this.mainNavigation.length; i++){
        this.mainNavigation[i].active = false;
        if(this.mainNavigation[i].url === window.location.pathname){
          this.mainNavigation[i].active = true;
        }
      }
      this.getNotifications();
      if(this.userData.role === 'trucker'){
        this.mainNavigation[3].display = false;
        this.mainNavigation[5].display = false;
        this.mainNavigation[8].display = false;
      }
      if(this.userData.role === 'location'){
        this.mainNavigation[4].display = false;
        this.mainNavigation[8].display = false;
      }
      
   
    },
    // beforeUpdate() {},
     updated() {
      //this.mainNavigation[1].display = this.isAuthenticated;
     },
    // beforeDestroy() {},
    // destroyed() {},
    //---------------------------------------------------
    //
    //  Methods
    //
    //---------------------------------------------------
    methods: {
      setActive() {
        const path = this.$route.path;

        // Hauptnavigation aktiv setzen
        for (let i = 0; i < this.mainNavigation.length; i++) {
          const navUrl = this.mainNavigation[i].url;

          // Spezielle Prüfung für Home "/"
          if (navUrl === '/' && path === '/') {
            this.mainNavigation[i].active = true;
          } 
          // Behandlung für "/trucks" und "/truck/123"
          else if (navUrl === '/trucks' && (path.startsWith('/trucks') || path.startsWith('/truck'))) {
            this.mainNavigation[i].active = true;
          }
          // Behandlung für "/locations" und "/location/333"
          else if (navUrl === '/locations' && (path.startsWith('/locations') || path.startsWith('/location'))) {
            this.mainNavigation[i].active = true;
          } 
          // Standardprüfung für andere URLs
          else if (navUrl !== '/' && path.startsWith(navUrl)) {
            this.mainNavigation[i].active = true;
          } 
          else {
            this.mainNavigation[i].active = false;
          }
        }

        // Login-Navigation aktiv setzen
        for (let i = 0; i < this.loginNavigation.length; i++) {
          if (path.startsWith(this.loginNavigation[i].url)) {
            this.loginNavigation[i].active = true;
          } else {
            this.loginNavigation[i].active = false;
          }
        }
      },
      formatDate(dateString) {
        return dayjs.utc(dateString).local().format('DD.MM.YY HH:mm');
      },
      async notificationAction(item){
        let request = {
          selectorTable:'id',
          selectorValue: item.id
        };
        let response;
        response = await this.apiData('MainHeader.vue notificationAction()','post','update-notifications', request);
        console.log(response);
        this.$router.push(item.url);
        this.getNotifications();
      },
      async notificationReadAll(){
        let request = {
          selectorTable:'owner',
          selectorValue: this.userData.uuid
        };
        let response;
        response = await this.apiData('MainHeader.vue notificationReadAll()','post','update-notifications', request);
        console.log(response);
        this.getNotifications();
      },
      /* ******** GET NOTIFICATIONS ******** */
      async getNotifications(){
        
        let request = {
          selectorTable:'owner',
          selectorValue: this.userData.uuid
        };
        let response;
        response = await this.apiData('MainHeader.vue getNotifications()','post','get-notifications', request);
        this.notifications = response;
        this.newNotifications = 0;
        for(let i = 0; i < response.length; i++){
          if(response[i].read === 0){
            this.newNotifications ++;
          }
          
        }
       
        
      },
       /* ******** GET NOTIFICATIONS END ******** */
     
      mainNav(item, x, navType){
        const navbarToggler = document.querySelector('.navbar-collapse');
      if (navbarToggler.classList.contains('show')) {
        const bsCollapse = new Collapse(navbarToggler, {
          toggle: true
        });
        bsCollapse.hide(); // Manually hide the collapsed menu
      }


        for(let i = 0 ; i < this[navType].length; i++) {
            this[navType][i].active =false;
        }
        this[navType][x].active = true;
        this.$router.push(item.url);
        this.checkScrollbar;
      },
      closeUserNavigation(){
        
        if(this.displayUserNavigation === true){
          this.displayUserNavigation =false;
        }
      },
      toggleNavbar() {
  const navbarToggler = document.querySelector("#navbarSupportedContentX");
  const bsCollapse = new Collapse(navbarToggler, {
    toggle: false,
  });

  //console.log('Navbar class list:', navbarToggler.classList); // Überprüfe die Klassenliste des Togglers

  // Prüfe explizit auf die Klasse 'show'
  if (navbarToggler.classList.contains("show")) {
    
    bsCollapse.hide();
  } else {
    
    bsCollapse.show();
  }
},


      logout(){
        localStorage.removeItem('access_token');
        localStorage.removeItem('userUUID');
        this.setLoginStatus(false);
        this.resetUserData();
        this.closeUserNavigation();
        this.$toast.open({
              message: this.$t('toast.logout'),
              type: 'success',
              duration:3000,
            });
        this.$router.push('/');
      }
    },
  };
</script>
<style lang="scss">
  .notificationBadge{
    position: absolute;
    margin-top:-8px;
    margin-left:-10px;

  }
 .notificationBtn{
  cursor: pointer;
        margin-right:0;
      }
      .notificationBtn svg{
        font-size: 24px;
      }


    .userChip {
        margin-right: 10px;
}
     
</style>