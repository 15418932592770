<template>
    <div  v-if="loading === false">
     
      <!-- FILTER -->
      <div class="row">
        <div class="col-md-12">
          <div class="card">
          <div class="card-header"><h5>{{$t('card.title.filter')}}</h5></div>
          <div class="card-body">
              
              <div class="row">
                <div class="col-md-2" style="margin-bottom:0 !important">
                  <div class="form-floating mb-3" >
                    <flat-pickr              
                      :config="filterPickerStart"
                      class="form-control"
                      placeholder="Von"
                      id="filterVon"
                      name="von"
                      v-model="mainFilter.von"
                    />
                    <label for="filterVon">{{$t('form.label.from')}}</label>
                  </div>
                </div>
                <div class="col-md-2" style="margin-bottom:0 !important">
                  <div class="form-floating mb-3" >
                    <flat-pickr              
                      :config="filterPickerEnd"
                      class="form-control"
                      placeholder="Bis"
                      id="filterBis"
                      name="bis"
                      v-model="mainFilter.bis"
                    />
                    <label for="filterBis">{{$t('form.label.to')}}</label>
                  </div>
                </div>
              
             
              <div class="col-md-3"  >
                <CategoriesDropdown 
                    :apiData="apiData"
                    v-model="mainFilter.categories"
                    :class="{'is-invalid': errors.categories }"
                    :searchable="true"
                    type="foodtruck"
                    />
              </div>
              <div class="col-md-3"  style="margin-bottom:0 !important">
                <RegionDropdown 
                    :apiData="apiData"
                    v-model="mainFilter.region"
                    :class="{'is-invalid': errors.region }"
                    :requestRegion="requestRegion"
                    :searchable="true"
                    />
              </div>
              <div class="col-md-2" style="margin-bottom:0 !important">
                <div class="form-floating mb-3">
                  <input v-model="mainFilter.text" placeholder="suchen" class="form-control" id="tableSearch">
                  <label for="tableSearch" class="form-label" >{{$t('form.label.search')}}</label>
                </div>
              </div>
              <div class="col-md-12"  style="margin-bottom:0 !important">
                <template v-if="mainFilter.von">
                  <span class="badge bg-secondary" style="margin-right:5px">{{$t('form.label.from')}}: {{mainFilter.von}} <font-awesome-icon icon="fa-solid fa-xmark"  style="cursor: pointer;" @click="removeFilterBadge('von')" /></span>&nbsp;
                </template>
                <template v-if="mainFilter.bis">
                  <span class="badge bg-secondary" style="margin-right:5px">{{$t('form.label.to')}}: {{mainFilter.bis}} <font-awesome-icon icon="fa-solid fa-xmark"  style="cursor: pointer;" @click="removeFilterBadge('bis')" /></span>&nbsp;
                </template>
                <template v-if="mainFilter.text">
                  <span class="badge bg-secondary" style="margin-right:5px">{{mainFilter.text}} <font-awesome-icon icon="fa-solid fa-xmark"  style="cursor: pointer;" @click="mainFilter.text = ''" /></span>&nbsp;
                </template>
                <template v-for="(item, x) in mainFilter.categories" :key='x' >
                  <span class="badge bg-secondary" style="margin-right:5px">{{item.name}} <font-awesome-icon icon="fa-solid fa-xmark"  style="cursor: pointer;" @click="removeFilterBadge('categories', x)" /></span>&nbsp;
                </template>
                <template v-for="(item, x) in mainFilter.region" :key='x' >
                  <span class="badge bg-secondary" style="margin-right:5px">{{item.name}} <font-awesome-icon icon="fa-solid fa-xmark"  style="cursor: pointer;" @click="removeFilterBadge('region', x)" /></span>&nbsp;
                </template>
                
              </div>
            </div>
            
          </div>
        </div>
        </div>
      </div>
      <!-- FILTER END -->





      <!-- MAP -->

      <div class="row d-flex">
          <div class="col-md-4">
            <div class="card h-100">
              <div class="card-header"><h5>{{$t('card.title.locations')}}</h5></div>
              <div class="card-body" style="padding:0; max-height: 400px; overflow-y: auto;" ref="scrollContainer">
                <div v-if="markers.length === 0" style="margin:15px">
                  {{$t('card.body.noLocations')}}
                </div>
                <div class="mapDetails list-group list-group-flush" v-else>
                  <template   v-for="(item, index) in filteredMarkers" :key="index">
                    <a href="#" :class="{'list-group-item':true, 'list-group-item-action':true, 'active': activeMarker === index}" aria-current="true" @click.prevent="handleListClick(index, [item.long, item.lat])" >
                      <div class="d-flex w-100 justify-content-between">
                        <h5 class="mb-1">{{ item.name }}</h5>
                      </div>
                      <p class="mb-1">{{ item.street }}, {{ item.zip }} {{ item.city }}, {{ item.region }}</p>
                    </a>
                </template>          
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-8">
            <div class="card h-100">
              <div class="card-body ">
            
                <MapboxMap 
                  @mb-created="initializeMap"
                  access-token="pk.eyJ1Ijoic2JlbmN6ZSIsImEiOiJjbTFiYXVrY3YwbWY0MmpzYm0wamc0bDhiIn0.R_6w0DsjbSGASOg2Snz8Ng"
                  style="min-height: 400px"
                  :center="mapCenterDefault"
                  class="h-100"
                  :map-style="mapboxTheme"
                  :zoom="zoom"
                  :scrollZoom="false"
                >
                <!-- REGIONEN -->
                <!-- <template   v-for="(item, x) in mapOverlay" :key='x'>
                  <MapboxSource :id="'route'+x" :options="item.routeSource"></MapboxSource>
                  <MapboxLayer :id="'routesource'+x" :options="item.routeLayer" />
                  <MapboxLayer :id="'outline'+x" :options="item.routeLayerOutline" />
                </template>  -->
                <!-- REGIONEN END -->
                <MapboxNavigationControl position="top-left" :showCompass="false" />
               <!-- LOCATIONS -->
                  <template v-for="(item, x) in filteredMarkers" :key="x">
                    <!-- Marker mit Popup aktiviert -->
                    <MapboxMarker :lng-lat="[item.long, item.lat]"  color="#f9b233" ref="mapMarkers"  > 
                    <template v-slot:popup>
                    <MapboxPopup :lng-lat="[item.long, item.lat]" v-if="activeMarker === x" @mb-close="onPopupClose(x)">
                      <h5 @click="$router.push('/location/'+item.id)">{{ item.name }}</h5>
                      <div v-for="(trucks, x) in item.trucks" :key="x">
                        <h6 @click="$router.push('/truck/'+trucks.id)">{{ trucks.name }}</h6>
                        <ul class="list-group list-group-flush">
                          <li v-for="(date, y) in trucks.dates" :key="y" class="list-group-item" style="white-space:nowrap;">
                            {{formatDate(date.start) }} - {{ formatDate(date.end) }}
                          </li>
                        </ul>
                      </div>
                    </MapboxPopup>
                      </template>
                      <template v-slot:default >
                      <div  
                        class="mapboxgl-marker mapboxgl-marker-anchor-center"
                        style="position: absolute; transform: translate(-50%, -100%);"
                        @click.stop="handleListClick(x, [item.long, item.lat])"
                        >
                        <svg display="block" height="41px" width="27px" viewBox="0 0 27 41"><defs><radialGradient id="shadowGradient"><stop offset="10%" stop-opacity="0.4"></stop><stop offset="100%" stop-opacity="0.05"></stop></radialGradient></defs><ellipse cx="13.5" cy="34.8" rx="10.5" ry="5.25" fill="url(#shadowGradient)"></ellipse><path fill="#f9b233" d="M27,13.5C27,19.07 20.25,27 14.75,34.5C14.02,35.5 12.98,35.5 12.25,34.5C6.75,27 0,19.22 0,13.5C0,6.04 6.04,0 13.5,0C20.96,0 27,6.04 27,13.5Z"></path><path opacity="0.25" d="M13.5,0C6.04,0 0,6.04 0,13.5C0,19.22 6.75,27 12.25,34.5C13,35.52 14.02,35.5 14.75,34.5C20.25,27 27,19.07 27,13.5C27,6.04 20.96,0 13.5,0ZM13.5,1C20.42,1 26,6.58 26,13.5C26,15.9 24.5,19.18 22.22,22.74C19.95,26.3 16.71,30.14 13.94,33.91C13.74,34.18 13.61,34.32 13.5,34.44C13.39,34.32 13.26,34.18 13.06,33.91C10.28,30.13 7.41,26.31 5.02,22.77C2.62,19.23 1,15.95 1,13.5C1,6.58 6.58,1 13.5,1Z"></path><circle fill="white" cx="13.5" cy="13.5" r="5.5"></circle></svg>
                      </div>
                    </template> 
                    <!-- Popup-Inhalt END -->
                    </MapboxMarker>
                  </template>
                  <!-- LOCATIONS END -->
                </MapboxMap>
              </div>
            </div>
          </div>
        </div>
        <!-- MAP END -->

      

    </div>
</template>
<script>
import flatPickr from 'vue-flatpickr-component';
 import RegionDropdown from '@/components/RegionDropdownV2extended.vue';
 import CategoriesDropdown from '@/components/CategoriesDropdownV2extended.vue';
import dayjs from 'dayjs';
//import { MapboxMap,  MapboxLayer,  MapboxSource, MapboxMarker, MapboxPopup } from '@studiometa/vue-mapbox-gl';
import { MapboxMap,  MapboxMarker, MapboxPopup, MapboxNavigationControl } from '@studiometa/vue-mapbox-gl';
//import { ref } from 'vue';
  export default {
    name: '${COMPONENT_NAME}',
    components: {
      MapboxMap,  MapboxMarker, MapboxPopup, //MapboxLayer,  MapboxSource,
      CategoriesDropdown,
      RegionDropdown,
      flatPickr,
      MapboxNavigationControl

    },
    //---------------------------------------------------
    //
    //  Properties
    //
    //---------------------------------------------------
    props: {
      apiData:{type:Function},
      config:{requireed: true}
    },
    //---------------------------------------------------
    //
    //  Data model
    //
    //---------------------------------------------------
    data() {
      return {
        errors:{},
        mainFilter:{
          text:'',
          region:[],
          categories:[],
          von:null,
          bis:null,
        },
        activeMarker:null,
        loading:true,
        locations:[],
        markers:[],
        mapCenter:[8.2311, 46.8083],
        mapCenterDefault:[8.2311, 46.8083],
        
        
        map: null,
        zoom:null,
        filterPickerStart:{
          dateFormat: 'd.m.Y H:i',
          enableTime: true,
          time_24hr:true,
          altInput: false, // Wichtig: Verhindert die Verwendung eines separaten Anzeige-Inputs
          defaultDate: null, // Kein Standarddatum, um das Floating Label korrekt zu behandeln
          minDate: new Date().fp_incr(0)
          
        }, 
        filterPickerEnd:{
          dateFormat: 'd.m.Y H:i',
          enableTime: true,
          time_24hr:true,
          altInput: false, // Wichtig: Verhindert die Verwendung eines separaten Anzeige-Inputs
          defaultDate: null, // Kein Standarddatum, um das Floating Label korrekt zu behandeln
          minDate: new Date().fp_incr(0)
          
        }, 
      };
    },
    //---------------------------------------------------
    //
    //  Computed Properties
    //
    //---------------------------------------------------
    computed: {
     
      mapboxTheme(){
        let value
        if(localStorage.getItem('theme') === 'light'){
          value ='mapbox://styles/mapbox/light-v11';
        }
        if(localStorage.getItem('theme') === 'dark'){
          value ='mapbox://styles/mapbox/dark-v11';
        }
        return value;
      },
      preFiltered() {
        const searchText = this.mainFilter.text.toLowerCase();

        // Funktion, um alle Werte eines Objekts, auch verschachtelte, als String zusammenzuführen
        const flattenObjectValues = (obj) => {
          return Object.values(obj).map(value => {
            if (typeof value === 'object' && value !== null) {
              return flattenObjectValues(value);
            }
            return String(value);
          }).join(""); // Verknüpfe alle Werte zu einem String
        };

        // Filter anwenden, basierend auf 'searchText'
        const preFiltered = this.markers.filter(truck => {
          return searchText === ""
            ? true
            : flattenObjectValues(truck).toLowerCase().includes(searchText);
        });

        return preFiltered;
      },
     
      filteredMarkers() {
        let filtered = this.preFiltered; // Keine Klammern hier!

        // Region-Filter
        if (this.mainFilter.region.length > 0) {
          const regionIds = this.mainFilter.region.map(region => region.id);
          filtered = filtered.filter(location => {
            // Prüfen, ob die regionID der Location in den ausgewählten Regionen enthalten ist
            return regionIds.includes(location.regionID);
          });
        }

        // Kategorien-Filter
        if (this.mainFilter.categories.length > 0) {
          const categoryIds = this.mainFilter.categories.map(category => category.id);
          filtered = filtered.map(location => {
            // Erstelle ein neues Array für gefilterte Trucks
            const filteredTrucks = location.trucks.filter(truck => {
              return truck.categoriesID.some(categoryId => categoryIds.includes(categoryId));
            });
            // Nur Locations mit mindestens einem Truck in der gewählten Kategorie behalten
            return {
              ...location, // Behalte die anderen Eigenschaften der Location
              trucks: filteredTrucks // Überschreibe das Trucks-Array mit dem gefilterten Array
            };
          }).filter(location => location.trucks.length > 0); // Entferne Locations ohne Trucks
        }

        // Datums-Filter
        if (this.mainFilter.von || this.mainFilter.bis) {
          const von = this.mainFilter.von ? this.parseFilterDate(this.mainFilter.von) : null;
          const bis = this.mainFilter.bis ? this.parseFilterDate(this.mainFilter.bis) : null;

          filtered = filtered.map(location => {
            // Filtere Trucks basierend auf ihren Dates
            const filteredTrucks = location.trucks.filter(truck => {
              const filteredDates = truck.dates.filter(date => {
                const start = this.convertUTCToLocal(date.start);
                const end = this.convertUTCToLocal(date.end);

                // Fall 1: Sowohl 'von' als auch 'bis' gesetzt
                if (von && bis) {
                  return start.isSameOrAfter(von) && end.isSameOrBefore(bis);
                }

                // Fall 2: Nur 'von' gesetzt
                if (von) {
                  return start.isSameOrAfter(von);
                }

                // Fall 3: Nur 'bis' gesetzt
                if (bis) {
                  return end.isSameOrBefore(bis);
                }

                return true; // Kein 'von' oder 'bis' gesetzt
              });

              // Nur Trucks behalten, die mindestens ein gültiges Date haben
              return filteredDates.length > 0;
            });

            // Nur Locations mit mindestens einem Truck behalten
            return {
              ...location, // Behalte die anderen Eigenschaften der Location
              trucks: filteredTrucks // Überschreibe das Trucks-Array mit dem gefilterten Array
            };
          }).filter(location => location.trucks.length > 0); // Entferne Locations ohne Trucks
        }

        return filtered;
      }


    },
    //---------------------------------------------------
    //
    //  Watch Properties
    //
    //---------------------------------------------------
    watch: {
      mainFilter: {
        handler(newValue) {
          /*this.map.flyTo({
            center: this.mapCenter, // LngLat-Koordinaten für das Zentrum
            essential: true, // Animation auf allen Geräten
            zoom: this.zoom // Optional: Zoom-Level festlegen
          });*/
         
          setTimeout(() => {
            if(this.filteredMarkers.length > 0){
              this.calculateMapCenterAndZoom(this.filteredMarkers);
            }
            //console.log('Mapcenter und Zoom neu berechnen');
          }, 1000);
          
          this.activeMarker = null;
          
          if (newValue.von) {
            this.filterPickerEnd.minDate = newValue.von;
            //console.log('filterende gesetzt');
          }
        },
        deep: true // Tiefes Watching aktivieren
      }
    },
    //---------------------------------------------------
    //
    //  Filter Properties
    //
    //---------------------------------------------------
    // filters: {},
    //---------------------------------------------------
    //
    //  Validation Properties
    //
    //---------------------------------------------------
    // validations: {},
    //---------------------------------------------------
    //
    //  Vue Lifecycle
    //
    //---------------------------------------------------
    // beforeCreate() {},
    // created() {},
     beforeMount() {
      window.removeEventListener('storage', this.checkThemeChange);
     },
    // render(h) { return h(); },
     async mounted() {
      this.getLocationsData();
      window.addEventListener('storage', this.checkThemeChange);
     },
    // beforeUpdate() {},
    // updated() {},
    // destroyed() {},
    //---------------------------------------------------
    //
    //  Methods
    //
    //---------------------------------------------------
    methods: {
      checkThemeChange() {
        setTimeout(() => {
          const newTheme = localStorage.getItem('theme');
          let newMapboxTheme;
          //console.log('Neues Thema: ' + newTheme);

          // Aktualisiere den Mapbox-Stil entsprechend dem neuen Theme
          if (newTheme === 'light') {
            newMapboxTheme = 'mapbox://styles/mapbox/light-v11';
          } else if (newTheme === 'dark') {
            newMapboxTheme = 'mapbox://styles/mapbox/dark-v11';
          }

          // Setze das neue Mapbox-Theme
          if (this.map && this.map._canvas) {
            this.map.setStyle(newMapboxTheme); // Kartenstil aktualisieren
            this.map.resize(); // Falls nötig, Karte neu rendern
            //console.log('New Mapbox Theme: ' + newMapboxTheme);
          } else {
            //console.warn('Map or canvas not initialized yet');
          }
        }, 100); // Timeout von 100ms
      },

      removeFilterBadge(filter, x){
        if(filter === 'von'){
          this.mainFilter.von = null;
        }
        if(filter === 'bis'){
          this.mainFilter.bis = null;
        }
        if(filter === 'region'){
          this.mainFilter.region.splice(x, 1);
        }
        if(filter === 'categories'){
          this.mainFilter.categories.splice(x, 1);
        }
        
      },
      convertUTCToLocal(dateString) {
    return dayjs.utc(dateString).local(); // Konvertiere von UTC in lokale Zeit
  },

  // Parsen von `mainFilter.von` und `mainFilter.bis` im korrekten Format (DD.MM.YYYY)
  parseFilterDate(dateString) {
    const format = 'DD.MM.YYYY HH:mm'; // Verwende das strikte TT.MM.JJJJ-Format
    return dayjs(dateString, format, true); // Striktes Parsing
  },

  handleListClick(x, position) {
    if (this.activeMarker === x) {
        this.activeMarker = null; // Schließe das Popup, wenn derselbe Marker erneut geklickt wird
    } else {
        this.activeMarker = x; // Öffne das Popup für den neuen Marker
    }

    // Stelle sicher, dass das DOM aktualisiert ist, bevor gescrollt wird
    this.$nextTick(() => {
        const activeElement = this.$el.querySelector(".list-group-item.active");
        if (activeElement) {
            const scrollContainer = this.$refs.scrollContainer;

            // Optional: Finde die Höhe des Headers (falls vorhanden)
            const headerElement = this.$el.querySelector(".card-header");
            const headerHeight = headerElement ? headerElement.offsetHeight : 0;

            // Berechne die Scroll-Position relativ zum Container
            const elementOffsetTop = activeElement.offsetTop;

            // Stelle sicher, dass das Scrollen korrekt funktioniert
            scrollContainer.scrollTo({
                top: elementOffsetTop - headerHeight,
                behavior: "smooth",
            });
        }
    });

    // Fliege zur neuen Position auf der Karte
    this.map.flyTo({
        center: position, // LngLat-Koordinaten für das Zentrum
        essential: true, // Animation auf allen Geräten
        zoom: 10 // Optional: Zoom-Level festlegen
    });
},



onPopupClose(index) {
if (this.activeMarker === index) {
    this.activeMarker = null;
   // this.calculateMapCenterAndZoom(this.markers);
    this.map.flyTo({
      center: this.mapCenter, // LngLat-Koordinaten für das Zentrum
      essential: true, // Animation auf allen Geräten
      zoom: this.zoom // Optional: Zoom-Level festlegen
    });
  }
},
      getNewZoom(){
       this.zoom = this.map.getZoom()
      },
      /* ################################# GET DB DATA ################################# */
      async getLocationsData(){
        this.loading = true;
         //GET LOCATIONS
         let requestLocations = {
        };
        let responseLocations;
        responseLocations = await this.apiData('MasterMap.vue getLocationsData()','post','get-confirmed-future-locations', requestLocations);
        this.locations = responseLocations;
        this.markers = this.createMarketObject(responseLocations);
        
        this.loading = false;
      },
       /* ################################# GET DB DATA END ################################# */
       /* ################################# REFORMAT DB DATA ################################# */
      createMarketObject(responseLocations) {
          const markerMap = {}; // Verwende ein Objekt, um Locations nach ihrer ID zu gruppieren
          responseLocations.forEach(location => {
              const locId = location.location.id;
              const truckId = location.truck.id;

              // Prüfen, ob die Location-ID bereits existiert
              if (!markerMap[locId]) {
                  // Wenn die Location noch nicht existiert, füge sie hinzu
                  markerMap[locId] = {
                      id: location.location.id,
                      name: location.location.name,
                      lat: location.location.lat,
                      long: location.location.long,
                      street: location.location.street,
                      zip: location.location.zip,
                      city: location.location.city,
                      region: location.location.region.name,
                      regionID: location.location.region.id,
                      trucks: []
                  };
              }

              // Überprüfen, ob der Truck bereits für die Location existiert
              let truckExists = markerMap[locId].trucks.find(truck => truck.id === truckId);

              // Wenn der Truck noch nicht existiert, füge ihn hinzu
              if (!truckExists) {
                  truckExists = {
                      id: location.truck.id,
                      name: location.truck.name,
                      categoriesID: location.truck.categories,
                      dates: []
                  };
                  markerMap[locId].trucks.push(truckExists);
              }

              // Füge die Start- und End-Daten in das "dates"-Array des Trucks ein
              truckExists.dates.push({
                  start: location.start,
                  end: location.end
              });
          });

          // Umwandeln des markerMap-Objekts in ein Array, das zurückgegeben wird
          return Object.values(markerMap);
      },
      /* ################################# REFORMAT DB DATA END ################################# */
      /* ################################# HANDLE DATES ################################# */
      getNextDate(datesArray) {
        const today = dayjs(); // Aktuelles Datum
        let closestDate = null;

        // Gehe durch alle Daten und finde das nächste Datum in der Zukunft
        datesArray.forEach(item => {
          const startDate = dayjs(item.start); // Startzeitpunkt in Day.js
          if (startDate.isAfter(today)) {
            // Wenn kein Datum gesetzt ist oder das gefundene Datum näher an heute liegt, setze es
            if (closestDate === null || startDate.isBefore(closestDate)) {
              closestDate = startDate;
            }
          }
        });

        return closestDate ? closestDate.format('YYYY-MM-DD HH:mm:ss') : null;
      },
      formatDate(dateString) {
        return dayjs.utc(dateString).local().format('DD.MM.YY HH:mm');
      },
      /* ################################# HANDLE DATES END ################################# */


      initializeMap(mapboxInstance) {
      // Überprüfe, ob die Mapbox-Instanz korrekt übergeben wird
     
      this.map = mapboxInstance; // Speichere die Mapbox-Instanz
      this.map.on('load', () => {
        //console.log('Map is fully loaded');
      });
      //console.log('map instantz');
      //console.log(this.map);
      this.calculateMapCenterAndZoom(this.markers);
      //this.map.flyTo({ center: [8.2311, 46.8083], zoom: this.zoom });
    },




      /* ################################# CALCULATE MAP CENTER AND ZOOM FOR LOCATIONS ################################# */
     

calculateMapCenterAndZoom(locations) {
  let totalLat = 0;
  let totalLng = 0;
  let totalPoints = 0;

  let minLat = Infinity;
  let maxLat = -Infinity;
  let minLng = Infinity;
  let maxLng = -Infinity;

  // Durchlaufe alle Locations
  locations.forEach(location => {
    const lat = parseFloat(location.lat);
    const lng = parseFloat(location.long);

    if (!isNaN(lat) && !isNaN(lng)) {
      totalLat += lat;
      totalLng += lng;
      totalPoints++;

      // Berechne die Bounding Box
      if (lat < minLat) minLat = lat;
      if (lat > maxLat) maxLat = lat;
      if (lng < minLng) minLng = lng;
      if (lng > maxLng) maxLng = lng;
    }
  });

  // Berechne den Mittelpunkt, falls Punkte vorhanden sind
  if (totalPoints > 0) {
    const centerLat = totalLat / totalPoints;
    const centerLng = totalLng / totalPoints;
    this.mapCenter = [centerLng, centerLat]; // Setze das Kartenzentrum
    //const localCenter =  [centerLng, centerLat];
    //console.log([centerLng, centerLat]);
  } else {
   this.mapCenter = [null, null]; // Falls keine gültigen Koordinaten vorhanden sind
  }

  
  

  // Setze das Kartenzentrum und den Zoom
  if (this.map && totalPoints > 0) {
   
    
    const bounds = [[minLng, minLat], [maxLng, maxLat]];
    this.map.fitBounds(bounds, { padding: 80, maxZoom: 14 }); // Passt die Karte an, um alle Polygone sichtbar zu machen
    //this.zoom = this.map.getZoom(); 


    setTimeout(() => {
      //this.mapCenter = this.map.getCenter();
      //this.mapCenter = localCenter;
      this.zoom = this.map.getZoom(); // Den richtigen Zoomwert nach dem FitBounds-Aufruf holen
      //console.log(`Zoom: ${this.zoom}, Center: ${this.mapCenter}`);

      


    }, 3000);
   
    
    //console.log('minLat '+minLat);
    //console.log('maxLat '+maxLat);
    //console.log('minLng '+minLng);
    //console.log('maxLng '+maxLng);

  }
},



/* ################################# CALCULATE MAP CENTER AND ZOOM FOR LOCATIONS END ################################# */



     

    },
  };
</script>
<style lang="scss">
</style>