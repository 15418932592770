<template>
  <div>
      <h1 class="text-center mb-5">Foodtruck Locator für Standortanbieter</h1>

      <div class="row">
          <div class="col-md-6">
              <h2 class="mb-4">Erziele zusätzliche Einnahmen mit Deiner Fläche.</h2>
              <p>Nutze Deine Parkflächen, Veranstaltungen oder ungenutzten Aussenflächen, um zusätzliches Geld zu verdienen, indem Du diese Foodtrucks zur Verfügung stellst. Egal ob Du ein KMU, Eventveranstalter oder Immobilienunternehmen bist – als Standortanbieter auf <strong>Foodtruck Locator</strong> hast Du die Möglichkeit, Deine Flächen entweder zu einem Fixpreis oder auf Auktionsbasis anzubieten – Du hast die volle Kontrolle über die Preisgestaltung!</p>
              <p>Du entscheidest selbst, ob Du die Flächen wöchentlich oder für spezielle Events vermieten möchtest. Profitiere von der Flexibilität und steigere Deine Einnahmen durch die Nutzung Deiner Flächen.</p>
          </div>
          <div class="col-md-6" :style="{ backgroundImage: `url(${require('../assets/teaser_location.jpg')})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center center', minHeight: '300px' }">


          </div>
      </div>

      <div class="row mt-5 d-flex">
          <div class="col-md-12">
              <h3 class="mb-4 text-center">Deine Vorteile auf einen Blick</h3>
          </div>
          <div class="col-md-4">
              <div class="card text-center h-100">
                  <div class="card-body">
                      <h5 class="card-title">Maximiere Deine Einnahmen</h5>
                      <p class="card-text">Verwandle ungenutzte Flächen in eine zuverlässige Einnahmequelle und biete Deinen Gästen oder Mitarbeitenden zusätzlichen Mehrwert. Egal ob Du ein KMU, Eventveranstalter oder Immobilienunternehmen bist – profitiere mit minimalem Aufwand.</p>                   
                    </div>
              </div>
          </div>
          <div class="col-md-4">
              <div class="card text-center h-100">
                  <div class="card-body">
                      <h5 class="card-title">Flexibel und Unkompliziert</h5>
                      <p class="card-text">Biete Deine Flächen zu einem Fixpreis an oder lasse Foodtrucks per Auktion bieten. Die Nutzung der Plattform ist kostenlos – erst bei einer erfolgreichen Buchung fällt eine Provision von 10% an. So nutzt Du die Plattform risikofrei und bietest gleichzeitig mehr Verpflegungsmöglichkeiten vor Ort.</p>
                  </div>
              </div>
          </div>
          <div class="col-md-4">
              <div class="card text-center h-100">
                  <div class="card-body">
                      <h5 class="card-title">Volle Kontrolle über Deine Angebote</h5>
                      <p class="card-text">Du behältst die Kontrolle darüber, welche Foodtrucks Deine Flächen nutzen und wie Du sie bewirbst. Jede Buchung lässt sich in Echtzeit verfolgen, sodass Du stets den Überblick behältst und Deine Gäste oder Mitarbeitenden einen zusätzlichen Vorteil genießen können.</p>
                  </div>
              </div>
          </div>
      </div>

      <div class="row mt-5 d-flex">
          <div class="col-md-6">
              <h3>So funktioniert's</h3>
              <ol class="list-group list-group-flush list-group-numbered">
                  <li class="list-group-item d-flex">
                      <div style="margin-left:5px">Melde Dich kostenlos auf unserer Plattform an und erstelle Dein Profil.</div>
                  </li>
                  <li class="list-group-item d-flex">
                      <div style="margin-left:5px">Stelle Deine Flächen mit allen relevanten Informationen online – bestimme Preise und Verfügbarkeiten selbst.</div>
                  </li>
                  <li class="list-group-item d-flex">
                      <div style="margin-left:5px">Verfolge Deine Buchungen im Kalender und erhalte automatische Bestätigungen bei jeder Buchung. So behältst Du jederzeit den Überblick.</div>
                  </li>
                  <li class="list-group-item d-flex">
                      <div style="margin-left:5px">Lass Deine Flächen von Foodtruck-Betreibern nutzen und erziele zusätzliche Einnahmen, während Deine Gäste oder Mitarbeitenden eine größere Auswahl an Verpflegungsmöglichkeiten genießen.</div>
                  </li>
              </ol>
          </div>
          <div class="col-md-6">
              <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/ntVbzjaJ8Y4?si=xvQPSapPrjdjWEmS" allowfullscreen width="100%" height="100%"></iframe>
          </div>
      </div>

      <div class="row mt-5">
          <div class="col-md-12">
              <div class="alert alert-primary text-center" role="alert">
                  <h4 class="alert-heading">Wir helfen Dir persönlich.</h4>
                  <p>Du hast Fragen oder benötigst Unterstützung bei den ersten Schritten? Kontaktiere uns über das <a href="#" class="alert-link" @click="$router.push('/contact')">Kontaktformular</a>. Wir begleiten Dich gerne bei der Einrichtung Deines Profils und den ersten Buchungen.</p>
              </div>
          </div>
      </div>

      <div class="row mt-5">
          <div class="col-md-12 text-center">
              <h3>Jetzt registrieren und durchstarten.</h3>
              <p class="lead">Nutze diese Chance und verwandle Deine Fläche in eine zusätzliche Einnahmequelle.</p>
              <a href="#" class="btn btn-primary btn-lg" @click="$router.push('/register')">Jetzt registrieren</a>
          </div>
      </div>
  </div>
</template>


<script>
  export default {
    name: '${COMPONENT_NAME}',
    components: {
    },
    //---------------------------------------------------
    //
    //  Properties
    //
    //---------------------------------------------------
    props: {},
    //---------------------------------------------------
    //
    //  Data model
    //
    //---------------------------------------------------
    data() {
      return {};
    },
    //---------------------------------------------------
    //
    //  Computed Properties
    //
    //---------------------------------------------------
    computed: {},
    //---------------------------------------------------
    //
    //  Watch Properties
    //
    //---------------------------------------------------
    watch: {},
    //---------------------------------------------------
    //
    //  Filter Properties
    //
    //---------------------------------------------------
    // filters: {},
    //---------------------------------------------------
    //
    //  Validation Properties
    //
    //---------------------------------------------------
    // validations: {},
    //---------------------------------------------------
    //
    //  Vue Lifecycle
    //
    //---------------------------------------------------
    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // render(h) { return h(); },
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // beforeDestroy() {},
    // destroyed() {},
    //---------------------------------------------------
    //
    //  Methods
    //
    //---------------------------------------------------
    methods: {},
  };
</script>
<style lang="scss">
</style>