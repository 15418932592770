<template>
  <div>
    <SortableTable 
    :colums = "colums"
    :rows = "invoices"
    :itemsPerPage = "itemsCount"
    :initialSortColumn = "sortBy"
    :sortDir = "sortDir"
    :userData="userData"
    :openFunction="openFunction"
    :downloadFunction="downloadFunction"
    v-if="loading === false"
    />


    
  </div>
</template>
<script>
import dayjs from 'dayjs';
import SortableTable from '@/components/SortableTable';
export default {
  name: '${COMPONENT_NAME}',
  components: {
    SortableTable,
  },
  //---------------------------------------------------
  //
  //  Properties
  //
  //---------------------------------------------------
  props: {
    config:{required:true},
    userData:{required:true},
    apiData:{type: Function}
  },
  //---------------------------------------------------
  //
  //  Data model
  //
  //---------------------------------------------------
  data() {
    return {
     
      pdfContent:null,
      loading:true,
      invoices:[],
      itemsCount: 10,
      sortBy:"created_at",
      sortDir:'desc',
      colums:[
      {
          name:'Datum',
          value:'created_at',
          valueReadable:'created_at_readable',
          type:'date',
          visible:true
        },
        {
          name:'Rechnungsnr.',
          value:'billingnr',
          type:'text',
          visible:true
        },
        {
          name:'Status',
          value:'status',
          type:'status',
          visible:true
        },
        {
          name:'Fällig am',
          value:'due',
          valueReadable:'due_readable',
          type:'date',
          visible:true
        },
        {
          name:'Betrag',
          value:'amount',
          valueReadable:'due',
          type:'text',
          visible:true
        },
      ],
    };
  },
  //---------------------------------------------------
  //
  //  Computed Properties
  //
  //---------------------------------------------------
  computed: {},
  //---------------------------------------------------
  //
  //  Watch Properties
  //
  //---------------------------------------------------
  watch: {},
  //---------------------------------------------------
  //
  //  Filter Properties
  //
  //---------------------------------------------------
  // filters: {},
  //---------------------------------------------------
  //
  //  Validation Properties
  //
  //---------------------------------------------------
  // validations: {},
  //---------------------------------------------------
  //
  //  Vue Lifecycle
  //
  //---------------------------------------------------
  // beforeCreate() {},
  // created() {},
  // beforeMount() {},
  // render(h) { return h(); },
   mounted() {
    this.getInvoices();
    
   },
  // beforeUpdate() {},
  // updated() {},
  // beforeDestroy() {},
  // destroyed() {},
  //---------------------------------------------------
  //
  //  Methods
  //
  //---------------------------------------------------
  methods: {
    
     formatCreated(dateString) {
      return dayjs(dateString).format('DD.MM.YYYY');
    },
    openFunction(item){
      
      this.pdfContent = this.config.storageURL+this.userData.uuid+'/invoice/'+item.file;
      
      this.uniqueModal.show();
    },
    downloadFunction(item){
      const link = document.createElement('a');
      link.href = this.config.storageURL+this.userData.uuid+'/invoice/'+item.file;
      link.setAttribute('download', '');
      link.click();
    },
    async getInvoices(){
      this.loading=true;
      let request = {
        selectorTable:'owner',
        selectorValue: this.userData.uuid
      };
      let response;
      response = await this.apiData('InvoicesView.vue getInvoices()','post','get-invoices', request);
      
      for(let i = 0; i < response.length; i++){
        
        response[i].created_at_readable = this.formatCreated(response[i].created_at)
        response[i].due_readable = this.formatCreated(response[i].due)
      }
      this.invoices = response;
      this.loading=false;
      
      
    },
  },
};
</script>
<style lang="scss">
</style>