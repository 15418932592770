<template>
    <!-- LOGIN & REGISTER PANEL -->
          <div class="card">
            
            <div class="card-header">
              <h5>{{ $t('card.title.notifications') }}</h5>
            </div>
            <div class="card-body" style="height:400px; overflow-y: auto;">
              
            <ol class="list-group list-group-flush listTimeTable" v-if="notifications.length > 0">
                <li class="list-group-item d-flex flex-column align-items-start" v-for="(item, x) in notifications" :key='x' @click="notificationAction(item)" style="cursor:pointer">
                  <div class="ms-2 me-auto" >
                    <div class="fw-bold" >
                      {{item.title}}
                      <span class="badge text-bg-success" v-if="item.read === 0">Neu</span>
                      <span class="badge text-bg-secondary" v-if="item.read === 1">Gelesen</span>
                    </div>
                    {{ item.text }}
                  </div>
                  <div class="ms-2 me-auto">
                  <small> {{formatDate(item.created_at)}}</small>
                  </div>
                  

                </li>
              </ol>
              <p v-else>{{ $t('card.body.noNotifications') }}</p>
              
            </div>    
          </div>
      <!-- LOGIN & REGISTER PANEL END -->
</template>
<script>
  import dayjs from 'dayjs';
  export default {
    name: '${COMPONENT_NAME}',
    components: {
    },
    //---------------------------------------------------
    //
    //  Properties
    //
    //---------------------------------------------------
    props: {
      notifications: {required:true},
      apiData:{type: Function}
    },
    //---------------------------------------------------
    //
    //  Data model
    //
    //---------------------------------------------------
    data() {
      return {
        selectedStatus:'gebucht'
      };
    },
    //---------------------------------------------------
    //
    //  Computed Properties
    //
    //---------------------------------------------------
    computed: {
      
    },
    //---------------------------------------------------
    //
    //  Watch Properties
    //
    //---------------------------------------------------
    watch: {},
    //---------------------------------------------------
    //
    //  Filter Properties
    //
    //---------------------------------------------------
    // filters: {},
    //---------------------------------------------------
    //
    //  Validation Properties
    //
    //---------------------------------------------------
    // validations: {},
    //---------------------------------------------------
    //
    //  Vue Lifecycle
    //
    //---------------------------------------------------
    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // render(h) { return h(); },
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // beforeDestroy() {},
    // destroyed() {},
    //---------------------------------------------------
    //
    //  Methods
    //
    //---------------------------------------------------
    methods: {
      formatDate(dateString) {
        return dayjs.utc(dateString).local().format('DD.MM.YY HH:mm');
      },
      async notificationAction(item){
        if(item.read === 0){
          let request = {
            selectorTable:'id',
            selectorValue: item.id
          };
          let response;
          response = await this.apiData('MainHeader.vue notificationAction()','post','update-notifications', request);
          console.log(response);
        }
        this.$router.push(item.url);
      },
    },
  };
</script>
<style lang="scss">
</style>