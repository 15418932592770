<template>
    <div class="row d-flex">
      <!-- TRUCKS -->
      <div class="col-md-3 col-6">
        <div  class="card h-100">
          <div class="card-body">
            <h6>{{ $t('card.title.trucks') }}</h6>
            <h2>{{ ownedTrucks.length }}/{{ trucks.length }}</h2>
          </div>
        </div>
      </div>
      <!-- TRUCKS END -->
      <!-- OFFERTS -->
      <div class="col-md-3 col-6">
        <div  class="card h-100">
          <div class="card-body">
            <h6>{{ $t('card.title.offerts') }}</h6>
            <h2>{{ offerts.length }}</h2>
          </div>
        </div>
      </div>
      <!-- OFFERTS END -->
      <!-- BOOKINGS -->
      <div class="col-md-3 col-6">
        <div  class="card h-100">
          <div class="card-body">
            <h6>{{ $t('card.title.bookings') }}</h6>
            <h2>{{ ownedBookingsTruck.length }}/{{ bookings.length }}</h2>
          </div>
        </div>
      </div>
      <!-- BOOKINGS END -->
      <!-- AUCTIONS -->
      <div class="col-md-3 col-6">
        <div  class="card h-100">
          <div class="card-body">
            <h6>{{ $t('card.title.auctions') }}</h6>
            <h2>{{ ownedAuctionsTruck.length }}/{{ auctions.length }}</h2>
          </div>
        </div>
      </div>
      <!-- AUCTIONS END -->
    </div>
</template>
<script>
  export default {
    name: 'TruckMetricsWidget',
    components: {
    },
    //---------------------------------------------------
    //
    //  Properties
    //
    //---------------------------------------------------
    props: {
      trucks: {required:true},
      ownedTrucks: {required:true},
      offerts: {required:true},
      bookings: {required:true},
      ownedBookingsTruck: {required:true},
      auctions: {required:true},
      ownedAuctionsTruck: {required:true},
    },
    //---------------------------------------------------
    //
    //  Data model
    //
    //---------------------------------------------------
    data() {
      return {};
    },
    //---------------------------------------------------
    //
    //  Computed Properties
    //
    //---------------------------------------------------
    computed: {},
    //---------------------------------------------------
    //
    //  Watch Properties
    //
    //---------------------------------------------------
    watch: {},
    //---------------------------------------------------
    //
    //  Filter Properties
    //
    //---------------------------------------------------
    // filters: {},
    //---------------------------------------------------
    //
    //  Validation Properties
    //
    //---------------------------------------------------
    // validations: {},
    //---------------------------------------------------
    //
    //  Vue Lifecycle
    //
    //---------------------------------------------------
    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // render(h) { return h(); },
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // beforeDestroy() {},
    // destroyed() {},
    //---------------------------------------------------
    //
    //  Methods
    //
    //---------------------------------------------------
    methods: {},
  };
</script>
<style lang="scss">
</style>