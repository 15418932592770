<template>
    <div>
      <div class="modal" id="myModal" data-bs-backdrop="static" >
        <div class="modal-dialog modal-dialog-centered   modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-header text-center">
              <h5 class="modal-title">{{ $t('modal.title.emailVerification') }}</h5>
            </div>
            <div class="modal-body text-center">
              <p class="card-text">{{ $t('card.body.emailVerification') }}</p>
              <h6 class="card-title">{{ $t('card.title.emailVerification') }}</h6>
              <br />
              <v-otp-input
                style="display: flex;justify-content: center;"
                ref="otpInput"
                input-classes="otp-input"
                :conditionalClass="['one', 'two', 'three', 'four']"
                separator="-"
                inputType="letter-numeric"
                :num-inputs="4"
                v-model:value="bindValue"
                :should-auto-focus="true"
                :should-focus-order="true"
                @on-complete="handleOnComplete"
              />
              <br />
              <h5 class="text-danger" v-if="verifyError">{{ $t('error.emailVerification') }}</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
  import { Modal } from "bootstrap";
  import VOtpInput from "vue3-otp-input";
  export default {
    name: '${COMPONENT_NAME}',
    components: {
      VOtpInput
    },
    //---------------------------------------------------
    //
    //  Properties
    //
    //---------------------------------------------------
    props: {
      userData: {required: true},
      isAuthenticated: {required: true},
      isVerified: {required: true},
      authFunction: {type: Function} ,
      apiData: {type: Function} 
    },
    //---------------------------------------------------
    //
    //  Data model
    //
    //---------------------------------------------------
    data() {
      return {
        verifyError:false,
        bindValue:"",
        userDataLocal: this.userData,
      };
    },
    //---------------------------------------------------
    //
    //  Computed Properties
    //
    //---------------------------------------------------
    computed: {},
    //---------------------------------------------------
    //
    //  Watch Properties
    //
    //---------------------------------------------------
    watch: {},
    //---------------------------------------------------
    //
    //  Filter Properties
    //
    //---------------------------------------------------
    // filters: {},
    //---------------------------------------------------
    //
    //  Validation Properties
    //
    //---------------------------------------------------
    // validations: {},
    //---------------------------------------------------
    //
    //  Vue Lifecycle
    //
    //---------------------------------------------------
    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // render(h) { return h(); },
    mounted() {
      this.uniqueModal = new Modal(document.getElementById("myModal"),{ keyboard: false });
      this.uniqueModal.show();
    },
    // beforeUpdate() {},
    // updated() {},
    // beforeDestroy() {},
    // destroyed() {},
    //---------------------------------------------------
    //
    //  Methods
    //
    //---------------------------------------------------
    methods: {
      async handleOnComplete(){
      let response;
      this.userDataLocal = this.userData;
      this.userDataLocal.code = this.bindValue;
      response = await this.apiData('Login.vue login()','post','auth/veryficate', this.userDataLocal);
      if(response === 1){
        this.uniqueModal.hide();
        this.authFunction();
        //console.log('eingabe richtig');
        this.verifyError = false;
      }else{
        //console.log('eingabe falsch')
        this.verifyError = true; 
      }     
    },
    },
  };
</script>
<style lang="scss">
</style>