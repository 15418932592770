<template>
          <div class="card">
            
            <div class="card-header">
              <ul class="nav nav-tabs card-header-tabs">
                <li class="nav-item">
                  <a :class="{'nav-link':true,  'active': selectedStatus==='gebucht' }" aria-current="true" href="#" @click.prevent="selectedStatus = 'gebucht'">{{ $t('tab.booked') }}</a>
                </li>
                <li class="nav-item">
                  <a :class="{'nav-link':true,  'active': selectedStatus ==='bestätigt' }" href="#" @click.prevent="selectedStatus = 'bestätigt'">{{ $t('tab.confirmed') }}</a>
                </li>
              </ul>
            </div>
            <div class="card-body" style="height:400px; overflow-y: auto;">
              <ol class="list-group list-group-flush listTimeTable" v-if="filteredOfferts.length > 0">
                  <li class="list-group-item d-flex justify-content-between align-items-start" v-for="(item, x) in filteredOfferts" :key='x' >
                    <div class="ms-2 me-auto" >
                      <div class="fw-bold" >{{formatDate(item.start)}} - {{formatDate(item.end)}}</div>
                      {{ item.location.name }}, {{ item.location.street }}, {{ item.location.city }}
                    </div>
                    <button type="button" class="btn btn-secondary " @click="$router.push('/truck/'+item.truck)"><font-awesome-icon icon="fa-solid fa-truck-front" style="margin-right:0" /></button>

                  </li>
                </ol>
                <p v-else>{{ $t('card.body.noBookings') }}</p>
              </div>
          
          </div>
</template>
<script>
  import dayjs from 'dayjs';
  export default {
    name: '${COMPONENT_NAME}',
    components: {
    },
    //---------------------------------------------------
    //
    //  Properties
    //
    //---------------------------------------------------
    props: {
      ownedBookingsLocation: {required:true},
    },
    //---------------------------------------------------
    //
    //  Data model
    //
    //---------------------------------------------------
    data() {
      return {
        selectedStatus:'bestätigt'
      };
    },
    //---------------------------------------------------
    //
    //  Computed Properties
    //
    //---------------------------------------------------
    computed: {
      filteredOfferts() {
        const now = dayjs(); // Aktuelle Zeit in lokalem Format
          // Wenn der Status 'gebucht', 'bestätigt' oder 'geboten' ist
          if (this.selectedStatus === 'gebucht' || this.selectedStatus === 'bestätigt') {
            return this.ownedBookingsLocation.filter(item => {
              const isFuture = dayjs.utc(item.start).local().isAfter(now); // Prüfen, ob das Start-Datum in der Zukunft liegt
              return item.status === this.selectedStatus && (isFuture); // Entweder in der Zukunft oder vom aktuellen Benutzer
            });
    
          }else{
            return null;
          }
      },
    },
    //---------------------------------------------------
    //
    //  Watch Properties
    //
    //---------------------------------------------------
    watch: {},
    //---------------------------------------------------
    //
    //  Filter Properties
    //
    //---------------------------------------------------
    // filters: {},
    //---------------------------------------------------
    //
    //  Validation Properties
    //
    //---------------------------------------------------
    // validations: {},
    //---------------------------------------------------
    //
    //  Vue Lifecycle
    //
    //---------------------------------------------------
    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // render(h) { return h(); },
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // beforeDestroy() {},
    // destroyed() {},
    //---------------------------------------------------
    //
    //  Methods
    //
    //---------------------------------------------------
    methods: {
      formatDate(dateString) {
        return dayjs.utc(dateString).local().format('DD.MM.YY HH:mm');
      },
    },
  };
</script>
<style lang="scss">
</style>