<template>
    <div>
      

     <!-- TEASERS -->
     <div class="row">
          <div class="col-md-6">
            <!-- CARD -->
            <div class="card mb-3 truckCard d-flex h-100" style="width:100%; cursor: pointer;" @click="$router.push('/ftl-for-locations') ">
              <div class="row h-100 d-flex" style="margin-left: 0 !important;"> 
                <div class="col-4 truckCardImg d-flex" :style="{ backgroundImage: `url(${require('@/assets/default_location.png')})` }" >

                </div>
                <div class="col-8 d-flex flex-column" style="margin-bottom: 0 !important; ">
                  <div class="card-body">
                    <h5 class="card-title">Für Standortanbieter</h5>
                      <p class="card-text">
                        Vermiete ungenutzte Flächen an Foodtrucks und generiere zusätzliche Einnahmen! Ob Fixpreis oder Auktion – Du bestimmst die Konditionen und profitierst von einer risikofreien Nutzung.
                      </p>
                      <button class="btn btn-primary" @click="$router.push('/ftl-for-locations')">Mehr erfahren</button>
                  </div>
                </div>
              </div>
            </div>
            <!-- END CARD -->
          </div>
          <div class="col-md-6">
            <!-- CARD -->
            <div class="card mb-3 truckCard d-flex h-100" style="width:100%; cursor: pointer;" @click="$router.push('/ftl-for-trucks')">
              <div class="row h-100 d-flex" style="margin-left: 0 !important;"> 
                <div class="col-4 truckCardImg d-flex" :style="{ backgroundImage: `url(${require('@/assets/default_truck.png')})` }" >

                </div>
                <div class="col-8 d-flex flex-column" style="margin-bottom: 0 !important; padding-left:0">
                  <div class="card-body">
                    <h5 class="card-title">Für Foodtruckbetreiber</h5>
                      <p class="card-text">
                        Entdecke neue Standorte und buche sie unkompliziert für Deinen Foodtruck. Nutze die Chance, Deine Reichweite zu vergrößern und mehr Kunden zu gewinnen!
                      </p>
                      <button class="btn btn-primary" @click="$router.push('/ftl-for-trucks')">Mehr erfahren</button>
                  </div>
                </div>
              </div>
            </div>
            <!-- END CARD -->
          </div>
        </div>
       <!-- TEASERS END -->
    
      <!-- MASTER MAP -->
        <MasterMap 
        :apiData="apiData"
        :config="config"
        />
      <!-- MASTER MAP END-->
      
     
      <!-- LOGIN & REGISTER PANEL -->
       <div class="row">
          <div class="col-md-12">
      
          <div class="card">
            
            <div class="card-header">
              <ul class="nav nav-tabs card-header-tabs">
                <li class="nav-item">
                  <a :class="{'nav-link':true,  'active': loginNav==='login' }" aria-current="true" href="#"  @click.prevent="loginNav = 'login'">Login</a>
                </li>
                <li class="nav-item">
                  <a :class="{'nav-link':true,  'active': loginNav==='register' }" href="#" @click.prevent="loginNav = 'register'">Registrieren</a>
                </li>
              </ul>
            </div>
            <div class="card-body">
              <div class="row" v-if="loginNav === 'login'">
                <div class="col-md-6" style="margin-bottom:0 !important">  
                  <h4>Login</h4>
                  <p>Willkommen zurück. Melde dich mit deinen Zugangsdaten an, um auf dein Konto zuzugreifen und alle Funktionen der Plattform zu nutzen.</p>
                  <p>Gib einfach deine E-Mail-Adresse und dein Passwort ein und klicke auf "Anmelden."</p>
              
                  <p>Falls du noch kein Konto hast, kannst du dich  <a href="#" @click.prevent="loginNav = 'register'">hier</a> registrieren.</p>
                </div>
                <div class="col-md-6"  style="margin-bottom:0 !important">
                  <Login 
                  :setLoginStatus="setLoginStatus"
                  :apiData="apiData"
                  :setUserData="setUserData" 
                  :authFunction="authFunction"
                  />
                </div>
              </div>
              <div class="row" v-if="loginNav === 'register'">
                <div class="col-md-6" style="margin-bottom:0 !important">
                  <h4>Registriere dich jetzt</h4>
                    <p>Um die volle Funktionalität unserer Plattform nutzen zu können, musst du dich zuerst registrieren. Ohne Registrierung ist der Zugriff auf unsere Inhalte und Dienstleistungen leider nicht möglich.</p>

                    <h5>Warum registrieren?</h5>
                    <ul>
                        <li><strong>Zugang zu allen Inhalten:</strong> Nur registrierte Benutzer können alle Bereiche und Inhalte von Foodtruck Locator nutzen.</li>
                    </ul>

                    <h5>So einfach geht's:</h5>
                    <ol>
                        <li><strong>Formular ausfüllen:</strong> Gib deine persönlichen Daten in das Registrierungsformular ein.</li>
                        <li><strong>Bestätigungscode erhalten:</strong> Nach der Registrierung senden wir dir einen vierstelligen Bestätigungscode per E-Mail.</li>
                        <li><strong>Code eingeben:</strong> Gib diesen Code bei deiner ersten Anmeldung ein, um dein Konto zu verifizieren.</li>
                    </ol>
                </div>
                <div class="col-md-6" style="margin-bottom:0 !important">
                  <Register    
                  :setLoginNav="setLoginNav"    
                  :apiData="apiData"
                  :agbShowFunction="agbShowFunction"
                  :colorMode="colorMode"
                  />
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </div>
      <!-- LOGIN & REGISTER PANEL END -->
        <!--TOP TRUCKS -->
        <div class="row d-flex" >
          <div class="col-md-4"  v-for="(item, x) in topTrucks" :key='x'>
            <!-- CARD -->
            <div class="card mb-3 truckCard d-flex h-100" style="width:100%; cursor: pointer;" @click="$router.push('/truck/'+item.id)">
                <div class="row h-100 d-flex">
                  <div class="col-4 truckCardImg d-flex" v-if="item.default_img" :style="{ backgroundImage:'url('+config.storageURL+item.owner+'/img/'+item.default_img.name+')' }">
                  
                  </div>
                  <div class="col-4 truckCardImg d-flex" v-else :style="{ backgroundImage: `url(${require('@/assets/default_truck.png')})` }" >

                  </div>
                  <div class="col-8 d-flex flex-column" style="margin-bottom: 0 !important; padding-left:0">
                    <div class="card-body">
                      <h5 class="card-title">{{ item.name }}</h5>
                        <p class="card-text">
                          <span class="badge bg-secondary" v-for="(categorie, z) in item.categories" :key='z' style="margin-right:5px">{{categorie.name}}</span>&nbsp;
                        </p>
                        <p class="card-text"><small class="text-body-secondary">Region: <span  class="region" v-for="(region, i) in item.region" :key='i'>{{ region.short }}</span></small></p>
                    </div>
                  </div>
                </div>
              </div>
              <!-- END CARD -->
          </div>
        </div>
      <!--TOP TRUCKS END -->
    </div>
 
</template>
<script>
  import Login from '@/components/Login.vue';
  import Register from '@/components/Register.vue';
  import MasterMap from '@/components/MasterMap.vue';

  export default {
    name: 'HomePublicView',
    components: {
      Login,
      Register,
      MasterMap
    },
    //---------------------------------------------------
    //
    //  Properties
    //
    //---------------------------------------------------
    props: {
      config: { required:true },
      apiData: { type: Function },
      setLoginStatus: {type:Function},
      setUserData: {type:Function},
      authFunction: {type:Function},
    },
    //---------------------------------------------------
    //
    //  Data model
    //
    //---------------------------------------------------
    data() {
      return {
        loginNav:'login',
        agbShow:false,
        topTrucks:[]

      };
    },
    //---------------------------------------------------
    //
    //  Computed Properties
    //
    //---------------------------------------------------
    computed: {},
    //---------------------------------------------------
    //
    //  Watch Properties
    //
    //---------------------------------------------------
    watch: {},
    //---------------------------------------------------
    //
    //  Filter Properties
    //
    //---------------------------------------------------
    // filters: {},
    //---------------------------------------------------
    //
    //  Validation Properties
    //
    //---------------------------------------------------
    // validations: {},
    //---------------------------------------------------
    //
    //  Vue Lifecycle
    //
    //---------------------------------------------------
    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // render(h) { return h(); },
    mounted() {
      this.getTopTrucks();
    },
    // beforeUpdate() {},
    // updated() {},
    // beforeDestroy() {},
    // destroyed() {},
    //---------------------------------------------------
    //
    //  Methods
    //
    //---------------------------------------------------
    methods: {
      setLoginNav(value){
      this.loginNav = value;
    },
    async getTopTrucks(){
      //GET TOP TRUCKS
      let requestTopTrucks = {
          
        };
        let responseTopTrucks;
        responseTopTrucks = await this.apiData('HomePublicView.vue getTopTrucks()','post','top-trucks', requestTopTrucks);
        this.topTrucks = responseTopTrucks;
       
    },
   
    },
  };
</script>
<style lang="scss">
</style>