<template>
    <div>
      <div class="row" v-if="hasTrucks === true">
         <!-- TAB NAV -->
         <div class="col-md-12">
          <ul class="nav nav-tabs d-flex" >
            <li class="nav-item">
              <a :class="{'nav-link': true, 'active': selectedStatus ===''}" aria-current="page" href="#"  @click.prevent="$router.push('/booking');selectedStatus ='';currentPage=1">{{ $t('tab.all') }}</a>
            </li>
            <li class="nav-item">
              <a :class="{'nav-link': true, 'active': selectedStatus ==='offen'}" href="#" @click.prevent="$router.push('/booking/open');selectedStatus ='offen';currentPage=1">{{ $t('tab.open') }}</a>
            </li>
            <li class="nav-item">
              <a :class="{'nav-link': true, 'active': selectedStatus ==='gebucht'}" href="#"  @click.prevent="$router.push('/booking/booked');selectedStatus ='gebucht';currentPage=1">{{ $t('tab.booked') }}</a>
            </li>
            <li class="nav-item">
              <a :class="{'nav-link': true, 'active': selectedStatus ==='geboten'}" href="#"  @click.prevent="$router.push('/booking/bid');selectedStatus ='geboten';currentPage=1">{{ $t('tab.bid') }}</a>
            </li>
            <li class="nav-item">
              <a :class="{'nav-link': true, 'active': selectedStatus ==='bestätigt'}" href="#"  @click.prevent="$router.push('/booking/confirmed');selectedStatus ='bestätigt';currentPage=1">{{ $t('tab.confirmed') }}</a>
            </li>
            <li class="d-flex align-items-center ms-auto" >
              <label class="form-check-label me-2 text-muted" for="flexSwitchCheckDefault">{{ $t('form.label.hideExpired') }}</label>
              <div class="form-check form-switch" >
                <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" v-model="mainFilter.hideExpired" style="margin-top:6px; "> 
              </div>
            </li>
          </ul>
          
        </div>
        <!-- TAB NAV END -->
        <!-- MAIN FILTER -->
        <div class="col-md-2" style="margin-bottom:0 !important">
          <div class="form-floating mb-3" >
            <flat-pickr              
              :config="filterPickerStart"
              class="form-control"
              placeholder="Von"
              id="filterVon"
              name="von"
              v-model="mainFilter.von"
            />
            <label for="filterVon">{{ $t('form.label.from') }}</label>
          </div>
        </div>
        <div class="col-md-2" style="margin-bottom:0 !important">
          <div class="form-floating mb-3" >
            <flat-pickr              
              :config="filterPickerEnd"
              class="form-control"
              placeholder="Bis"
              id="filterBis"
              name="bis"
              v-model="mainFilter.bis"
            />
            <label for="filterBis">{{ $t('form.label.to') }}</label>
          </div>
        </div>
        <div class="col-md-2 " style="margin-bottom:0 !important">
          <div class="form-floating mb-3">
          
            <RegionDropdown 
              :apiData="apiData"
              v-model="mainFilter.region"
              :class="{'is-invalid': errors.region }"
              :requestRegion="requestRegion"
              :searchable="true"
              />
            <div class="invalid-feedback  d-block" v-if="errors.region">
                <p v-for="(item, x) in errors.region" :key='x' >{{ errors.region[x] }}</p>
            </div> 
              
          </div>
        </div>
        <div class="col-md-2" style="margin-bottom:0 !important">
          <div class="form-floating mb-3">
          
            <MultiDropdown 
              v-model="mainFilter.type"
              :class="{'is-invalid': errors.region }"
              :searchable="false"
              :data="dropdownTypeData"
              :name="$t('form.label.type')"
              />
            <div class="invalid-feedback  d-block" v-if="errors.region">
                <p v-for="(item, x) in errors.region" :key='x' >{{ errors.region[x] }}</p>
            </div> 
              
          </div>
        </div>
        <div class="col-md-2" style="margin-bottom:0 !important">
          <div class="form-floating mb-3">
          
            <MultiDropdown 
              v-model="mainFilter.priceModel"
              :class="{'is-invalid': errors.region }"
              :searchable="false"
              :data="dropdownPricemodelData"
              :name="$t('form.label.priceModel')"
              />
            <div class="invalid-feedback  d-block" v-if="errors.region">
                <p v-for="(item, x) in errors.region" :key='x' >{{ errors.region[x] }}</p>
            </div> 
              
          </div>
        </div>
        <!-- PRICE -->
        <div class="col-md-2" style="margin-bottom: 0 !important">
          <div class="input-group  mb-3">
            <span class="input-group-text" id="basic-addon1">
              {{ $t('form.label.chf') }}
            </span>
              <div class="form-floating">            
                <input type="text"  :class="{'form-control': true}"  id="PriceFilter" v-model="mainFilter.maxPrice" placeholder="Max. Preis" >
                <label for="PriceFilter" class="form-label">{{ $t('form.label.maxPrice') }}</label>             
              </div>           
          </div>
        </div>
        <!-- PRICE END -->
        <div class="col-md-12 filterBadges">
          <template v-if="mainFilter.von">
            <span class="badge bg-secondary" style="margin-right:5px">{{ $t('form.label.from') }}: {{mainFilter.von}} <font-awesome-icon icon="fa-solid fa-xmark"  style="cursor: pointer;" @click="removeFilterBadge('von')" /></span>&nbsp;
          </template>
          <template v-if="mainFilter.bis">
            <span class="badge bg-secondary" style="margin-right:5px">{{ $t('form.label.to') }}: {{mainFilter.bis}} <font-awesome-icon icon="fa-solid fa-xmark"  style="cursor: pointer;" @click="removeFilterBadge('bis')" /></span>&nbsp;
          </template>
          <template v-for="(item, x) in mainFilter.region" :key='x' >
            <span class="badge bg-secondary" style="margin-right:5px">{{item.name}} <font-awesome-icon icon="fa-solid fa-xmark"  style="cursor: pointer;" @click="removeFilterBadge('region', x)" /></span>&nbsp;
          </template>
          <template v-for="(item, x) in mainFilter.type" :key='x' >
            <span class="badge bg-secondary" style="margin-right:5px">{{item.name}} <font-awesome-icon icon="fa-solid fa-xmark"  style="cursor: pointer;" @click="removeFilterBadge('type', x)" /></span>&nbsp;
          </template>
          <template v-for="(item, x) in mainFilter.priceModel" :key='x' >
            <span class="badge bg-secondary" style="margin-right:5px">{{item.name}} <font-awesome-icon icon="fa-solid fa-xmark"  style="cursor: pointer;" @click="removeFilterBadge('priceModel', x)" /></span>&nbsp;
          </template>
          <template  v-if="mainFilter.maxPrice">
            <span class="badge bg-secondary" style="margin-right:5px">Max. CHF {{mainFilter.maxPrice}} <font-awesome-icon icon="fa-solid fa-xmark"  style="cursor: pointer;" @click="removeFilterBadge('maxPrice')" /></span>&nbsp;
          </template>
        </div>
      <!-- MAIN FILTER END -->
      </div>
      <template v-if="hasTrucks === true" >
      <!-- PAGINATION NAV -->
      <nav aria-label="Page navigation" v-if="paginatedOfferts.length > 0">
        <ul class="pagination">
          <li class="page-item" :class="{ disabled: currentPage === 1 }">
            <a class="page-link" href="#" @click.prevent="changePage(currentPage - 1)">{{ $t('button.previous') }}</a>
          </li>
          <li class="page-item" v-for="page in totalPages" :key="page" :class="{ active: currentPage === page }">
            <a class="page-link" href="#" @click.prevent="changePage(page)">{{ page }}</a>
          </li>
          <li class="page-item" :class="{ disabled: currentPage === totalPages }">
            <a class="page-link" href="#" @click.prevent="changePage(currentPage + 1)">{{ $t('button.next') }}</a>
          </li>
        </ul>
      </nav>
      <!-- PAGINATION NAV END -->
      
      <div v-for="(item, y) in paginatedOfferts" :key='y' class="col-md-12">
        
          <!-- CARD OFFERT -->
           
          <div class="card mb-3 d-flex" >
            <div class="row g-0">
              <!-- MAP OFFERT -->
              <div class="col-md-4" style="margin-bottom:0 !important">
                <MapboxMap 
                  access-token="pk.eyJ1Ijoic2JlbmN6ZSIsImEiOiJjbTFiYXVrY3YwbWY0MmpzYm0wamc0bDhiIn0.R_6w0DsjbSGASOg2Snz8Ng"
                  style="min-height: 320px"
                  :center="[item.location.long,item.location.lat]"
                  :map-style="mapboxTheme"
                  :zoom="16"
                  :scrollZoom="false"
                >
                  <MapboxNavigationControl position="top-left" :showCompass="false" />
                  <MapboxMarker :lng-lat="[item.location.long,item.location.lat]" color="#f9b233" />
                </MapboxMap>
              </div>
              <!-- MAP OFFERT END -->
              <div class="col-md-8 d-flex flex-column" style="margin-bottom: 0 !important; padding-left:0">
                <div class="card-body w-100">
                  <!-- OFFERT CONTENT -->
                  <div class="row">
                    <div class="col-8">
                      <h5 class="card-title" @click="$router.push('/location/'+item.location.id)" style="cursor: pointer;">{{ item.location.name }} <span style="margin-right:5px" :class="{'badge':true, 'text-bg-secondary': item.status ==='offen', 'text-bg-warning': item.status ==='geboten' || item.status ==='gebucht',  'text-bg-success':item.status ==='bestätigt'}">
                        {{ item.status }}</span>
                        <span class="badge text-bg-danger" v-if="checkExpired(item.start)">abgelaufen</span>
                      </h5>
                    </div>
                    <div class="col-4 d-flex justify-content-end" v-if="item.price_model === 'auction' && !item.bid ">
                      <h5 >{{ $t('form.label.startprice') }} {{ $t('form.label.chf') }} {{ item.price }}</h5>
                    </div>
                    <div class="col-4 d-flex justify-content-end" v-if="item.bid">
                      <h5 >{{ $t('form.label.bid') }} {{ $t('form.label.chf') }} {{ item.bid }}</h5>
                    </div>
                    <div class="col-4 d-flex justify-content-end" v-if="item.price_model === 'fix'">
                      <h5 >{{ $t('form.label.chf') }} {{ item.price }}</h5>
                    </div>
                    <div class="col-md-6 cardContentElement"> 
                      <p>{{ $t('form.label.from') }}: {{ formatDate(item.start) }}</p>
                    </div>
                    <div class="col-md-6 cardContentElement"> 
                      <p>{{ $t('form.label.to') }}: {{ formatDate(item.end) }}</p>
                    </div>
                    <div class="col-md-6 cardContentElement"> 
                      <p>{{ $t('form.label.street') }}: {{ item.location.street }}</p>
                    </div>
                    <div class="col-md-6 cardContentElement"> 
                      <p>{{ $t('form.label.city') }}: {{ item.location.city }}</p>
                    </div>
                    <div class="col-md-6 cardContentElement"> 
                      <p>{{ $t('form.label.type') }}: {{ item.location.type.name }}</p>
                    </div>
                    <div class="col-md-6 cardContentElement"> 
                      <p v-if="item.price_model === 'fix'">Preismodell: Fixpreis</p>
                      <p v-if="item.price_model === 'auction'">Preismodell: Auktion</p>
                    </div>
                    <div class="col-md-6 cardContentElement"> 
                      <p>{{ $t('form.label.region') }}: {{ item.location.region.name }}</p>
                    </div>
                    <div class="col-md-6 cardContentElement"> 
                      <p v-if="item.repead === 'no'" >{{ $t('form.label.repeads') }}: Einmalig</p>
                      <p v-if="item.repead === 'daily'" >{{ $t('form.label.repeads') }}: Täglich</p>
                      <p v-if="item.repead === 'weekly'" >{{ $t('form.label.repeads') }}: Wöchentlich</p>
                      <p v-if="item.repead === 'monthly'" >{{ $t('form.label.repeads') }}: Monatlich</p>
                    </div> 
                    <div class="col-md-6 cardContentElement" v-if="item.price_model === 'auction'"> 
                      <p>{{ $t('form.label.bids') }}: {{ item.bids }}</p>
                    </div>  
                    <div class="col-md-6 cardContentElement" v-if="item.price_model === 'auction'"> 
                      <p>{{ $t('form.label.auctionEnd') }}: {{ auctionEndCountdown(item.auction_end, item) }}</p>
                     
                    </div>  
                  </div>
                  <!-- OFFERT CONTENT END -->
                </div>
                <div class="card-footer align-self-end justify-content-end d-flex" style="width:100%; min-height:54px;">
                 
                   <!-- ALERTS -->
                  <div class="alert alert-warning alert-sm" role="alert" style="margin-bottom:0 !important" v-if="item.status === 'gebucht' && item.buyer === userData.uuid && isPast(item.start) === false">
                    {{ $t('alert.booked') }}
                  </div>
                  <div class="alert alert-danger alert-sm" role="alert" style="margin-bottom:0 !important" v-if="item.price_model === 'fix' && item.status === 'gebucht' && item.buyer === userData.uuid && isPast(item.start) === true">
                    {{ $t('alert.bookedExpired') }}
                  </div>
                  <div class="alert alert-success alert-sm" role="alert" style="margin-bottom:0 !important" v-if="item.price_model === 'auction' && item.status === 'geboten' && item.buyer === userData.uuid && isPast(item.start) === false">
                    {{ $t('alert.leadBid') }}
                  </div>
                  <div class="alert alert-warning alert-sm" role="alert" style="margin-bottom:0 !important; margin-right:10px" v-if="item.price_model === 'auction' && item.status === 'geboten' && item.buyer != userData.uuid && isPast(item.start) === false && checkBider(item) === true" >
                    {{ $t('alert.overBid') }}
                  </div>
               
                  <!-- ALERTS END -->
                  <button type="button" class="btn btn-primary " @click="$router.push('/location/'+item.location.id)" v-if="item.status === 'gebucht' || item.status === 'bestätigt'" style="margin-left:10px;"><font-awesome-icon icon="fa-solid fa-location-dot" style="margin-right:0" /></button>
                  <a  class="btn btn-secondary btn-sm d-flex align-items-center" :href="'mailto:'+item.email_seller" v-if="item.status === 'bestätigt'" style="margin-left:10px;"><font-awesome-icon icon="fa-solid fa-envelope" style="margin-right:0" /></a>
                  <!-- BOOK -->
                  <template v-if="item.status === 'offen' && item.price_model === 'fix'">
                    <div class="col-md-6" style="margin-bottom: 0 !important">
                      <div class="form-floating mb-3" style="margin-bottom:0 !important">
                        <TrucksDropdown 
                            :apiData="apiData"
                            :userData="userData"
                            v-model="bookingTruck"
                            :class="{'is-invalid': errors.region }"
                            :requestRegion="requestRegion"
                            :searchable="false"
                            id="truckField"
                            :idPreset="item.id"
                            />
                      </div>
                    </div>
                    <button class="btn btn-primary" style="margin-left:10px" @click="bookOffert(item)">{{ $t('button.bookNow') }}</button>
                  </template>  
                  <!-- BOOK END -->
                   <!-- BID -->
                  <template v-if="item.status === 'offen' | item.status === 'geboten' && item.price_model === 'auction' && item.buyer != userData.uuid">
                    <div class="col-md-6" style="margin-bottom: 0 !important">
                      <div class="form-floating mb-3" style="margin-bottom:0 !important">
                        <TrucksDropdown 
                            :apiData="apiData"
                            :userData="userData"
                            v-model="item.truck"
                            :class="{'is-invalid': errors.region }"
                            :requestRegion="requestRegion"
                            :searchable="false"
                            id="truckField"
                            :idPreset="item.id"
                            />
                      </div>
                    </div>
                    <button class="btn btn-primary" style="margin-left:10px" @click="bidOffert(item)" v-if="item.bids === 0">Jetzt CHF {{item.price}} {{ $t('button.bid') }}</button>
                    <button class="btn btn-primary" style="margin-left:10px" @click="bidOffert(item)" v-else>Jetzt CHF {{ parseFloat(item.bid) + parseFloat(item.steps) }} {{ $t('button.bid') }}</button>
                  </template>  
                  <!-- BID END -->
                </div>
              </div>
            </div>
          </div>
         
          <!-- CARD OFFERT END -->
       </div>
      <!-- PAGINATION NAV -->
      <nav aria-label="Page navigation" v-if="paginatedOfferts.length > 0">
        <ul class="pagination">
          <li class="page-item" :class="{ disabled: currentPage === 1 }">
            <a class="page-link" href="#" @click="changePage(currentPage - 1)"> {{ $t('button.previous') }}</a>
          </li>
          <li class="page-item" v-for="page in totalPages" :key="page" :class="{ active: currentPage === page }">
            <a class="page-link" href="#" @click="changePage(page)">{{ page }}</a>
          </li>
          <li class="page-item" :class="{ disabled: currentPage === totalPages }">
            <a class="page-link" href="#" @click="changePage(currentPage + 1)"> {{ $t('button.next') }}</a>
          </li>
        </ul>
      </nav>
      <!-- PAGINATION NAV END -->
     </template>
     <!-- TRUCK TEASER -->
     <div class="row" v-if="hasTrucks === false && loading === false">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body text-center">
              <h5>{{ $t('teaser.title.truck') }}</h5>
              <h6>{{ $t('teaser.content.truckBooking') }}</h6>
              <button class="btn btn-primary" @click="$router.push('/profil/add-truck')">{{ $t('button.addTruck') }}</button>
            </div>
          </div>
        </div>
      </div>
      <!-- TRUCK TEASER END -->


    </div>
</template>
<script>
  import TrucksDropdown from "@/components/TrucksDropdownSingle.vue"
  import dayjs from 'dayjs';
  import utc from 'dayjs/plugin/utc';
  import timezone from 'dayjs/plugin/timezone';
  import { MapboxMap, MapboxMarker, MapboxNavigationControl } from '@studiometa/vue-mapbox-gl'
  import { useIntervalFn } from '@vueuse/core';
  import flatPickr from 'vue-flatpickr-component';
  import RegionDropdown from '@/components/RegionDropdownV2extended.vue';
  import MultiDropdown from '@/components/MultiDropdownGeneric.vue';

  import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.extend(customParseFormat); // NEU
  export default {
    name: '${COMPONENT_NAME}',
    components: {
      MapboxMap,
      MapboxMarker,
      MapboxNavigationControl,
      TrucksDropdown,
      flatPickr,
      RegionDropdown,
      MultiDropdown
    },
    //---------------------------------------------------
    //
    //  Properties
    //
    //---------------------------------------------------
    props: {
      userData:{required: true},
      apiData:{type: Function}
    },
    //---------------------------------------------------
    //
    //  Data model
    //
    //---------------------------------------------------
    data() {
      return {
        filterActions:0,
        hideExpired:false,
        bookingTruck:null,
        loading:true,
        hasTrucks:false,
        itemsPerPage: 10,           // Anzahl der Items pro Seite
        currentPage: 1,             // Aktuelle Seite
        dropdownTypeData:[
          {id:315 , name:'Parkplatz', value:'Parkplatz'},
          {id:316, name:'Strasse', value:'Strasse'},
          {id:317, name:'Event', value:'Event'},
          {id:318, name:'Festival', value:'Festival'},
          {id:319, name:'Baustelle', value:'Baustelle'},
          {id:320, name:'Einkaufszentrum', value:'Einkaufszentrum'},
          {id:321, name:'Ladenlokal', value:'Ladenlokal'},
        ],
        dropdownPricemodelData:[
          {name:'Aukton', value:'auction'},
          {name:'Fixpreis', value:'fix'},
        ],
        filterPickerStart:{
          dateFormat: 'd.m.Y H:i',
          enableTime: true,
          time_24hr:true,
          altInput: false, // Wichtig: Verhindert die Verwendung eines separaten Anzeige-Inputs
          defaultDate: null, // Kein Standarddatum, um das Floating Label korrekt zu behandeln
          minDate: new Date().fp_incr(0)
          
        }, 
        filterPickerEnd:{
          dateFormat: 'd.m.Y H:i',
          enableTime: true,
          time_24hr:true,
          altInput: false, // Wichtig: Verhindert die Verwendung eines separaten Anzeige-Inputs
          defaultDate: null, // Kein Standarddatum, um das Floating Label korrekt zu behandeln
          minDate: new Date().fp_incr(0)
          
        }, 
        mainFilter:{
          von:null,
          bis:null,
          region:[],
          type:[],
          priceModel:[],
          maxPrice:null,
          hideExpired:false
        },
        currentTime: dayjs(), // Reaktiver Wert für die aktuelle Zeit
        errors:{},
        booking:{
          truck:null
        },
        selectedStatus:'',
        offerts:[]
      };
    },
    //---------------------------------------------------
    //
    //  Computed Properties
    //
    //---------------------------------------------------
    computed: {
      mapboxTheme(){
        let value
        if(localStorage.getItem('theme') === 'light'){
          value ='mapbox://styles/mapbox/light-v11'
        }
        if(localStorage.getItem('theme') === 'dark'){
          value ='mapbox://styles/mapbox/dark-v11'
        }
        
        return value;
      },
     
      filteredOfferts() {
        const now = dayjs(); // Aktuelle Zeit im lokalen Format

        if (this.selectedStatus) {
          if (this.selectedStatus === 'gebucht' || this.selectedStatus === 'bestätigt') {
            // Für 'gebucht' oder 'bestätigt': Nur Einträge, bei denen der Benutzer der Käufer ist
            return this.offerts.filter(item => {
              const isBuyer = item.buyer === this.userData.uuid;
              return item.status === this.selectedStatus && isBuyer;
            });
          } else if (this.selectedStatus === 'geboten') {
            // Für 'geboten': Einträge anzeigen, bei denen der Benutzer der Käufer ist oder in `biders` vorkommt
            return this.offerts.filter(item => {
              const isBuyer = item.buyer === this.userData.uuid;
              const isInBiders = item.biders && item.biders.includes(this.userData.uuid);
              return item.status === this.selectedStatus && (isBuyer || isInBiders);
            });
          } else if (this.selectedStatus === 'offen') {
            // Für 'offen': Nur zukünftige Einträge mit Status 'offen' oder 'geboten' von anderen Nutzern
            return this.offerts.filter(item => {
              const isFuture = dayjs.utc(item.start).local().isAfter(now);
              const isOffen = item.status === 'offen';
              const isGebotenByOthers = item.status === 'geboten' && item.buyer !== this.userData.uuid;
              return isFuture && (isOffen || isGebotenByOthers);
            });
          } else {
            // Für alle anderen Status: Nur Status prüfen und sicherstellen, dass es entweder vom Benutzer ist oder in der Zukunft liegt
            return this.offerts.filter(item => {
              const isBuyer = item.buyer === this.userData.uuid;
              const isFuture = dayjs.utc(item.start).local().isAfter(now);
              return item.status === this.selectedStatus && (isBuyer || isFuture);
            });
          }
        } else {
          // Standardfilterung, wenn kein spezifischer Status ausgewählt ist
          return this.offerts.filter(item => {
            const isBuyer = item.buyer === this.userData.uuid;
            const isFuture = dayjs.utc(item.start).local().isAfter(now);
            const isOpenOrBid = item.status === 'offen' || item.status === 'geboten';
            const isBookedOrConfirmed = item.status === 'gebucht' || item.status === 'bestätigt';

            if (isOpenOrBid && isFuture) {
              return true;
            }

            if (isBookedOrConfirmed && isBuyer) {
              return true;
            }

            return false;
          });
        }
      },


      filteredOffertsDetail() {
      let offerts = this.filteredOfferts;

      // Datum: Filtere nach 'von' und 'bis'
      if (this.mainFilter.von || this.mainFilter.bis) {
        const von = this.mainFilter.von ? this.parseFilterDate(this.mainFilter.von) : null;
        const bis = this.mainFilter.bis ? this.parseFilterDate(this.mainFilter.bis) : null;

        offerts = offerts.filter(item => {
          const start = this.convertUTCToLocal(item.start);

          if (von && bis) {
            return start.isSameOrAfter(von) && start.isSameOrBefore(bis);
          }

          if (von) {
            return start.isSameOrAfter(von);
          }

          if (bis) {
            return start.isSameOrBefore(bis);
          }

          return true;
        });
      }

      // Preisfilter: Vergleich von 'maxPrice' unter Berücksichtigung von 'bid' und 'steps'
      if (this.mainFilter.maxPrice !== null) {
        offerts = offerts.filter(item => {
          if (item.bid !== null) {
            const bidWithSteps = parseFloat(item.bid) + (item.steps ? parseFloat(item.steps) : 0);
            return bidWithSteps <= this.mainFilter.maxPrice;
          } else {
            return parseFloat(item.price) <= this.mainFilter.maxPrice;
          }
        });
      }

      // Filter nach Region
      if (this.mainFilter.region.length > 0) {
        const regionIds = this.mainFilter.region.map(region => region.id);
        offerts = offerts.filter(item => regionIds.includes(item.location.region.id));
      }

      // Filter nach Type
      if (this.mainFilter.type.length > 0) {
        const typeIds = this.mainFilter.type.map(type => type.id);
        offerts = offerts.filter(item => typeIds.includes(item.location.type.id));
      }

      // Filter nach Price Model
      if (this.mainFilter.priceModel.length > 0) {
        const priceModels = this.mainFilter.priceModel.map(model => model.value);
        offerts = offerts.filter(item => priceModels.includes(item.price_model));
      }

      // Filter abgelaufene Angebote
      if (this.mainFilter.hideExpired) {
        offerts = offerts.filter(item => !this.checkExpired(item.start));
      }

      return offerts;
    },

    // Berechnet die totalen Seiten basierend auf der Anzahl der Daten
    
    totalPages() {
      return Math.ceil(this.filteredOffertsDetail.length / this.itemsPerPage);
    },
    // Schneidet die Daten für die aktuelle Seite aus
    
    paginatedOfferts() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.filteredOffertsDetail.slice(start, end);
    }

    

    
    },
    //---------------------------------------------------
    //
    //  Watch Properties
    //
    //---------------------------------------------------
    watch: {
      $route(){
        if(this.$route.params.view === 'confirmed'){
        this.selectedStatus ='bestätigt';
        this.getOfferts();
        }
        if(this.$route.params.view === 'bid'){
          this.selectedStatus ='geboten';
          this.getOfferts();
        }
      },
      mainFilter: {
        handler(newValue) {  
          if (newValue.von) {
            this.filterPickerEnd.minDate = newValue.von;
            //console.log('filterende gesetzt');
          }
          if (newValue.von || newValue.bis || newValue.region || newValue.type || newValue.priceModel || newValue.maxPrice || newValue.hideExpired) {
            localStorage.setItem('bookingFilter', JSON.stringify(this.mainFilter));
            if(this.filterActions > 0)
            {
             this.currentPage = 1;
            }
            this.filterActions ++;
          }
        },
        deep: true // Tiefes Watching aktivieren
      },
      currentPage(){
        localStorage.setItem('bookingPage', this.currentPage);
      }
     
    },
    //---------------------------------------------------
    //
    //  Filter Properties
    //
    //---------------------------------------------------
    // filters: {},
    //---------------------------------------------------
    //
    //  Validation Properties
    //
    //---------------------------------------------------
    // validations: {},
    //---------------------------------------------------
    //
    //  Vue Lifecycle
    //
    //---------------------------------------------------
    // beforeCreate() {},
    // created() {},
    async beforeMount() {
      const bookingFilter = localStorage.getItem('bookingFilter');
        if (bookingFilter) {
            const parsedFilter = JSON.parse(bookingFilter);
            this.mainFilter = parsedFilter;
        }
      const bookingPage = localStorage.getItem('bookingPage');
        if (bookingPage) {
           
            this.currentPage = parseInt(bookingPage, 10);
        }
      this.getOfferts();
      this.getTrucks();
     },
    // render(h) { return h(); },
    async mounted() {
      useIntervalFn(() => {
      this.currentTime = dayjs(); // Aktualisiere die aktuelle Zeit
      }, 1000);
      useIntervalFn(() => {
      this.getOfferts();
      }, 60000);
      
      if(this.$route.params.view === 'confirmed'){
        this.selectedStatus ='bestätigt';
        this.getOfferts();
      }
      if(this.$route.params.view === 'bid'){
        this.selectedStatus ='geboten';
        this.getOfferts();
      }
      if(this.$route.params.view === 'open'){
        this.selectedStatus ='offen';
        this.getOfferts();
      }
      if(this.$route.params.view === 'booked'){
        this.selectedStatus ='booked';
        this.getOfferts();
      }
      
      
    },
    // beforeUpdate() {},
    // updated() {},
    // beforeDestroy() {},
    // destroyed() {},
    //---------------------------------------------------
    //
    //  Methods
    //
    //---------------------------------------------------
    methods: {
      async getTrucks(){
        this.loading = true;
        let request = {
          selectorTable:'owner',
          selectorValue: this.userData.uuid
        };
        let response;
        response = await this.apiData('BookingView.vue getTrucks()','post','get-trucks', request);
        if(response.length >0){
          this.hasTrucks = true;
        }else{
          this.hasTrucks = false;
        }
        this.loading = false;
      },
      // Seitenwechsel
      changePage(page) {
        if (page > 0 && page <= this.totalPages) {
          this.currentPage = page;
        }
      },
      checkExpired(dateString) {
      // Umwandlung der übergebenen Zulu-Zeit in ein Ortszeit-Datum
      const inputDate = dayjs.utc(dateString).local();
      
      // Vergleich mit dem aktuellen Datum und Uhrzeit in Ortszeit
      return inputDate.isAfter(dayjs()) ? false : true;
    },
      checkBider(item){
        let bider = false;
        for(let i=0; i < item.biders.length; i++){     
          if(item.biders[i] === this.userData.uuid){
            bider = true;
          }  
        }
        return bider;
      },
      isPast(dateString) {
        const now = dayjs(); // Aktuelle Zeit
        const date = dayjs.utc(dateString); // Konvertiere das Datum von UTC
        return date.isBefore(now); // Wenn es in der Vergangenheit liegt, return true, sonst false
      },
      removeFilterBadge(filter, x){
        if(filter === 'von'){
          this.mainFilter.von = null;
        }
        if(filter === 'bis'){
          this.mainFilter.bis = null;
        }
        if(filter === 'region'){
          this.mainFilter.region.splice(x, 1);
        }
        if(filter === 'type'){
          this.mainFilter.type.splice(x, 1);
        }
        if(filter === 'priceModel'){
          this.mainFilter.priceModel.splice(x, 1);
        }
        if(filter === 'maxPrice'){
          this.mainFilter.maxPrice = null;
        }
      },
      formatDate(dateString) {
        return dayjs.utc(dateString).local().format('DD.MM.YY HH:mm');
      },
      // Konvertiere von UTC in lokale Zeit
  convertUTCToLocal(dateString) {
    return dayjs.utc(dateString).local(); // Konvertiere von UTC in lokale Zeit
  },

  // Parsen von `mainFilter.von` und `mainFilter.bis` im korrekten Format (DD.MM.YYYY)
  parseFilterDate(dateString) {
    const format = 'DD.MM.YYYY HH:mm'; // Verwende das strikte TT.MM.JJJJ-Format
    return dayjs(dateString, format, true); // Striktes Parsing
  },
      /* ###################### BOOKING DB ROUTINES ###################### */
      /* ******** GET OFFERTS ******** */
      async getOfferts(){
        this.loading =true;
        let request = {};
        let response;
        response = await this.apiData('LocationView.vue getOfferts()','post','get-offerts', request);
        this.offerts = response;
        this.loading = false;
      },
      /* ******** BOOK OFFERT ******** */
      async bookOffert(item){  
        item.buyer = this.userData.uuid;
        item.truck = this.bookingTruck;
        let response;
        response = await this.apiData('BookView.vue bookOffert()','post','book-offert', item);
        //console.log(response);
        if (response.status === 'failure'){
          this.errors = response.errors;
          this.$toast.open({
            message: this.$t('toast.error'),
            type: 'error',
            duration:3000,
          });
        }else if(response === 'success'){
          this.getOfferts();
          this.errors = {};
          this.$toast.open({
            message: this.$t('toast.bookingSuccess'),
            type: 'success',
            duration:3000,
          });
        }else if(response === 'hoppla'){
          this.getOfferts();
          this.errors = {};
          this.$toast.open({
            message: this.$t('toast.bookingFail'),
            type: 'warning',
            duration:3000,
          });
        }    
      },
      /* ******** BID OFFERT ******** */
      async bidOffert(item){  
        let biderExists = false;
        item.buyer = this.userData.uuid;
        item.bids = item.bids + 1;
        if(item.bid){
          item.bid = parseFloat(item.bid) + parseFloat(item.steps) ;
          
        }else{
          item.bid = item.price;
        }

        for(let i=0; i < item.biders.length; i++ ){
          if(item.biders[i] === this.userData.uuid){
            biderExists = true;
          }
        }
        if(biderExists === false){
          item.biders.push(this.userData.uuid);
        }
        
        
        let response;
        response = await this.apiData('BookView.vue bidOffert()','post','bid-offert', item);

        if (response.status === 'failure'){
          this.errors = response.errors;
          this.$toast.open({
            message: this.$t('toast.error'),
            type: 'error',
            duration:3000,
          });
        }else if(response === 'success'){
          this.getOfferts();
          this.errors = {};
          this.$toast.open({
            message:  this.$t('toast.bidSuccess'),
            type: 'success',
            duration:3000,
          });
        }else if(response === 'hoppla'){
          this.getOfferts();
          this.errors = {};
          this.$toast.open({
            message:  this.$t('toast.bidFail'),
            type: 'warning',
            duration:3000,
          });
        }else if(response === 'closed'){
          this.getOfferts();
          this.errors = {};
          this.$toast.open({
            message:  this.$t('toast.auctionEnded'),
            type: 'warning',
            duration:3000,
          });
        }       
      },
      /* ###################### BOOKING DB ROUTINES END ###################### */
      /* ###################### AUCTION TIMER ###################### */
     auctionEndCountdown(targetDateString, item) {
        // Parse das Datum als UTC und konvertiere in lokale Zeit
        const targetDate = dayjs.utc(targetDateString).local();
        const timeDiff = targetDate.diff(this.currentTime);

        if (timeDiff > 0) {
          const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
          const hours = Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
          const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
          const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

          // Füge führende Nullen hinzu, wenn die Zahl einstellig ist
          const formattedDays = String(days).padStart(2, '0');
          const formattedHours = String(hours).padStart(2, '0');
          const formattedMinutes = String(minutes).padStart(2, '0');
          const formattedSeconds = String(seconds).padStart(2, '0');

          return `${formattedDays}:${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
        } else {
          if(item.auction_closed === 0){
            this.closeAuction(item);
          }
          return 'Auktion abgelaufen';
        }
      },
      async closeAuction(item){
      
            let response;
            response = await this.apiData('BookView.vue auctionEndCountdown()','post','close-auction', item);
            console.log(response);
            this.getOfferts();
          
      }
    /* ###################### AUCTION TIMER END ###################### */     
    },
  };
</script>
<style lang="scss">
.alert-sm{
  padding-top: 5px;
  padding-bottom: 5px;
}
</style>