<template>
  <div class="dropdown-floating-label">
    
      <div class="dropdown">
        <button :class="['btn btn-custom-select','dropdown-toggle','d-flex','justify-content-between','align-items-center',customClass]"  :id="idPreset+'id'"
                style="width:100%; padding: 0.375rem 0.75rem 0.375rem 0.75rem; appearance: none; border-radius: 0.25rem;"
                type="button" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false" data-bs-display="static">
                <template v-if="!selectionName">
                  &nbsp; 
                </template>
                <template v-else>
                  <p style="margin-top:20px">{{selectionName}}</p>
                </template>
                
               
          <font-awesome-icon icon="fa-solid fa-chevron-down"/>
        </button>
        <label :class="{ 'floating': selectionName, 'dropdown-label': true }" for="locationField">{{ $t('form.label.truck') }}</label>
       
        <form class="dropdown-menu" style="min-width: 100%">
          <div class="dropdown-header" v-if="searchable === true">
            <div class="form-floating mb-3">
              <input type="text" class="form-control" id="searchField" placeholder="Search" v-model="searchString">
              <label for="searchField" class="form-label">{{ $t('form.label.search') }}</label>
            </div>
          </div>
          <hr class="dropdown-divider" v-if="searchable === true">
          <ul class="list-group list-group-flush">
            <li class="list-group-item" v-for="(item, x) in filteredTrucks" :key="x">
              <input class="form-check-input me-1" type="radio" :value="item.id" :id="idPreset+'trucks_checkbox_' + x" @click="selectionName = item.name;"
                     v-model="internalSelectedTrucks">
              <label class="form-check-label" :for="idPreset+'trucks_checkbox_' + x" style="min-width: 85%">{{item.name}}</label>
            </li>
          </ul>
        </form>
      </div>
   
  </div>
</template>
<script>
  import 'bootstrap/dist/js/bootstrap';
  export default {
    name: '${COMPONENT_NAME}',
    components: {
    },
    //---------------------------------------------------
    //
    //  Properties
    //
    //---------------------------------------------------
    props: {
      id:{required:true},
      idPreset:{required:true},
      apiData: {type: Function},
      searchable:{required:true},
      userData:{required:true},
      class: {
        type: [String, Object], // Akzeptiert entweder einen String oder ein Object für dynamische Klassen
        default: ''
      },
      modelValue: { type: Number, default: () => 0 },  
    },
    //---------------------------------------------------
    //
    //  Data model
    //
    //---------------------------------------------------
    data() {
      return {
        selectionName:null,
        internalSelectedTrucks: this.modelValue, // Erstelle eine Kopie von modelValue
        searchString:'',
        trucks:[],
      };
    },
    //---------------------------------------------------
    //
    //  Computed Properties
    //
    //---------------------------------------------------
    computed: {
      filteredTrucks() {    
        const filteredTrucks = this.searchString.toLowerCase() === ""
            ? this.trucks
            : this.trucks.filter( wo => Object.values(wo).join("").toLowerCase().indexOf(this.searchString.toLowerCase()) !== -1);    
        return filteredTrucks;
      },
      customClass() {
      return this.class;
    }
    },
    //---------------------------------------------------
    //
    //  Watch Properties
    //
    //---------------------------------------------------
    watch: {
      
      
      // Überwache interne Änderungen und emitte sie zurück an die Parent-Komponente
    internalSelectedTrucks(newVal) {
      // Verhindere Rekursion: Nur wenn sich internalSelectedCategories vom modelValue unterscheidet
      if (newVal !== this.modelValue) {
        this.$emit('update:modelValue', newVal);
      }
    },
    // Falls die Parent-Komponente den Wert von modelValue ändert, synchronisiere die interne Kopie
    modelValue(newVal) {
      // Verhindere Rekursion: Nur wenn sich modelValue von internalSelectedCategories unterscheidet
      if (newVal !== this.internalSelectedTrucks) {
        this.internalSelectedTrucks = newVal;
        //this.selectionName= this.trucks[newVal].name;
        for(let i = 0; i < this.trucks.length; i++){
          if(this.trucks[i].id === newVal){
            this.selectionName= this.trucks[i].name;
          }
        }
      }
    }
    
    },
    //---------------------------------------------------
    //
    //  Filter Properties
    //
    //---------------------------------------------------
    // filters: {},
    //---------------------------------------------------
    //
    //  Validation Properties
    //
    //---------------------------------------------------
    // validations: {},
    //---------------------------------------------------
    //
    //  Vue Lifecycle
    //
    //---------------------------------------------------
    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // render(h) { return h(); },
    async mounted() {
      await this.getTrucks();
       this.internalSelectedTrucks = this.trucks[0].id;
        this.selectionName= this.trucks[0].name;
      //this.internalSelectedTrucks = this.modelValue;   
      //console.log(this.trucks[0].id);   

    },
    async beforeUpdate() {
     
    },
    // updated() {},
    // beforeDestroy() {},
    // destroyed() {},
    //---------------------------------------------------
    //
    //  Methods
    //
    //---------------------------------------------------
    methods: {
      
      async getTrucks(){
        let request = {
          selectorTable:'owner',
          selectorValue: this.userData.uuid
        };
        let response;
        response = await this.apiData('LocationsView getLocations()','post','get-trucks', request);
        this.trucks = response;
      },
      
    },
  };
</script>
<style scoped lang="scss">
/* Stelle sicher, dass das Dropdown-Label dynamisch auf Light und Dark Mode reagiert */
.dropdown-floating-label {
  position: relative;

  /* Floating Label nur für das Dropdown-Label */
  .dropdown-label {
    position: absolute;
    top: 50%; /* Zentriere das Label vertikal im Button */
    left: 0.75rem; /* Setze Padding links wieder auf 0.75rem */
    transform: translateY(-50%); /* Vertikale Zentrierung */
    transition: all 0.2s ease;
    pointer-events: none;
    z-index: 2;
    opacity: 1; /* Volle Deckkraft */
    color: var(--bs-body-color); /* Dynamische Farbe abhängig vom Farbschema */
    background: none; /* Kein Hintergrund */
    
  }

  /* Wenn das Label schwebt (nach Auswahl) */
  .dropdown-label.floating {
    top: 0.25rem; /* Schwebendes Label innerhalb des Buttons */
    left: 0.75rem; /* Setze Padding links wieder auf 0.75rem */
    font-size: 0.75rem;
    opacity: 1; /* Behalte volle Deckkraft */
    transform: none;
    color: var(--bs-body-color); /* Dynamische Farbe abhängig vom Farbschema */
    background: none; /* Kein Hintergrund */
  }

  /* Button-Styling */
  .btn-custom-select {
    width: 100%;
    padding: 0.375rem 0.75rem;
    appearance: none;
    border-radius: 0.25rem;
    text-align: left;
    position: relative;
  }
}

/* Standardmäßige Dropdown-Stile */
.dropdown .list-group-flush {
  max-height: 200px;
  overflow-y: scroll;
  margin-top: 0 !important;
}

.dropdown hr {
  margin-bottom: 0 !important;
  margin-top: 0 !important;
}

.dropdown .dropdown-header {
  padding-bottom: 0 !important;
}

</style>