<template>
    <div class="card">
      <div class="card-header">
        <h5>{{$t('card.title.invoices')}}</h5>
      </div>
      <div class="card-body" style="height:400px; overflow-y: auto;">
        <table class="table">
           
          <tbody>
            <tr v-for="(item, x) in invoices" :key='x'> 
              <td>{{ formatDate(item.created_at) }}</td>
  
              <td><span :class="{'badge':true,  'text-bg-secondary': item.status === 'offen',   'text-bg-success': item.status === 'bezahlt',   'text-bg-warning': item.status === 'fällig',   'text-bg-danger': item.status === 'überfällig'}">{{ item.status }}</span></td>
              <td align="right">CHF {{ item.amount }}</td>
              <td align="right" style="padding-right:0 !important"> <button type="button" class="btn btn-secondary " @click="downloadInvoice(item)"><font-awesome-icon icon="fa-solid fa-circle-down" style="margin-right:0" /></button></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
</template>
<script>
import dayjs from 'dayjs';
  export default {
    name: '${COMPONENT_NAME}',
    components: {
    },
    //---------------------------------------------------
    //
    //  Properties
    //
    //---------------------------------------------------
    props: {
      invoices:{required:true},
      config:{required:true},
      userData:{required:true}
    },
    //---------------------------------------------------
    //
    //  Data model
    //
    //---------------------------------------------------
    data() {
      return {};
    },
    //---------------------------------------------------
    //
    //  Computed Properties
    //
    //---------------------------------------------------
    computed: {},
    //---------------------------------------------------
    //
    //  Watch Properties
    //
    //---------------------------------------------------
    watch: {},
    //---------------------------------------------------
    //
    //  Filter Properties
    //
    //---------------------------------------------------
    // filters: {},
    //---------------------------------------------------
    //
    //  Validation Properties
    //
    //---------------------------------------------------
    // validations: {},
    //---------------------------------------------------
    //
    //  Vue Lifecycle
    //
    //---------------------------------------------------
    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // render(h) { return h(); },
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // beforeDestroy() {},
    // destroyed() {},
    //---------------------------------------------------
    //
    //  Methods
    //
    //---------------------------------------------------
    methods: {
      formatDate(dateString) {
        return dayjs.utc(dateString).local().format('DD.MM.YY HH:mm');
      },
      downloadInvoice(item){
      const link = document.createElement('a');
      link.href = this.config.storageURL+this.userData.uuid+'/invoice/'+item.file;
      link.setAttribute('download', '');
      link.click();
    },
    },
  };
</script>
<style lang="scss">
</style>