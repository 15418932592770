import { createRouter, createWebHistory } from 'vue-router'
import HomePrivateView from '../views/HomePrivateView.vue'
import HomePublicView from '../views/HomePublicView.vue'
import LoginView from '../views/LoginView.vue'
import RegisterView from '../views/RegisterView.vue'

import Profile from '../views/ProfileView.vue'
import Sandbox from '../views/SandboxView.vue'
import Truck from '../views/TruckView.vue'
import Offerts from '../views/OffertsView.vue'
import Booking from '../views/BookingView.vue'
import Location from '../views/LocationView.vue'
import Agb from '../views/AgbView.vue'
import Dsv from '../views/DsvView.vue'
import Impressum from '../views/ImpressumView.vue'
import Contact from '../views/ContactView.vue'
import LocationProvider from '../views/LocationContentView.vue'
import TruckProvider from '../views/TruckContentView.vue'
import Invoices from '../views/InvoicesView.vue'
import PublicTrucks from '../views/PublicTrucksView.vue'
import PublicLocations from '../views/PublicLocationsView.vue'
import UserSettings from '../views/UserSettingsView.vue'
import ResetPassword from '../views/ResetPasswordView.vue'

const routes = [
    /* HYBRID */
  {
    path: '/',
    name: 'home',
    components:{
      privateRouter: HomePublicView,
      publicRouter: HomePublicView
    },
    meta: {
      title: 'Foodtruck Locator',
      description: 'Awesome Description',
    }
    
  },
  {
    path: '/truck/:id',
    name: 'Truck',
    components:{
      privateRouter: Truck,
      publicRouter: Truck,
    },
    meta: {
      title: 'Foodtruck Locator | Foodtruck Detail',
      description: 'Awesome Description',
    },
    
  },
  {
    path: '/location/:id',
    name: 'Location',
    components:{
      privateRouter: Location,
      publicRouter: Location,
    },
    meta: {
      title: 'Foodtruck Locator | Standort Detail ',
      description: 'Awesome Description',
    },
    
  },
  {
    path: '/agb',
    name: 'AGB',
    components:{
      privateRouter: Agb,
      publicRouter: Agb,
    },
    meta: {
      title: 'Foodtruck Locator | AGB',
      description: 'Awesome Description',
    },
    
  },
  {
    path: '/dsv',
    name: 'DSV',
    components:{
      privateRouter: Dsv,
      publicRouter: Dsv,
    },
    meta: {
      title: 'Foodtruck Locator | Datenschutzverordnung',
      description: 'Awesome Description',
    },
    
  },
  {
    path: '/contact',
    name: 'Contact',
    components:{
      privateRouter: Contact,
      publicRouter: Contact,
    },
    meta: {
      title: 'Foodtruck Locator | Kontakt',
      description: 'Awesome Description',
    },
    
  },
  {
    path: '/impressum',
    name: 'Impressum',
    components:{
      privateRouter: Impressum,
      publicRouter: Impressum,
    },
    meta: {
      title: 'Foodtruck Locator | Impressum',
      description: 'Awesome Description',
    },
    
  },
  /* PUBLIC */ 
  {
    path: '/login',
    name: 'login',
    components:{
      publicRouter: LoginView,
    },
    meta: {
      title: 'Foodtruck Locator | Anmelden',
      description: 'Awesome Description',
    }
  },
  {
    path: '/register',
    name: 'register',
    components:{
      publicRouter: RegisterView,
    },
    meta: {
      title: 'Foodtruck Locator | Registrieren',
      description: 'Awesome Description',
    }
  },
  {
    path: '/ftl-for-locations',
    name: 'LocationProvider',
    components:{
      publicRouter: LocationProvider,
      privateRouter: LocationProvider,
    },
    meta: {
      title: 'Foodtruck Locator für Standortanbieter',
      description: 'Awesome Description',
    }
  },
  {
    path: '/ftl-for-trucks',
    name: 'TruckProvider',
    components:{
      publicRouter: TruckProvider,
      privateRouter: TruckProvider,
    },
    meta: {
      title: 'Foodtruck Locator für Foodtruckbetreiber',
      description: 'Awesome Description',
    }
  },
  {
    path: '/trucks',
    name: 'PublicTrucks',
    components:{
      publicRouter: PublicTrucks,
      privateRouter: PublicTrucks,
    },
    meta: {
      title: 'Foodtruck Locator | Alle Foodtrucks',
      description: 'Awesome Description',
    }
  },
  {
    path: '/locations',
    name: 'PublicLocations',
    components:{
      publicRouter: PublicLocations,
      privateRouter: PublicLocations,
    },
    meta: {
      title: 'Foodtruck Locator | Alle Standorte',
      description: 'Awesome Description',
    }
  },
  {
    path: '/reset-password',
    name: 'ResetPasswort',
    components:{
      publicRouter: ResetPassword,
    },
    meta: {
      title: 'Passwort zurücksetzen',
      description: 'Awesome Description',
    }
  },
    /* Private */ 
    {
      path: '/dashboard',
      name: 'Dashboard',
      components:{
        privateRouter: HomePrivateView,
      },
      meta: {
        title: 'Foodtruck Locator | Dashboard',
        description: 'Awesome Description',
      }
      
    },
  {
    path: '/profil',
    name: 'Profil',
    components:{
      privateRouter: Profile,
      publicRouter: LoginView
    },
    meta: {
      title: 'Foodtruck Locator | Profil',
      description: 'Awesome Description',
    },
    
  },
  {
    path: '/profil/:function',
    name: 'ProfilDeep',
    components:{
      privateRouter: Profile,
      publicRouter: LoginView
    },
    meta: {
      title: 'Foodtruck Locator | Profil',
      description: 'Awesome Description',
    },
    
  },
  {
    path: '/sandbox',
    name: 'Sandbox',
    components:{
      privateRouter: Sandbox,
      publicRouter: LoginView
    },
    meta: {
      title: 'Sandbox',
      description: 'Awesome Description',
    },
    
  },
  
  {
    path: '/offerts',
    name: 'Offerts',
    components:{
      privateRouter: Offerts,
      publicRouter: LoginView
    },
    meta: {
      title: 'Foodtruck Locator | Standort anbieten',
      description: 'Awesome Description',
    },
    
  },
  {
    path: '/offerts/:view',
    name: 'OffertsDeep',
    components:{
      privateRouter: Offerts,
      publicRouter: LoginView
    },
    meta: {
      title: 'Foodtruck Locator | Standort anbieten',
      description: 'Awesome Description',
    },
    
  },
  {
    path: '/booking',
    name: 'Booking',
    components:{
      privateRouter: Booking,
      publicRouter: LoginView
    },
    meta: {
      title: 'Foodtruck Locator | Standort buchen',
      description: 'Awesome Description',
    },
    
  },
  {
    path: '/booking/:view',
    name: 'BookingDeep',
    components:{
      privateRouter: Booking,
      publicRouter: LoginView
    },
    meta: {
      title: 'Foodtruck Locator | Standort buchen',
      description: 'Awesome Description',
    },
    
  },
  {
    path: '/invoices',
    name: 'Invoices',
    components:{
      privateRouter: Invoices,
      publicRouter: LoginView
    },
    meta: {
      title: 'Foodtruck Locator | Rechnungen',
      description: 'Awesome Description',
    },
    
  },
  {
    path: '/settings',
    name: 'Settings',
    components:{
      privateRouter: UserSettings,
      publicRouter: LoginView
    },
    meta: {
      title: 'Foodtruck Locator | Einstellungen',
      description: 'Awesome Description',
    },
    
  },
  
 
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
  
  

});

router.beforeEach((to) => {
  //const { title, description } = to.meta;
  const { title } = to.meta;
  const defaultTitle = 'Foodtruck Locator';
  //const defaultDescription = 'Default Description';

  document.title = title || defaultTitle

  //const descriptionElement = document.querySelector('head meta[name="description"]')

  //descriptionElement.setAttribute('content', description || defaultDescription)
});


export default router
